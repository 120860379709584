<template>
  <Menu>
      <h1 class="p" style="color: #fff">
        <FONT FACE="arial" SIZE="6">
          <i class="fas fa-address-card"></i> Dashboard</FONT>
        <small class="text-muted"></small>
      </h1>
      <hr size="2px" color="white" />

      <div class="row">
        <div class="col-md-4 mb-3">
          <div
            class="card bg-white"
            style="color: white; border-color: white; height: 100%">
            <div class="card-body">
              <center>
                <h5 class="card-title">
                  <i class="fas fa-user-friends"></i>
                </h5>
                <h6 class="card-subtitle mb-2">Usuarios activos</h6>
              </center>
              <div class="Style">
                <ul class="list-group" style="color: black">
                  <li class="list-group-item" style="background-color: #e9ecef">
                    Angel Roman
                    <span style="float: right; color: forestgreen"><i class="fas fa-circle"></i></span>
                  </li>
                  <li class="list-group-item" style="background-color: #ccc">
                    David Cardenas
                    <span style="float: right; color: forestgreen"><i class="fas fa-circle"></i></span>
                  </li>
                  <li class="list-group-item" style="background-color: #e9ecef">
                    Bani Rosas
                    <span style="float: right; color: forestgreen"><i class="fas fa-circle"></i></span>
                  </li>
                  <li class="list-group-item" style="background-color: #e9ecef">
                    Luis Peña
                    <span style="float: right; color: forestgreen"><i class="fas fa-circle"></i></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div
            class="card bg-white"
            style="color: white; border-color: white; height: 100%">
            <div class="card-body">
              <center>
                <h5 class="card-title"><i class="fas fa-list-alt"></i></h5>
                <h6 class="card-subtitle mb-2">Contribuyentes</h6>
                <p class="card-text" style="font-size: 51px">13,000</p>
                <router-link to="#/Contratos" class="card-link" style="color: #ffc107">Agregar</router-link>
              </center>
            </div>
          </div>
        </div>

        <div class="col-md-4 mb-3">
          <div
            class="card bg-white"
            style="color: white; border-color: white; height: 100%">
            <div class="card-body">
              <center>
                <h5 class="card-title"><i class="fas fa-smile"></i></h5>
                <h6 class="card-subtitle mb-2">Usuarios registrados</h6>
                <p class="card-text" style="font-size: 51px">3,000</p>
                <router-link to="#/proximopago" class="card-link" style="color: #ffc107">Pagos</router-link>
              </center>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <div
            class="card bg-white"
            style="color: white; border-color: white; height: 100%">
            <div class="card-body">
              <center>
                <h5 class="card-title">
                  <i class="fas fa-circle-notch"></i>
                </h5>
                <h6 class="card-subtitle mb-2">Chart de pagos</h6>
                <canvas id="myChart" width="30%" height="15%" />
              </center>
            </div>
          </div>
        </div>
        <!---separaciom-->
        <div class="col-md-6 mb-3">
          <div class="card bg-white" style="color: white; border-color: white">
            <div class="card-body">
              <center>
                <h5 class="card-title"><i class="far fa-chart-bar"></i></h5>
                <h6 class="card-subtitle mb-2">Chart de pagos</h6>
                <canvas id="mixed-chart"></canvas>
              </center>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card bg-white" style="color: white; border-color: white">
            <div class="card-body">
              <center>
                <h5 class="card-title"><i class="fas fa-align-left"></i></h5>
                <h6 class="card-subtitle mb-2">Chart de pagos</h6>
                <canvas id="bar-chart-horizontal" height="80px"></canvas>
              </center>
            </div>
          </div>
        </div>
      </div>
  </Menu>
</template>
<script>
import Menu from "../components/Menu.vue";

export default {
  name: "Dashboardview",
  components: {
    Menu
  },
  created() {
    $(function () {
      var ctx = document.getElementById("myChart").getContext("2d");
      Chart.defaults.global.defaultFontFamily = "Arial";
      Chart.defaults.global.defaultFontSize = 10;
      Chart.defaults.global.defaultFontColor = "white";
      var myChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Vigentes", "Saldados", "Vencidos"],
          datasets: [
            {
              backgroundColor: ["#E0FFFF", "#87CEEB", "#FF7F50"],
              data: [12, 20, 25],
            },
          ],
        },
      });
    });
    ////////////////////////////////////////////////////////////////////////////////////////////////
    $(function () {
      new Chart(document.getElementById("mixed-chart"), {
        type: "bar",
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          datasets: [
            {
              label: "Vencidos",
              type: "line",
              borderColor: "#8e5ea2",
              data: [300, 547, 675, 734],
              fill: false,
            },
            {
              label: "Saldados",
              type: "line",
              borderColor: "#3e95cd",
              data: [133, 221, 783, 2478],
              fill: false,
            },
            {
              label: "Vencidos",
              type: "bar",
              backgroundColor: "#FF7F50",
              data: [300, 547, 675, 734],
            },
            {
              label: "Saldados",
              type: "bar",
              backgroundColor: "#87CEEB",
              backgroundColorHover: "#3e95cd",
              data: [133, 221, 783, 2478],
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: "Pagos por mes: Vencidos & Saldados",
          },
          legend: { display: false },
        },
      });
    });

    $(function () {
      new Chart(document.getElementById("bar-chart-horizontal"), {
        type: "horizontalBar",
        data: {
          labels: ["2014", "2015", "2016", "2017", "2018"],
          datasets: [
            {
              label: "Population (millions)",
              backgroundColor: [
                "#3e95cd",
                "#8e5ea2",
                "#3cba9f",
                "#e8c3b9",
                "#c45850",
              ],
              data: [2478, 5267, 734, 784, 433],
            },
          ],
        },
        options: {
          legend: { display: false },
          title: {
            display: true,
            text: "Pagos por año",
          },
        },
      });
    });
  },
};
</script>
<style>
.Style {
  max-height: 150px;
  overflow-y: scroll;
  overflow: auto;
}

@media (min-width: 768px) {
  .main {
    padding-left: 240px; /* 180 + 40 */
  }
}
.bg-white {
  background-color: #333333ad !important;
}
.content {
  border: 0;
  height: 400%;
  overflow-x: inline-block; /* se habilita el scroll vertical */
}

.content::-webkit-scrollbar {
  /* solo oculta su visualizacion */
  display: none;
}
#body-row {
  margin-left: 0;
  margin-right: 0;
}
#sidebar-container {
  min-height: 130vh;
  background-color: #333;
  padding: 0;
}
/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
  width: 230px;
  height: 100%;
}
.sidebar-collapsed {
  width: 60px;
}
/* Menu item*/
#sidebar-container .list-group a {
  height: 50px;
  color: white;
}
/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
  height: 45px;
  padding-left: 30px;
}
.sidebar-submenu {
  font-size: 0.9rem;
}
/* Separators */
.sidebar-separator-title {
  background-color: #333;
  height: 35px;
}
.sidebar-separator {
  background-color: #333;
  height: 25px;
}
.logo-separator {
  background-color: #333;
  height: 60px;
}
/* Closed submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="false"]
  .submenu-icon::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/* Opened submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="true"]
  .submenu-icon::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/*TRASICION DEL TITULO*/
.p {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
  }
}
@keyframes latidos {
  from {
    transform: none;
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: none;
  }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
  display: inline-block;
  animation: latidos 4s infinite;
}
</style>
