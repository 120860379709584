<!--Creacion del menu-->
<template>
    <div class="container-fluid px-1 px-md-2 px-lg-4 py-1 mx-auto">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-7 col-lg-10 col-md-12 col-sm-12">
          <div class="card border-0">
            <div class="row justify-content-center">
              <h3
                class="mb-4 text-2xl text-gray-300 dark:text-gray-600 font-medium leading-none mt-3"
              >
                Pago con tarjeta de Crédito / Débito
              </h3>
            </div>
            <div class="row">
              <div class="col-sm-6 border-line pb-3">
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">Titular de la Tarjeta</p>
                  <input
                    style="width: 250px"
                    type="text"
                    id="nomTar"
                    name="name"
                    placeholder="Nombre"
                    size="15"
                  />
                </div>
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">Número de tarjeta</p>
                  <div class="row px-3">
                    <input
                      style="width: 200px"
                      type="text"
                      name="card-num"
                      placeholder="0000 0000 0000 0000"
                      size="18"
                      id="cr_no"
                      minlength="19"
                      maxlength="19"
                    />
                    <p class="mb-0 ml-3">/</p>
                    <!-- <img
                      class="image ml-1"
                      src="https://i.imgur.com/WIAP9Ku.jpg"
                    /> -->
                  </div>
                </div>
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">Fecha de caducidad</p>
                  <input
                    type="text"
                    name="exp"
                    style="width: 70px"
                    placeholder="MM/AA"
                    size="6"
                    id="exp"
                    minlength="5"
                    maxlength="5"
                  />
                </div>
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">CVV/CVC</p>
                  <input
                    style="width: 40px"
                    type="password"
                    id="cvv"
                    name="cvv"
                    placeholder="***"
                    size="1"
                    minlength="3"
                    maxlength="3"
                  />
                </div>
                <!-- <hr size="2px" color="white" /> -->
                <br>
                <div style="text-align:center;">
                  <h6 style="color:#313a55;">Datos de Facturación</h6>
                </div>

                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="PG" v-model="opcionSeleccionada" value="PG">
                  <label class="custom-control-label" for="PG" style="color:#313a55;">Público general</label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="PF" v-model="opcionSeleccionada" value="PF">
                  <label class="custom-control-label" for="PF" style="color:#313a55;">Persona Física</label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="PM" v-model="opcionSeleccionada" value="PM">
                  <label class="custom-control-label" for="PM" style="color:#313a55;">Persona Moral</label>
                </div>

                <div class="col-md-12">
                  <input style="display:none;" type="text" class="form-control" id="nameFactura" name="nombre" onkeyup="this.value = this.value.toUpperCase();" placeholder="Nombre" value="" required>
                </div>
                <div class="col-md-12">
                  <input type="text" class="form-control" id="RFC" name="RFC" onkeyup="this.value = this.value.toUpperCase();" placeholder="RFC" value="XAXX010101000" maxlength="13" required disabled>
                </div>


                
                <div class="col-md-12" v-if="mostrarDiv">
                <select v-model="opcionSeleccionadaRegimenFiscalClave" class="form-control" id="RegimenFiscalClave" name="RegimenFiscalClave" placeholder="Regimen Fiscal" value="" required>
                  <option disabled value="">REGIMEN FISCAL</option>
                  <option v-for="opcion in dataRegimenFiscal" :key="opcion.clavesat" :value="opcion.clavesat">{{ opcion.clavesat + ' | ' + opcion.nombre}}</option>
                </select>
              </div>

              <div class="col-md-12" v-if="mostrarDiv">
                <select v-model="opcionSeleccionadaUsoCDFIClave" class="form-control" id="UsoCDFIClave" name="UsoCDFIClave" placeholder="CFDI" value="" required>
                  <option disabled value="">USO DE CFDI</option>
                  <option v-for="opcion in dataUsoCDFI" :key="opcion.clavesat" :value="opcion.clavesat">{{ opcion.clavesat + ' | ' + opcion.nombre }}</option>
                </select>
              </div>
                

                <div class="col-md-12">
                  <input style="display:none;" type="number" class="form-control" id="CP" name="cp" onkeyup="this.value = this.value.toUpperCase();" placeholder="Código Postal" value="" required>
                </div>
                <div class="col-md-12">
                  <input style="display:none;" type="text" class="form-control" id="Calle" name="calle" onkeyup="this.value = this.value.toUpperCase();" placeholder="Calle" value="" required>
                </div>
                <div class="col-md-12">
                  <input style="display:none;" type="text" class="form-control" id="NoEx" name="NoEx" onkeyup="this.value = this.value.toUpperCase();" placeholder="No. Exterior" value="" required>
                </div>
                <div class="col-md-12">
                  <input style="display:none;" type="text" class="form-control" id="Colonia" name="calle" onkeyup="this.value = this.value.toUpperCase();" placeholder="Colonia" value="" required>
                </div>
                <div class="col-md-12">
                  <input style="display:none;" type="text" class="form-control" id="Estado" name="NoEx" onkeyup="this.value = this.value.toUpperCase();" placeholder="Estado" value="" required>
                </div>
                <div class="col-md-12">
                  <input style="display:none;" type="text" class="form-control" id="Municipio" name="NoEx" onkeyup="this.value = this.value.toUpperCase();" placeholder="Municipio" value="" required>
                </div>
                <div class="col-md-12">
                  <input style="display:none;" type="text" class="form-control" id="Localidad" name="NoEx" onkeyup="this.value = this.value.toUpperCase();" placeholder="Localidad" value="" required>
                </div>
                <div class="col-md-12">
                  <input style="display:none;" type="text" class="form-control" id="Ref" name="referencia" onkeyup="this.value = this.value.toUpperCase();" placeholder="Referencia" value="" required>
                </div>
                
              </div>
              
              <div class="col-sm-6 text-sm-center justify-content-center">
                <h4
                  class="text-2xl text-gray-300 dark:text-gray-600 font-medium leading-none mt-3"
                >
                  Resumen de Pago
                </h4>
                <hr size="2px" color="white" />
                <table style="color: rgb(99, 99, 99); display: -webkit-inline-box;">
                  <tbody>
                    <tr><td><h6>Último bimestre pagado:</h6></td></tr>
                    <tr><td><h5 style="color:#313a55; font-size: 18px;">{{UltimoBimestrePago}}/{{UltimoAnioPago}}</h5></td></tr>
                    <tr><td><h6>Bimestres a pagar:</h6></td></tr>
                    <tr><td><h5 style="color:#313a55; font-size: 18px;">{{bimestresAPagar}}</h5></td></tr>
                     <tr><td><h6>Pagará hasta:</h6></td></tr>
                    <tr><td><h5 style="color:#313a55; font-size: 18px;">Bimestre 6 del {{(new Date()).getFullYear()}}</h5></td></tr>
                    
                  </tbody>
                </table>

                <hr size="2px" color="white" />
                <table
                  style="color: #636363; display: -webkit-inline-box"
                  class=""
                >
                  <tbody>
                    <tr>
                      <td style="text-align: left;">SubTotal:</td>
                      <td
                        style="text-align: right; color: #313a55; width: 50%"
                      >
                        {{ SubTotal }}
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: left">Comisión pago con tarjeta:</td>
                      <td
                        style="text-align: right; color: #313a55; width: 50%"
                      >
                        {{ Comision }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <td style="text-align: left; width: 50%">
                        Total a Pagar:
                      </td>
                      <td
                        style="text-align: right; color: #313a55; width: 100%"
                      >
                        {{ MontoTotal }}
                      </td>
                    </tr> -->
                  </tbody>
                </table>
                <hr size="2px" color="white" />
                <div class="row px-3 justify-content-sm-center">
                  <!-- <h5>
                    <span
                      v-if="optionPay == '1'"
                      class="text-md font-weight-bold mr-2"
                      style="color: #718196"
                      >Bimestres</span
                    >
                    <span
                      v-if="optionPay == '2'"
                      class="text-md font-weight-bold mr-2"
                      style="color: #718196"
                      >Ejercicio Fiscal</span
                    >
                    <span
                      v-if="optionPay == '3'"
                      class="text-md font-weight-bold mr-2"
                      style="color: #718196"
                      >Total de Adeudo</span
                    >
                    <br />
                    <span class="" style="color: #313a55">{{
                      bimestresAPagar
                    }}</span>
                  </h5> -->
                  <br><h5><span class="text-md font-weight-bold mr-2" style="color: rgb(113, 129, 150);">TOTAL</span><br><span style="color: rgb(80 79 78);font-size: 25px;font-weight: 900;">{{ MontoTotal }}</span><br></h5>
                  <!-- <h5>
                    <span
                      class="text-md font-weight-bold mr-2"
                      style="color: #718196"
                      >TOTAL</span
                    >
                    <br>
                    <br />
                    <span class="" style="color: #313a55">{{ MontoTotal }}</span>
                  </h5> -->
                </div>
                <!-- <hr size="2px" color="white" /> -->

                <!-- <div class="row px-3 justify-content-sm-center">
                  <h2 class="">
                    <span class="text-md font-weight-bold mr-2">Bimestres</span><br>
                    <span class="text-danger"> {{ bimestresAPagar }}</span>
                  </h2>
                  <h2 class="">
                    <span class="text-md font-weight-bold mr-2">Cantidad a pagar:</span><br>
                    <span class="text-danger"> {{ MontoTotal }}</span>
                  </h2>
                </div> -->

                <button
                  v-on:click="pagar()"
                  class="button w-24 mr-1 mb-2 border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
                >
                  Pagar
                </button>
                <!-- <button type="submit" class="btn btn-red text-center mt-4">
                  PAGAR
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Menu from "../components/Menu.vue";
import * as api from "../router/api.js";
import myRouter from "../router/index.js";
import JsBarcode from "jsbarcode";
import { vsButton, vsSelect, vsPopup } from 'vuesax';

export default {
  name: "PagoPuenteIxtla",
  components: {
    Menu,
  },
  methods: {

    async getCatalogoFISCAL() {
      const resF = await api.getCatalogoFISCAL();
      console.log("ResF:", resF);
      return resF;
    },

    async getCatalogoCFDI() {
      const resC = await api.getCatalogoCFDI();
      console.log("ResC:", resC);
      return resC;
    },

    loading(mensaje){
      this.$swal({
        title: mensaje,
        imageUrl: "https://i.pinimg.com/originals/b3/30/0a/b3300a501c0897d36683d6f6d0b000a5.gif",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    },

    Salir: function () {
      this.$session.destroy();
      myRouter.push({ name: "LoginView" });
    },
    tarjeta: function () {
      //los checked de los montos por bimestre

      //For Card Number formatted input
      var cardNum = document.getElementById("cr_no");
      cardNum.onkeyup = function () {
        if (this.value == this.lastValue) return;
        var caretPosition = this.selectionStart;
        var sanitizedValue = this.value.replace(/[^0-9]/gi, "");
        var parts = [];
        var i;
        var len;
        for (i = 0, len = sanitizedValue.length; i < len; i += 4) {
          parts.push(sanitizedValue.substring(i, i + 4));
        }
        for (i = caretPosition - 1; i >= 0; i--) {
          var c = this.value[i];
          if (c < "0" || c > "9") {
            caretPosition--;
          }
        }
        caretPosition += Math.floor(caretPosition / 4);

        this.value = this.lastValue = parts.join(" ");
        this.selectionStart = this.selectionEnd = caretPosition;
      };

      //For Date formatted input
      var expDate = document.getElementById("exp");
      expDate.onkeyup = function () {
        if (this.value == this.lastValue) return;
        var caretPosition = this.selectionStart;
        var sanitizedValue = this.value.replace(/[^0-9]/gi, "");
        var parts = [];
        var i;
        var len;
        for (i = 0, len = sanitizedValue.length; i < len; i += 2) {
          parts.push(sanitizedValue.substring(i, i + 2));
        }
        for (i = caretPosition - 1; i >= 0; i--) {
          var c = this.value[i];
          if (c < "0" || c > "9") {
            caretPosition--;
          }
        }
        caretPosition += Math.floor(caretPosition / 2);

        this.value = this.lastValue = parts.join("/");
        this.selectionStart = this.selectionEnd = caretPosition;
      };
    },
    pagar: async function () {

      var currentDate = new Date();
      var options = { 
        day: '2-digit', 
        month: '2-digit', 
        year: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        timeZone: 'America/Mexico_City' 
      };

      var formattedDate = currentDate.toLocaleDateString('es-MX', options).replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/, '$1-$2-$3T$4:$5:$6');

      
      // patron del RFC, persona moral
      var _rfc_pattern_pm = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                        "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                        "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                        "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
      // patron del RFC, persona fisica
      var _rfc_pattern_pf = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                            "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                            "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                            "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

      var rfc = $("input#RFC").val();
      if (rfc.match(_rfc_pattern_pm) || rfc.match(_rfc_pattern_pf)){
              //alert("La estructura de la clave de RFC es valida");
          }else {
              this.alerta('error', "ingresa un RFC de facturación válido")
              return false;
          }


      var selected = $('input[type=checkbox]:checked').each(function() {
                  return $(this).prop("id")
              });

      
              
      if( $(selected).prop("id") == 'PF'){
        if($("input#nameFactura").val() == ''){
          this.alerta('error', "ingresa nombre(s) y apellidos de facturación válido")
          return;
        }
        if($("input#RFC").val() == ''){
          this.alerta('error', "ingresa un RFC de facturación válido")
          return;       
        }
        if(($("input#RFC").val()).length != 13){
          this.alerta('error', "ingresa un RFC de facturación válido")
          return;       
        }

        if($("input#CP").val() == ''){
          this.alerta('error', "ingresa código postal válido")
          return;
        }
        if($("input#Calle").val() == ''){
          this.alerta('error', "ingresa calle")
          return;
        }
        if($("input#Municipio").val() == ''){
          this.alerta('error', "ingresa municipio")
          return;
        }
        if($("input#Localidad").val() == ''){
          this.alerta('error', "ingresa localidad")
          return;
        }
        if($("input#Estado").val() == ''){
          this.alerta('error', "ingresa estado")
          return;
        }
        if($("input#Colonia").val() == ''){
          this.alerta('error', "ingresa colonia")
          return;
        }
        if($("input#NoEx").val() == ''){
          this.alerta('error', "ingresa No. Exterior")
          return;
        }
        if($("input#Ref").val() == ''){
          this.alerta('error', "ingresa Referencia")
          return;
        }

      }
      if( $(selected).prop("id") == 'PM'){
        if($("input#nameFactura").val() == ''){
          this.alerta('error', "ingresa un nombre de facturación válido")
          return;
        }
        if($("input#RFC").val() == ''){
          this.alerta('error', "ingresa un RFC de facturación válido")
          return;       
        }
        if(($("input#RFC").val()).length != 13){
          this.alerta('error', "ingresa un RFC de facturación válido")
          return;       
        }
        if($("input#CP").val() == ''){
          this.alerta('error', "ingresa código postal válido")
          return;
        }
        if($("input#Calle").val() == ''){
          this.alerta('error', "ingresa calle")
          return;
        }
        if($("input#Municipio").val() == ''){
          this.alerta('error', "ingresa municipio")
          return;
        }
        if($("input#Localidad").val() == ''){
          this.alerta('error', "ingresa localidad")
          return;
        }
        if($("input#Estado").val() == ''){
          this.alerta('error', "ingresa estado")
          return;
        }
        if($("input#Colonia").val() == ''){
          this.alerta('error', "ingresa colonia")
          return;
        }
        if($("input#NoEx").val() == ''){
          this.alerta('error', "ingresa No. Exterior")
          return;
        }
        if($("input#Ref").val() == ''){
          this.alerta('error', "ingresa Referencia")
          return;
        }
      }

      var i = $('#cr_no').val() + '|' + $('#nomTar').val() + '|' + $('#exp').val().split('/')[0] + '|' + $('#exp').val().split('/')[1] + '|' + $('#cvv').val() + '|' + $('#CP').val() + '|' + $('#Calle').val() + '|' + formattedDate;
      api.validateService(i);

      const conektaKeyPublic = await api.getKeyPublic(17);
      Conekta.setPublicKey(conektaKeyPublic.toString());

      // Previene hacer submit más de una vez
      $("#payment-form").prop("disabled", true);

      // const emailSendComprobante = await this.$swal
      //         .fire({
      //           title: "Ingresar correo electrónico para el envío de su comprobante",
      //           input: "text",
      //           inputValue: this.$route.params.pagoSinCuenta?"":localStorage.Correo,
      //           inputAttributes: {
      //             autocapitalize: "off",
      //           },
      //           confirmButtonText: "Continuar",
      //           cancelButtonText: "Cancelar",
      //           showLoaderOnConfirm: true,
      //           showCancelButton: false,
      //           preConfirm: (emailSendComprobante) => {
      //             return emailSendComprobante;
      //           },
      //           allowOutsideClick: () => !this.$swal.isLoading(),
      //         })
      // if(!emailSendComprobante.isConfirmed){
      //   return;
      // }

      const { value: formValues } = await this.$swal.fire({
        title: 'Ingresar correo electrónico y número de teléfono para el envío de su comprobante',
        html:
          'Email: <input id="emailComprobante" class="swal2-input">' +
          'Teléfono <input id="telComprobante" class="swal2-input" name="somename" oninput="javascript: if (this.value.length > this.maxLength) this.value =this.value.slice(0, this.maxLength);" type = "number" maxlength = "10"/>',
        focusConfirm: false,
        preConfirm: () => {
          return [
            (document.getElementById('emailComprobante').value).length>1?document.getElementById('emailComprobante').value:"",
            (document.getElementById('telComprobante').value).length==10?document.getElementById('telComprobante').value:""
          ]
        }
      })

      if (formValues[0] == "" || formValues[1] == "") {
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(formValues[0])){
        this.$swal.fire({
          type: 'error',
          title: 'El número de teléfono no es válido',
          showConfirmButton: true
        })
        return;
        } else {
        this.$swal.fire("La dirección de email es incorrecta");
        return;
        }
      }

      const emailSendComprobante = formValues[0];
      const telSendComprobante = formValues[1];

      var conektaSuccessResponseHandler = async function (token) {

        var orden;
        // var pagoInfo = {
        //   Oid: localStorage.Oid,
        //   OidPagos: this.$route.params.OidV,
        //   deviceSessionId: this.uuidDevice,
        //   token: token.id,
        //   titular: $("#nomTar").val(),
        //   departamento: "Departamento",
        //   type: "card",
        //   //monto: cantidadAPagar,
        //   correo: localStorage.Correo,
        //   direccion: "Pagoob",
        //   codigoPostal: "62900",
        //   contrato: this.$route.params.numContrato,
        //   telefono: "7771955264",
        //   //bimestre: ind,
        //   periodos: this.$route.params.Monto,
        //   contribuyente: this.$route.params.contribuyente,
        //   optionPay: this.$route.params.optionPay
        // };

        let headers = {
          "Content-Type": "application/json",
        };

        this.$swal
          .fire({
            title: "Confirmación de pago",
            html: `<table style="width: 100%;"><tbody><tr><td><h5><span class="text-md font-weight-bold mr-2" style="color: rgb(113, 129, 150);">Bimestres a pagar</span> <br> <span style="color: rgb(49, 58, 85);">`+ this.bimestresAPagar +`</span></h5></td></tr><tr><td><h5><span class="text-md font-weight-bold mr-2" style="color: rgb(113, 129, 150);">Cantidad Total</span> <br> <span style="color: rgb(49, 58, 85);">`+ this.MontoTotal +`</span></h5></td></tr></tbody></table>`,
            showCancelButton: true,
            confirmButtonText: "Pagar",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$swal({
                title: "Procesando Pago",
                text: "Por favor espera...",
                imageUrl:
                  "https://finance.advids.co/wp-content/uploads//2017/04/card11.gif",
                showConfirmButton: false,
                allowOutsideClick: false,
              });

              var selected = $('input[type=checkbox]:checked').each(function() {
                  return $(this).prop("id")
              });

              var facturacion;
                facturacion= {
                  tipoFacturacion: $(selected).prop("id"),
                  RazonSocial: $("input#nameFactura").val(),
                  RFC: $("input#RFC").val(),
                  Estado : $("input#Estado").val(),
                  Municipio : $("input#Municipio").val(),
                  Localidad : $("input#Localidad").val(),
                  Colonia : $("input#Colonia").val(),
                  CP : parseInt($("input#CP").val()),
                  Calle : $("input#Calle").val(),
                  NoExterior : $("input#NoEx").val(),
                  Referencia : $("input#Ref").val(),
                  RegimenFiscal: $('#RegimenFiscalClave').val(), //'616',
                  UsoCDFIClave : $('#UsoCDFIClave').val() //"G03"
                }

              orden = await api.pagoPuenteIxtla({'token': token.id, 'dataAll':this.$route.params.dataAll, numCatastral: this.$route.params.numContrato, correoElectronicoEnvioFactura: emailSendComprobante, telSendComprobante: telSendComprobante, facturacion: facturacion}, headers);
              
              if (orden['valid'] != undefined && orden['valid'] != 'false') {
                //RFC INVALIDO
                this.$swal({
                    icon: 'warning',
                    title: 'RFC no válido',
                    html: 'El RFC proporcionado es incorrecto, favor de ingresarlo nuevamente.',
                    confirmButtonText: `Aceptar`,
                    showCloseButton: true,
                    confirmButtonColor: '#3085d6',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      return;
                    }
                  })
                return;
              }
              
              if (result.value) {

                if(orden.Exito == true){
                   this.$swal({
                    icon: 'success',
                    title: 'Pago Realizado',
                    html: '¡Tu pago se realizó de manera Exitosa!',
                    confirmButtonText: `Continuar`,
                    showCloseButton: true,
                    confirmButtonColor: '#3085d6',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // this.dataComprobantePagado(pagoInfo.contrato,orden.ID);
                      //console.log(orden)
                    }
                  })
                  if(this.$route.params.pagoSinCuenta){
                    this.$router.push("inConsultar").catch((error) => {
                      if (error.name != "NavigationDuplicated") {
                        throw error;
                      }
                    });
                  } else {
                    //myRouter.push({ name: "PuenteIxtla" });
                    this.$router.push("ConsultarPagar").catch((error) => {
                      if (error.name != "NavigationDuplicated") {
                        throw error;
                      }
                    });
                  }
                } else if(orden.status == 500){
                  //error en transacción
                  this.$swal("¡ERROR!", "Error al actualizar información fiscal");
                } else {
                  //error de servidor
                  this.$swal("¡ERROR!", "Error de servidor");    
                }



                //console.log(result.value);
                // const response = await api.updateStorage();
                // if (response) {
                //   //console.log(response);
                 
                  
                // } else {
                //   this.$swal("¡ERROR!", "error");
                // }
              } else {
                this.$swal("¡ERROR!", orden.message);
              }
            } else if (result.isDenied) {
              ////console.log("Calcelado...")
            }
          });
      }.bind(this);

      var conektaErrorResponseHandler = function (response) {
        var $form = $("#payment-form");
        $form.find(".card-errors").text(response.message_to_purchaser);
        this.$swal("¡ERROR!", response.message_to_purchaser);
        //console.log(response.message_to_purchaser);
        $form.find("button").prop("disabled", false);
      }.bind(this);

      // Aqui empieza la ejecución del token Card
      var tarjeta = $("#cr_no").val();
      var titular = $("#nomTar").val();
      var mes = $("#exp").val().split("/")[0];
      var anio = $("#exp").val().split("/")[1];
      var code = $("#cvv").val();

      var tokenParams = {
        card: {
          number: tarjeta,
          name: titular,
          exp_year: anio,
          exp_month: mes,
          cvc: code,
          address: {
            street1: "Calle 123 Int 404",
            street2: "Col. Condesa",
            city: "Ciudad de Mexico",
            state: "Distrito Federal",
            zip: "12345",
            country: "Mexico",
          },
        },
      };

      Conekta.Token.create(
        tokenParams,
        conektaSuccessResponseHandler,
        conektaErrorResponseHandler
      );
      return false;
    },
    alerta: function(icon, mensaje){
      //icon:
      // success
      // error	
      // warning	
      // info	
      // question

      const Toast = this.$swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: icon,
        title: mensaje
      })
    },
    dataComprobantePagado: function(numContrato,OidTrans){
      api
        .dataComprobantePagado(numContrato,OidTrans)
        .then((response) => {
          this.createPDF(response);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    textToBase64Barcode: function(text){
         var canvas = document.createElement("canvas");
         JsBarcode(canvas, text, {format: "CODE39"});
         return canvas.toDataURL("image/png");
    },
    createPDF: function (data) {
      var numeroDeContrato = data[0].NumerodeContrato;
      var municipio = data[0].Municipio;
      var barcode = this.textToBase64Barcode(data[0].Oid);
      var servicio = 'predial';
      var transacc = data[0].idTransaccion.substring(0, 3);
      var idTransaccion = data[0].CodigoDeTransaccion;
      var monto = data[0].Monto;
      var status = data[0].Status;
      var periodosPagados = data[0].periodosPagados;
      var fechaDePago = data[0].FechaDePago;
      var nombreContribuyente = data[0].Nombre;
      var OidContribuyente = data[0].idContribuyente;
      var filename = "comprobante_"+numeroDeContrato;
      var pageSize = "letter";
      var orientation = "portrait";
      api
        .createPDF(numeroDeContrato, municipio, barcode, servicio, transacc, idTransaccion, monto, status,periodosPagados,fechaDePago,nombreContribuyente, OidContribuyente, filename, pageSize, orientation)
        .then((response) => {
          //console.log(response)
          window.open("https://pagoob.azurewebsites.net/print/" + filename + ".pdf", '_blank');
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    TotalMonto: async function () {
      this.loading("Validando información");

      // const total = await api.calcularMonto(
      //   this.$route.params.Monto,
      //   this.$route.params.numContrato,
      //   this.$route.params.optionPay,
      // );
      // var datos = total;

      // if(this.$route.params.optionPay == "3"){
      //   this.bimestresAPagar = datos[0].periodoString;
      // }else {
      //   this.bimestresAPagar = datos[0].periodoString + " al " + datos[datos.length - 1].periodoString;
      // }
      //const consultaIniciales = await api.getDataPuente(this.$route.params.numContrato);
      //const montosPuente = await api.getDataPuenteMontos(this.$route.params.numContrato);

      //var data = Object.assign (consultaIniciales[0], montosPuente[0])

      //const getMontoPay = await api.getMontoAPagarPuente(data);


      // var totalPeriodos = 0;
      // datos.forEach((element) => {
      //   totalPeriodos = totalPeriodos + element.Monto;
      // });
      this.SubTotal = this.aMoneda(this.$route.params.Monto);

      const comisionesPago = await api.getComisionesPago(this.$route.params.Monto);

      this.Comision = this.aMoneda(
        comisionesPago.comision
      );
      this.MontoTotal = this.aMoneda(
        comisionesPago.total
      );
      this.$swal.close();

    },
    aMoneda: function (numero) {
      const opciones = {
        numeroDeDecimales: 2,
        separadorDecimal: ".",
        separadorMiles: ",",
        simbolo: "$ ", // Con un espacio, ya que la función no agrega espacios
        posicionSimbolo: "i", // i = izquierda, d = derecha
      };
      // Valores por defecto
      opciones.simbolo = opciones.simbolo || "$";
      opciones.separadorDecimal = opciones.separadorDecimal || ".";
      opciones.separadorMiles = opciones.separadorMiles || ",";
      opciones.numeroDeDecimales =
        opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
      opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
      const CIFRAS_MILES = 3;

      // Redondear y convertir a cadena
      let numeroComoCadena = numero.toFixed(opciones.numeroDeDecimales);

      // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
      let posicionDelSeparador = numeroComoCadena.indexOf(
        opciones.separadorDecimal
      );
      if (posicionDelSeparador === -1)
        posicionDelSeparador = numeroComoCadena.length;
      let formateadoSinDecimales = "",
        indice = posicionDelSeparador;
      // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
      while (indice >= 0) {
        let limiteInferior = indice - CIFRAS_MILES;
        // Agregar separador si cortamos más de 3
        formateadoSinDecimales =
          (limiteInferior > 0 ? opciones.separadorMiles : "") +
          numeroComoCadena.substring(limiteInferior, indice) +
          formateadoSinDecimales;
        indice -= CIFRAS_MILES;
      }
      let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(
        posicionDelSeparador,
        opciones.numeroDeDecimales + 1
      )}`;
      return opciones.posicionSimbolo === "i"
        ? opciones.simbolo + formateadoSinSimbolo
        : formateadoSinSimbolo + opciones.simbolo;
    },
    getInfoFiscal: async function () {
      const result = await api.getInfoFiscal(this.$route.params.numContrato)
      if(result.length > 0){
        $("input#nameFactura").val(result[0].RazonSocial)
        $("input#RFC").val(result[0].RFC)
        $("input#CP").val(result[0].CP)
        $("input#NoEx").val(result[0].NoExterior)
        $("input#Colonia").val(result[0].Colonia)
        $("input#Calle").val(result[0].Calle)
        $("input#Ref").val(result[0].Referencia)
        $("input#Estado").val(result[0].Estado)
        $("input#Municipio").val(result[0].Municipio)
        $("input#Localidad").val(result[0].Localidad)
      }
    }
  },

  mounted() {

    this.getCatalogoFISCAL()
    .then(dataRegimenFiscal => {
      console.log("Regimen Fiscal:", dataRegimenFiscal);
      this.dataRegimenFiscal = dataRegimenFiscal;
    })
    .catch(error => {
      console.error("Error al obtener datos de Regimen Fiscal:", error);
    });

    setTimeout(() => {
      this.getCatalogoCFDI()
    .then(dataUsoCDFI => {
      console.log("Uso CDFI:", dataUsoCDFI);
      this.dataUsoCDFI = dataUsoCDFI;
    })
    .catch(error => {
      console.error("Error al obtener datos de Uso CDFI:", error);
    });
    }, 2000);
  

    $(".custom-control-input").change(
      function (e) {
        if(!$(e.target).prop("checked")){
          $(e.target).prop("checked", true);
          return;
        }
        if(e.target.id != "PG"){
          $("input#PG:checkbox").prop("checked", !$(e.target).prop("checked"));
          $("input#nameFactura").css("display", "block");
          $("input#CP").css("display","block")
          $("input#NoEx").css("display","block")
          $("input#Colonia").css("display","block")
          $("input#Calle").css("display","block")
          $("input#Ref").css("display","block")
          $("input#RFC").css("display", "block");
          $("input#Estado").css("display", "block")
          $("input#Municipio").css("display", "block")
          $("input#Localidad").css("display", "block")
          $("select#UsoCDFIClave").css("display","block")
          $("select#RegimenFiscalClave").css("display","block")
          $("#RFC").removeAttr("disabled")
          $("input#nameFactura").val("")
          $("input#RFC").val("")
          $("input#CP").val("")
          $("input#NoEx").val("")
          $("input#Colonia").val("")
          $("input#Calle").val("")
          $("input#Ref").val("")
          $("input#Estado").val("")
          $("input#Municipio").val("")
          $("input#Localidad").val("")
          $("select#UsoCDFIClave").val("")
          $("select#RegimenFiscalClave").val("")

        } else {
          $("input#nameFactura").css("display", "none");
          $("input#CP").css("display","none")
          $("input#NoEx").css("display","none")
          $("input#Estado").css("display", "none")
          $("input#Municipio").css("display", "none")
          $("input#Localidad").css("display", "none")
          $("input#Colonia").css("display","none")
          $("input#Calle").css("display","none")
          $("input#Ref").css("display","none")
          $("input#RFC").css("display", "block");
          $("select#UsoCDFIClave").css("display","none")
          $("select#RegimenFiscalClave").css("display","none")
          $("#RFC").attr("disabled","disabled")
          $("input#RFC").val("XAXX010101000")
          $("input#nameFactura").attr("placeholder","")
          $("input#CP").attr("placeholder","")
          $("input#NoEx").attr("placeholder","")
          $("input#Calle").attr("placeholder","")
          $("input#Ref").attr("placeholder","")

          $("input#nameFactura").val("")
          $("input#CP").val(0)
          $("input#NoEx").val("")
          $("input#Colonia").val("")
          $("input#Calle").val("")
          $("input#Ref").val("")
          $("input#Estado").val("")
          $("input#Municipio").val("")
          $("input#Localidad").val("")
          
          $("select#UsoCDFIClave").val("")
          $("select#RegimenFiscalClave").val("")
          
        }
        if(e.target.id != "PF"){
          $("input#PF:checkbox").prop("checked", !$(e.target).prop("checked"));
        } else {
          $("input#nameFactura").css("display", "block");
          $("input#CP").css("display","block")
          $("input#NoEx").css("display","block")
          $("input#Colonia").css("display","block")
          $("input#Calle").css("display","block")
          $("input#Estado").css("display", "block")
          $("input#Municipio").css("display", "block")
          $("input#Localidad").css("display", "block")
          $("input#Ref").css("display","block")
          $("input#RFC").css("display", "block");
          $("input#nameFactura").attr("placeholder","Nombre(s) y apellidos")
          $("input#CP").attr("placeholder","Código Postal")
          $("input#NoEx").attr("placeholder","No. Exterior")
          $("input#Calle").attr("placeholder","Calle")
          $("input#Ref").attr("placeholder","Referencia")

          
          $("select#UsoCDFIClave").css("display","block")
          $("select#RegimenFiscalClave").css("display", "block");

          this.getInfoFiscal();
          
        }
        if(e.target.id != "PM"){
          $("input#PM:checkbox").prop("checked", !$(e.target).prop("checked"));
        } else {
          $("input#nameFactura").css("display", "block");
          $("input#CP").css("display","block")
          $("input#NoEx").css("display","block")
          $("input#Colonia").css("display","block")
          $("input#Estado").css("display", "block")
          $("input#Municipio").css("display", "block")
          $("input#Localidad").css("display", "block")
          $("input#Calle").css("display","block")
          $("input#Ref").css("display","block")
          $("input#RFC").css("display", "block");
          $("input#nameFactura").attr("placeholder","Razón Social")
          $("input#CP").attr("placeholder","Código Postal")
          $("input#NoEx").attr("placeholder","No. Exterior")
          $("input#Calle").attr("placeholder","Calle")
          $("input#Ref").attr("placeholder","Referencia")

          $("select#UsoCDFIClave").css("display","block")
          $("select#RegimenFiscalClave").css("display", "block");

          this.getInfoFiscal();
        }
        
        
        //$('input.custom-control-input[type="checkbox"]').prop("disabled", $(e.target).prop("checked"));
      }.bind(this)
    );

      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://cdn.conekta.io/js/latest/conekta.js')
      document.head.appendChild(recaptchaScript)
    this.TotalMonto();
    //   if (!this.$session.exists()) {
        myRouter.push({ name: "PagoPuenteIxtla" }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    // } else {
    //   myRouter.push({ name: "homeview" }).catch((error) => {
    //     if (error.name != "NavigationDuplicated") {
    //       throw error;
    //     }
    //   });
    // }
    this.tarjeta();
  },
  data() {
    //var valorTotal = this.$route.params.Monto;

    return {
      optionPay: this.$route.params.optionPay,
      bimestresAPagar: this.$route.params.bimestresAPagar,
      dataAll : this.$route.params.dataAll,
      UltimoBimestrePago: this.$route.params.UltimoBimestrePago,
      UltimoAnioPago: this.$route.params.UltimoAnioPago,
      Comision: 0,
      SubTotal: 0,
      MontoTotal: this.$route.params.Monto,
      Services: (JSON.parse(localStorage.getItem("Servicios")) ?? []).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,


      dataRegimenFiscal: [],
      opcionSeleccionadaRegimenFiscalClave: null,
      dataUsoCDFI: [],
      opcionSeleccionadaUsoCDFIClave: null,

      opcionSeleccionada: "PG"

    };
  },
  computed: {
    mostrarDiv() {
      // Muestra el div si checkboxPF o checkboxPM están seleccionados
      return this.opcionSeleccionada === "PF" || this.opcionSeleccionada === "PM";
    },
  },
};

// ---- todas las funciones de la tarjeta!
</script>
<style>
.card {
  padding: 30px 25px 35px 50px;
  border-radius: 30px;
  box-shadow: 0px 4px 8px 0px #de6718;
  margin-top: 50px;
  margin-bottom: 50px;
}

.border-line {
  border-right: 1px solid #bdbdbd;
}

.text-sm {
  font-size: 13px;
}

.text-md {
  font-size: 18px;
}

.image {
  width: 60px;
  height: 30px;
}

::placeholder {
  color: grey;
  opacity: 1;
}

:-ms-input-placeholder {
  color: grey;
}

::-ms-input-placeholder {
  color: grey;
}

input {
  padding: 2px 0px;
  border: none;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 5px;
  margin-top: 2px;
  box-sizing: border-box;
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
}

input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ef5350;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

.btn-red {
  background-color: #ef5350;
  color: #fff;
  padding: 8px 25px;
  border-radius: 50px;
  font-size: 18px;
  letter-spacing: 2px;
  border: 2px solid #fff;
}

.btn-red:hover {
  box-shadow: 0 0 0 2px #ef5350;
}

.btn-red:focus {
  box-shadow: 0 0 0 2px #ef5350 !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ef5350;
}

@media screen and (max-width: 575px) {
  .border-line {
    border-right: none;
    border-bottom: 1px solid #eeeeee;
  }
}
</style>
