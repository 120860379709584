<template>
<section>
<div>
<!-- Bootstrap row -->
    <!-- Sidebar -->
    <!-- MAIN -->
    <div class="col">
        <h1 class="p" style="color:#fff;">
          <FONT FACE="arial" SIZE=6><i class="fas fa-user-circle"></i>
          Invitado</FONT>
            <small class="text-muted"></small>
        </h1>
        <hr size="2px" color="white" />
        <div>
            <div class="card-body" style="background-color:rgba(179, 179, 179, 0.57);">
          <ul>
            <div class="py-4 text-center">
      <div class="corazon">
      <img class="d-block mx-auto" src="../assets/logoP.svg" alt="" width="300" style="max-width: 95%;min-width:90%;">
      </div>
       <h4 class="mb-4" style="color:#FDFEFE;">Ingresa el numero de tu contrato</h4>
    </div>
      <div class="row justify-content-md-center" >
        <div class="col-md-10 order-md-1">
         <div>
            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="firstName" style="color:#FDFEFE;">Numero De Contrato: </label>
                <input type="text" class="form-control" id="numContrato" name="nombre" placeholder="" value="" required>
                <div class="invalid-feedback">
                  El Nombre es Requerido.
                </div>
              </div>
              <div class="col-md-3 mb-1 d-sm-block">
                <label for="lastName" style="color:#FDFEFE;">Busca:</label>
                <br>
                <button v-on:click='principal()' type="button" class="form-control btn-primary "><i class="fas fa-search mr-1"></i>Buscar</button>
              </div>
              <div class="col-md-3 mb-1 d-sm-block">
                <label for="lastName" style="color:#FDFEFE;">Limpiar:</label>
                <br>
                <button v-on:click='Limpiar()'  type="button" class="form-control btn-primary "><i class="fas fa-paint-brush mr-1"></i>Limpiar</button>
              </div>
            </div>

             <div class="row">
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">Servicio: </label>
              <input type="text" style="background-color:white" class="form-control" id="Servicio" name="rfc" disabled="disabled" placeholder="Servicio" required>
              <div class="invalid-feedback">
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">Descripcion: </label>
              <input type="text" style="background-color:white" class="form-control" id="Descripcion" disabled="disabled" name="telefono" placeholder="Descripcion" required>
              <div class="invalid-feedback">
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">Precio: </label>
              <input type="text" style="background-color:white" class="form-control" id="precio" disabled="disabled" name="rasocial" placeholder="Precio" required>
              <div class="invalid-feedback">
              </div>
            </div>

            </div>
             <hr class="mb-4" style="background-color:#FDFEFE;">
            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="nombre" style="color:#FDFEFE;">Nombre(s): </label>
                <input type="text" class="form-control" id="Nombre"  disabled="disabled"  style="background-color:white" name="nombre" placeholder="" value="" required>
                <div class="invalid-feedback">
                  El Nombre es Requerido.
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="apaterno" style="color:#FDFEFE;">Apellido Paterno: </label>
                <input type="text" class="form-control" id="ApellidoPaterno" style="background-color:white"  disabled="disabled" name="Apaterno" placeholder="" value="" required>
                <div class="invalid-feedback">
                  El Apellido Paterno es Requerido.
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="amaterno" style="color:#FDFEFE;">Apellido Materno: </label>
                <input type="text" class="form-control"  disabled="disabled" style="background-color:white" id="ApellidoMaterno" name="Amaterno" placeholder="" value="" required>
                <div class="invalid-feedback">
                  El Apellido Materno es Requerido.
                </div>
              </div>
            </div>
            <div class="row">
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">RFC: </label>
              <input type="text" class="form-control"  disabled="disabled"  style="background-color:white" id="rfc" name="Rfc" placeholder="RFC" required>
              <div class="invalid-feedback">
                Ppr favor ingresa tu RFC, es indispensable.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="telefono" style="color:#FDFEFE;">Telefono: </label>
              <input type="number" class="form-control"  disabled="disabled" id="telefono" style="background-color:white" name="Telefono" placeholder="Telefono" required>
              <div class="invalid-feedback">
                Ppr favor ingresa tu Telefono, es indispensable.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="razonsocial" style="color:#FDFEFE;">Razón Social: </label>
              <input type="text" class="form-control" id="razonsocial"   disabled="disabled" style="background-color:white" name="RazonSocial" placeholder="Razon Social" required>
              <div class="invalid-feedback">
                Ppr favor ingresa la Razon Social, es indispensable.
              </div>
            </div>
            </div>
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <h4 class="mb-3" style="color:#FDFEFE;">Domicilio</h4>
            <div class="row">
              <div class="col-md-5 mb-3">
                <label for="calle" style="color:#FDFEFE;">Calle</label>
                <input type="text" style="background-color:white" disabled="disabled" class="form-control" id="calle" name="calle" placeholder="" required>
                <div class="invalid-feedback">
                  La Calle es Requerida.
                </div>
              </div>
              <div class="col-md-2 mb-3">
                <label for="numint" style="color:#FDFEFE;">Núm. Exterior</label>
                <input type="text" style="background-color:white" disabled="disabled" class="form-control" id="numext" name="numext" placeholder="" required>
                <div class="invalid-feedback">
                  El Número Exterior es Requerida.
                </div>
              </div>
              <div class="col-md-2 mb-3">
                <label for="numint" style="color:#FDFEFE;">Núm. Interior</label>
                <input type="text" style="background-color:white" disabled="disabled" class="form-control" id="numint" name="numint" placeholder="" required>
                <div class="invalid-feedback">
                  El Número Exterior es Requerida.
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="colonia" style="color:#FDFEFE;">Colonia</label>
                <input type="text" style="background-color:white" disabled="disabled" class="form-control" id="colonia" name="colonia" placeholder="" required>
                <div class="invalid-feedback">
                  La Colonia es Requerida.
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="colonia" style="color:#FDFEFE;">Ciudad</label>
                <input type="text" style="background-color:white" disabled="disabled" class="form-control" id="ciudad" name="ciudad" placeholder="" required>
                <div class="invalid-feedback">
                  La Colonia es Requerida.
                </div>
              </div>
              <div class="col-md-5 mb-3">
                <label for="colonia" style="color:#FDFEFE;">Municipio</label>
                <input type="text" style="background-color:white" disabled="disabled" class="form-control" id="Municipio" name="codpostal" placeholder="" required>
                <div class="invalid-feedback">
                  El Municipio es Requerido.
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="colonia" style="color:#FDFEFE;">Código Postal</label>
                <input type="text" style="background-color:white" disabled="disabled" class="form-control" id="codpos" name="codpostal" placeholder="" required>
                <div class="invalid-feedback">
                  El Código Postal es Requerida.
                </div>
              </div>
            </div>
            <!-- <hr class="mb-4">
            <h4 class="mb-3">Pago</h4>
            <div class="d-block my-3">
              <div class="custom-control custom-radio">
                <input id="credit" name="paymentMethod" type="radio" class="custom-control-input" checked required>
                <label class="custom-control-label" for="credit">Tarjeta de Credito</label>
              </div>
              <div class="custom-control custom-radio">
                <input id="debit" name="paymentMethod" type="radio" class="custom-control-input" required>
                <label class="custom-control-label" for="debit">Tarjeta de Débito</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="cc-name">Propietario:</label>
                <input type="text" class="form-control" id="cc-name" placeholder="" required>
                <small class="text-muted">Escribe el nombre completo del propietario</small>
                <div class="invalid-feedback">
                  El propietario es Requerido
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="cc-number">Número de tarjeta</label>
                <input type="text" class="form-control" id="cc-number" placeholder="" required>
                <div class="invalid-feedback">
                  Tu numero de tarjeta es Requerido
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 mb-3">
                <label for="cc-expiration">Vencimiento</label>
                <input type="text" class="form-control" id="cc-expiration" placeholder="" required>
                <div class="invalid-feedback">
                  Se Requiere Fecha de Vencimiento
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="cc-cvv">CVV</label>
                <input type="text" class="form-control" id="cc-cvv" placeholder="" required>
                <div class="invalid-feedback">
                  Se Requiere Código de Seguridad
                </div>
              </div>
            </div>
            -->
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <h1 class="p" style="color:#FDFEFE;">
          <FONT FACE="arial" SIZE=6><i class="fas fa-credit-card mr-1"></i>
          Pagos</FONT>
            <small class="text-muted"></small>
        </h1>
            <div class="table-responsive">
         <table class="table table-hover">
  <thead  style="background-color:#ffad00;">
    <tr>
      <th scope="col" class="text-center"><i class="fas fa-calendar mr-1"></i>Fecha De Cobro</th>
      <th scope="col" class="text-center"><i class="fas fa-calendar-check mr-1"></i>Periodo De Pago</th>
      <th scope="col" class="text-center"><i class="fas fa-check-circle mr-1"></i>Status</th>
      <th scope="col" class="text-center"><i class="fas fa-coins mr-1"></i>Monto</th>
       <th scope="col" class="text-center"><i class="fas fa-hand-holding-usd mr-1"></i>Pagos</th>
    </tr>
  </thead>
  <tbody style="background-color:#FFFFB0;" v-if="bandera" id="table">
      <tr v-for="ve in Venci" :key="ve.id">
       <td scope="row" class="text-center">{{ve.FechaDeCobro}}</td>
       <td scope="row" class="text-center">{{ve.PeriodoDePago}}</td>
       <td scope="row" class="text-center" style="color:#F51515;">{{ve.Status}}</td>
       <td scope="row" class="text-center">{{ve.Monto}}</td>
       <center>
      <td scope="row" class="text-center"><button id="PagarVen" v-on:click='PagoVen()' type="button" class="btn btn-primary"><i class="fas fa-credit-card mr-1"></i>Pagar</button></td>
      </center>
    </tr>
    <tr v-for="vi in Vigen" :key="vi.id">
       <td scope="row" class="text-center">{{vi.FechaDeCobro}}</td>
       <td scope="row" class="text-center">{{vi.PeriodoDePago}}</td>
       <td scope="row" class="text-center" style="color:#13CC29;">{{vi.Status}}</td>
       <td scope="row" class="text-center">{{vi.Monto}}</td>
       <center>
      <td scope="row" class="text-center"><button id="PagarVen" v-on:click='PagoVen()' type="button" class="btn btn-primary"><i class="fas fa-credit-card mr-1"></i>Pagar</button></td>
      </center>
    </tr>
  </tbody>
         </table>
        </div>
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <hr class="mb-4" style="background-color:#FDFEFE;">
          </div>
        </div>
      </div>
                </ul>
            </div>
            </div>
    </div><!-- Main Col END -->
</div><!-- body-row END -->
</section>
</template>
<script>
import Vue from 'vue'
import * as api from '../router/api.js'

export default{
  name: 'Invitadoview',
  data: function () {
    return {
      bandera: false,
      Vigen: [],
      Venci: []
    }
  },
  methods: {
   Limpiar: function() {
     document.getElementById("Servicio").value = "";
     document.getElementById("Descripcion").value = "";
     document.getElementById("precio").value = "";
     document.getElementById("calle").value = "";
     document.getElementById("numext").value = "";
     document.getElementById("numint").value = "";
     document.getElementById("colonia").value = "";
     document.getElementById("ciudad").value = "";
     document.getElementById("Municipio").value = "";
     document.getElementById("codpos").value = "";
     document.getElementById("Nombre").value = "";
     document.getElementById("ApellidoPaterno").value = "";
     document.getElementById("ApellidoMaterno").value = "";
     document.getElementById("telefono").value = "";
     document.getElementById("rfc").value = "";
     document.getElementById("razonsocial").value = "";
   },
    PagoVen () {
      this.$swal('Esta en desarrollo!', '', 'error')
      //myRouter.push({name: 'PagosInvitadoview', params: {numContrato: a, Monto: o}})
    },
    PagoVig () {
      this.$swal('Esta en desarrollo!', '', 'error')
      //myRouter.push({name: 'PagosInvitadoview', params: {numContrato: a, Monto: o}})
    },
    funcionPrincipal (thiis, callback) {
      var NumContrato = $('#numContrato').val()
      api.infoContrato(NumContrato).then(
        response => {
          $('#Servicio').val(JSON.parse(response[0].DATA).Contribuyente[0].Nombre)
          $('#Descripcion').val(JSON.parse(response[0].DATA).Contribuyente[0].Descripcion)
          $('#precio').val(JSON.parse(response[0].DATA).Contribuyente[0].precio)
          $('#calle').val(JSON.parse(response[0].DATA).Contribuyente[0].Calle)
          $('#numext').val(JSON.parse(response[0].DATA).Contribuyente[0].Numero)
          $('#numint').val(JSON.parse(response[0].DATA).Contribuyente[0].Interior)
          $('#colonia').val(JSON.parse(response[0].DATA).Contribuyente[0].Colonia)
          $('#ciudad').val(JSON.parse(response[0].DATA).Contribuyente[0].Ciudad)
          $('#Municipio').val(JSON.parse(response[0].DATA).Contribuyente[0].Municipio)
          $('#codpos').val(JSON.parse(response[0].DATA).Contribuyente[0].CodigoPostal)
          $('#Nombre').val(JSON.parse(response[0].DATA).Contribuyente[0].NombreContribuyente)
          $('#ApellidoPaterno').val(JSON.parse(response[0].DATA).Contribuyente[0].ApellidoPaterno)
          $('#ApellidoMaterno').val(JSON.parse(response[0].DATA).Contribuyente[0].ApellidoMaterno)
          $('#telefono').val(JSON.parse(response[0].DATA).Contribuyente[0].Telefono)
          $('#rfc').val(JSON.parse(response[0].DATA).Contribuyente[0].rfc)
          $('#razonsocial').val(JSON.parse(response[0].DATA).Contribuyente[0].razonsocial)

          var Vi, Ve

          if (response[1].DATA == null) {
            Vi = null
          } else {
            Vi = JSON.parse(response[1].DATA).Vigente
          }
          if (response[2].DATA == null) {
            Ve = null
          } else {
            Ve = JSON.parse(response[2].DATA).Vencido
          }
          thiis.Busca(Vi, Ve)

          // thiis.=true
          callback(thiis)
        })
        .catch(error => {
          //console.log(error)
          this.$swal('No existe el Contrato!', 'Da click en el boton!', 'error')
        })
    },
    principal () {

      var thiis = this
      this.funcionPrincipal(thiis, function (valor) {

        //console.log(valor)
      })
    },
    Busca (vigen, venci) {

      this.Vigen = vigen
      this.Venci = venci
      this.bandera = true
    }

  }
}
</script>
<style>
/*TRASICION DEL TITULO*/
.p {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%
  }

  to {

  }
}
@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.1); }
    to { transform: none; }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
	display: inline-block;
	animation: latidos 3s infinite;
}
</style>
