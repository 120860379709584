<!--Creacion del menu-->
<template>
  <section>
    <Menu>
      <div class="flex flex-col sm:flex-row items-center">
        <label class="w-full sm:w-20 sm:text-right sm:mr-5">Contrato</label>
        <input
          type="text"
          class="input w-full border mt-2 flex-1"
          placeholder="Número de contrato"
        />
      </div>
      <div class="flex flex-col sm:flex-row items-center">
        <label class="w-full sm:w-20 sm:text-right sm:mr-5"
          >Iniciales Nombre</label
        >
        <input
          type="text"
          class="input w-full border mt-2 flex-1"
          placeholder="Primeras iniciales del nombre"
        />
      </div>

      <br />
      <hr size="2px" color="white" />

      <br/>
      <div class="col-md-10 order-md-1">
        <h4 class="mb-3" style="color: #fdfefe">
          Ingresa el número de tu contrato
        </h4>
        <div id="formularioUser">
          <div class="row">
            <div class="col-md-4 mb-3">
              <label for="firstName" style="color: #fdfefe"
                >Número de contrato:
              </label>
              <input
                type="text"
                class="form-control"
                id="numContrato"
                name="nombre"
                placeholder=""
                value=""
                required
              />
              <div class="invalid-feedback">El Nombre es Requerido.</div>
            </div>
            <div class="col-md-3 mb-1 d-sm-block">
              <label for="lastName" style="color: #fdfefe">Busca:</label>
              <br />
              <button
                v-on:click="BuscaContrato()"
                type="button"
                class="form-control btn-primary"
              >
                <i class="fas fa-search mr-1"></i>Buscar
              </button>
            </div>
            <div class="col-md-3 mb-1 d-sm-block">
              <label for="lastName" style="color: #fdfefe">Limpiar:</label>
              <br />
              <button
                v-on:click="Limpiar()"
                type="button"
                class="form-control btn-primary"
              >
                <i class="fas fa-paint-brush mr-1"></i>Limpiar
              </button>
            </div>
          </div>

          <div class="intro-y col-span-12 lg:col-span-6">
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color: #fdfefe">Servicio: </label>
              <input
                type="text"
                style="background-color: white"
                class="form-control"
                id="Servicio"
                name="rfc"
                disabled="disabled"
                placeholder="Servicio"
                required
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color: #fdfefe">Descripcion: </label>
              <input
                type="text"
                style="background-color: white"
                class="form-control"
                id="Descripcion"
                disabled="disabled"
                name="telefono"
                placeholder="Descripcion"
                required
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color: #fdfefe">Precio: </label>
              <input
                type="text"
                style="background-color: white"
                class="form-control"
                id="precio"
                disabled="disabled"
                name="rasocial"
                placeholder="Precio"
                required
              />
              <div class="invalid-feedback"></div>
            </div>
          </div>



          <hr class="mb-4" style="background-color: #fdfefe" />



          <h4 class="mb-3" style="color: #fdfefe">Domicilio</h4>
          <div class="intro-y col-span-12 lg:col-span-6">
            <div class="col-md-5 mb-3">
              <label for="calle" style="color: #fdfefe">Calle</label>
              <input
                type="text"
                style="background-color: white"
                disabled="disabled"
                class="form-control"
                id="calle"
                name="calle"
                placeholder=""
                required
              />
              <div class="invalid-feedback">La Calle es Requerida.</div>
            </div>
            <div class="col-md-2 mb-3">
              <label for="numint" style="color: #fdfefe">Núm. Exterior</label>
              <input
                type="text"
                style="background-color: white"
                disabled="disabled"
                class="form-control"
                id="numext"
                name="numext"
                placeholder=""
                required
              />
              <div class="invalid-feedback">
                El Número Exterior es Requerida.
              </div>
            </div>
            <div class="col-md-2 mb-3">
              <label for="numint" style="color: #fdfefe">Núm. Interior</label>
              <input
                type="text"
                style="background-color: white"
                disabled="disabled"
                class="form-control"
                id="numint"
                name="numint"
                placeholder=""
                required
              />
              <div class="invalid-feedback">
                El Número Exterior es Requerida.
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <label for="colonia" style="color: #fdfefe">Colonia</label>
              <input
                type="text"
                style="background-color: white"
                disabled="disabled"
                class="form-control"
                id="colonia"
                name="colonia"
                placeholder=""
                required
              />
              <div class="invalid-feedback">La Colonia es Requerida.</div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="colonia" style="color: #fdfefe">Ciudad</label>
              <input
                type="text"
                style="background-color: white"
                disabled="disabled"
                class="form-control"
                id="ciudad"
                name="ciudad"
                placeholder=""
                required
              />
              <div class="invalid-feedback">La Colonia es Requerida.</div>
            </div>
            <div class="col-md-5 mb-3">
              <label for="colonia" style="color: #fdfefe">Municipio</label>
              <input
                type="text"
                style="background-color: white"
                disabled="disabled"
                class="form-control"
                id="Municipio"
                name="codpostal"
                placeholder=""
                required
              />
              <div class="invalid-feedback">El Municipio es Requerido.</div>
            </div>
            <div class="col-md-3 mb-3">
              <label for="colonia" style="color: #fdfefe">Código Postal</label>
              <input
                type="text"
                style="background-color: white"
                disabled="disabled"
                class="form-control"
                id="codpos"
                name="codpostal"
                placeholder=""
                required
              />
              <div class="invalid-feedback">El Código Postal es Requerida.</div>
            </div>
          </div>

          <hr class="mb-4" style="background-color: #fdfefe" />
          <button
            class="btn btn-primary btn-lg btn-block"
            id="Activar"
            v-on:click="AltaContrato()">
            <i class="fas fa-save mr-1"></i>Almacenar
          </button>
        </div>
      </div>
    </Menu>
  </section>
</template>
<script>
import Menu from "../components/Menu.vue";
import myRouter from "../router/index.js";
import axios from "axios";
import * as api from "../router/api.js";
export default {
  name: "Contratosview",
  components: {
    Menu,
  },
  props: {
    title: String,
  },
  data() {
    return {
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,
    };
  },
  mounted() {
    this.init();
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "Contratosview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  methods: {
    Limpiar: function () {
      document.getElementById("Servicio").value = "";
      document.getElementById("Descripcion").value = "";
      document.getElementById("precio").value = "";
      document.getElementById("calle").value = "";
      document.getElementById("numext").value = "";
      document.getElementById("numint").value = "";
      document.getElementById("colonia").value = "";
      document.getElementById("ciudad").value = "";
      document.getElementById("Municipio").value = "";
      document.getElementById("codpos").value = "";
    },

    /*Salir: function () {
      this.$session.destroy()
      myRouter.push({name: 'LoginView'})
    },*/
    BuscaContrato() {
      var NumContrato = $("#numContrato").val();

      api
        .infoContrato(NumContrato)
        .then((response) => {
          $("#Servicio").val(
            JSON.parse(response[0].DATA).Contribuyente[0].Nombre
          );
          $("#Descripcion").val(
            JSON.parse(response[0].DATA).Contribuyente[0].Descripcion
          );
          $("#precio").val(
            JSON.parse(response[0].DATA).Contribuyente[0].precio
          );
          $("#calle").val(JSON.parse(response[0].DATA).Contribuyente[0].Calle);
          $("#numext").val(
            JSON.parse(response[0].DATA).Contribuyente[0].Numero
          );
          $("#numint").val(
            JSON.parse(response[0].DATA).Contribuyente[0].Interior
          );
          $("#colonia").val(
            JSON.parse(response[0].DATA).Contribuyente[0].Colonia
          );
          $("#ciudad").val(
            JSON.parse(response[0].DATA).Contribuyente[0].Ciudad
          );
          $("#Municipio").val(
            JSON.parse(response[0].DATA).Contribuyente[0].Municipio
          );
          $("#codpos").val(
            JSON.parse(response[0].DATA).Contribuyente[0].CodigoPostal
          );
          $("#Activar").prop("disabled", false);
        })
        .catch((error) => {
          //console.log(error);
          this.$swal(
            "No existe el Contrato!",
            "Da click en el boton!",
            "error"
          );
        });
    },
    AltaContrato() {
      api
        .altaContrato($("#numContrato").val())
        .then((response) => {
          if (response[0].DATA) {
            api
              .updateStorage()
              .then((response) => {
                if (response) {
                  this.$swal("Ok!", "El servicio fue agregado.", "success");
                }
              })
              .catch((error) => {
                //console.log("Error: " + error + " **UpdateStorage Servicio**");
              });
          } else {
            this.$swal(
              "Error!",
              "El contrato esta agregado o no existe!",
              "error"
            );
          }
        })
        .catch((error) => {
          //console.log(error);
          this.$swal(
            "Ingesa el numero del Contrato!",
            "Da click en el boton!",
            "error"
          );
        });
    },
    init() {
      $(function () {
        // Siempre que salgamos de un campo de texto, se chequeará esta función

        $("#Activar").prop("disabled", true);

        function // Función para comprobar los campos de texto
        checkCampos(obj) {
          var camposRellenados = true;
          obj.find("input").each(function () {
            var $this = $(this);
            if ($this.val().length <= 0) {
              camposRellenados = false;
              return false;
            }
          });
          if (camposRellenados) {
            return true;
          } else {
            return false;
          }
        }
      });
    },
  },
};
</script>
<style>
</style>
