<template>
<div class="container">
  <div class="card-body" style="background-color:rgb(0 0 0 / 57%)">
    <div class="py-4 text-center">
      <div class="corazon">
      <img class="d-block mx-auto" src="../assets/logoP.svg" alt="" width="300" style="max-width: 95%;min-width: 90%;">
      </div>
      <h2>Registro</h2>
      <!-- <h4 class="lead">Deberás llenar el formulario de forma específica</h4> -->
    </div>
    <div class="row justify-content-center">
      <div class="col-md-10 order-md-1">
        <h4 class="mb-3">Datos Personales: </h4>
        <div  id="formularioUser" method="post" class="needs-validation" novalidate>
          <div class="row">
            <div class="col-md-4 mb-3">
              <label for="nombre">Nombre(s): </label>
              <input type="text" class="form-control" id="nombre" name="nombre" placeholder="" value="" required>
              <div class="invalid-feedback">
                El Nombre es Requerido.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="apaterno">Apellido Paterno: </label>
              <input type="text" class="form-control" id="apaterno" name="Apaterno" placeholder="" value="" required>
              <div class="invalid-feedback">
                El Apellido Paterno es Requerido.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="amaterno">Apellido Materno: </label>
              <input type="text" class="form-control" id="amaterno" name="Amaterno" placeholder="" value="" required>
              <div class="invalid-feedback">
                El Apellido Materno es Requerido.
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-md-4 mb-3">
              <label for="rfc">RFC: </label>
              <input type="text" class="form-control" id="rfc" name="Rfc" placeholder="RFC" required>
              <div class="invalid-feedback">
                Ppr favor ingresa tu RFC, es indispensable.
              </div>
            </div> -->
            <div class="col-md-4 mb-3">
              <label for="telefono">Telefono: </label>
              <input type="number" maxlength = "10" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" class="form-control" id="telefono" name="Telefono" placeholder="Telefono" required>
              <div class="invalid-feedback">
                Ppr favor ingresa tu Telefono, es indispensable.
              </div>
            </div>
            <!-- <div class="col-md-4 mb-3">
              <label for="razonsocial">Razón Social: </label>
              <input type="text" class="form-control" id="razonsocial" name="RazonSocial" placeholder="Razon Social"
                required>
              <div class="invalid-feedback">
                Ppr favor ingresa la Razon Social, es indispensable.
              </div>
            </div> -->
          </div>
          <hr class="mb-4" style="background-color:#FDFEFE;">
          <h4 class="mb-3">Cuenta: </h4>
          <div class="mb-3">
            <label for="email">Correo Electronico: </label>
            <input type="email" class="form-control" id="email" autocomplete="off" name="correo" placeholder="tucorreo@ejemplo.com">
            <div class="invalid-feedback">
              Por favor ingresa un correo electronico valido.
            </div>
          </div>
          <div class="mb-3">
                  <label for="nombre">Username: </label>
                  <input type="text" class="form-control" id="userName" autocomplete="off" name="userName" placeholder="Username" value="" required>
                  <div class="invalid-feedback">
                    El userName es Requerido.
                  </div>
              </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="pass">Contraseña: </label>
              <input type="password" class="form-control" id="password" autocomplete="off" name="contraseña" placeholder="Contraseña">
              <div class="invalid-feedback">
                Por favor ingresa una Contraseña.
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="repassword">Repite Contraseña: </label>
              <input type="password" class="form-control" id="repassword" autocomplete="off" name="repassword" placeholder="Repite Contraseña">
              <div class="invalid-feedback">
                Por favor repite la Contraseña.
              </div>
            </div>
          </div>
          <br>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="same-address">
            <label class="custom-control-label" for="same-address"> <router-link to="#">Mis datos son correctos</router-link></label>
          </div>
          <hr class="mb-4" style="background-color:#FDFEFE;">
          <button ion-button class="btn btn-primary btn-lg btn-block" id="registrar" v-on:click="registrar()">REGISTRAR</button>
          <br>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import myRouter from '../router/index.js'
import * as api from '../router/api.js'
export default{
  name: 'RegitroView',
  mounted () {
    this.init()
  },
  methods: {
    loading(mensaje){
      this.$swal({
        title: mensaje,
        imageUrl: "https://i.pinimg.com/originals/b3/30/0a/b3300a501c0897d36683d6f6d0b000a5.gif",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    },
    alertDisplay (mensaje) {
      this.$swal({
    		  title: '¡Sólo falta un paso!',
        // add a custom html tags by defining a html method.
        html: mensaje,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false
      }).then((result) => {
        if (result.value) {
          myRouter.push({name: 'LoginView'}).catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        } else {
          //this.$swal('Cancelar', 'Your file is still intact', 'info')
        }
      })
    },
    registrar () {
      if ($('#password').val() === $('#repassword').val()) {
        if ($('#same-address').is(':checked')) {
          this.loading("Registrando")
          var parametros = {
            'Name': 'RegistrarUsuario',
            'userName': $('#userName').val(),
            'nombre': $('#nombre').val(),
            'Apaterno': $('#apaterno').val(),
            'Amaterno': $('#amaterno').val(),
            'correo': $('#email').val(),
            'contraseña': $('#password').val(),
            'Rfc': 'NULL',
            'RazonSocial': 'NULL',
            'Telefono': $('#telefono').val()
          }
          api.registrarUsuario(parametros).then(
            response => {
              if (response[0].DATA) {
                api.sendEmailRegister(response[0].DATA, $('#email').val(), $('#userName').val()).then(
                  response => {
                    this.alertDisplay('¡Activa tu cuenta! Se envío un correo de verificación a (' + $('#email').val() + ')')
                    myRouter.push({name: 'LoginView'}).catch(error => {
                      if (error.name != "NavigationDuplicated") {
                        throw error;
                      }
                    });
                  }).catch(error => {
                  this.$swal('¡Error al enviar código de verificación.', '', 'error')
                  //console.log('Error al mandar email de confirmación')
                })
              } else {
                if (response.length == 2) {
                  this.$swal(response[1].MENSAJE, '', 'error')
                } else {
                  this.$swal('¡Error al registrar usuario.', '', 'error')
                  //console.log('Error al registrar usuario')
                }
              }
            }).catch(error => {
            //console.log(error)
          })
        } else {
          this.$swal('¡Aceptar terminos y condiciones!', '', 'info')
          //console.log('Aqui error xD')
        }
      } else {
        //console.log('Aqui error 2 xD')
      }
    },

    init () {
      $(function () {
      // Siempre que salgamos de un campo de texto, se chequeará esta función
        $('#registrar').prop('disabled', true)
        $('#formularioUser input').keyup(function () {
          var form = $(this).parents('#formularioUser')
          var check = checkCampos(form)
          if (check) {
            $('#registrar').prop('disabled', false)
          } else {
            $('#registrar').prop('disabled', true)
          }
        })

        function // Función para comprobar los campos de texto
        checkCampos (obj) {
          var camposRellenados = true
          obj.find('input').each(function () {
            var $this = $(this)
            if ($this.val().length <= 0) {
              camposRellenados = false
              return false
            }
          })
          if (camposRellenados) {
            return true
          } else {
            return false
          }
        }
      })
    }
  }
}
</script>
<style>

label{
  color: #ffffffbd;
}

h4{
  color: #ffffff;
}
h2{
  color: #ffffffbd;
}
p{
  color: #ffffffbd;
}
.cuadro{
      position: absolute;
    /* top: 100%; */
    /* left: 50%; */
    /* -webkit-transform: translate(-50%,-50%); */
    /* transform: translate(-50%,-50%); */
    width: 85%;
    /* height: 100%; */
    border-bottom-width: thick;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: rgba(0,0,0,.5);
}

@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.1); }
    to { transform: none; }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
	display: inline-block;
	animation: latidos 4s infinite;
}
</style>
