import { render, staticRenderFns } from "./PagosAgua.vue?vue&type=template&id=4f96becc"
import script from "./PagosAgua.vue?vue&type=script&lang=js"
export * from "./PagosAgua.vue?vue&type=script&lang=js"
import style0 from "./PagosAgua.vue?vue&type=style&index=0&id=4f96becc&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports