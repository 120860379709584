<template>
  <div id="app">
    <router-view>
    </router-view>
  </div>
</template>

<script>
  export default {
    name: 'App'
  }

  document.getElementsByTagName("body")[0].style = "background-color: transparent;"

  document.getElementsByTagName("html")[0].className = 'dark';

      var scripts = [
        "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js",
        "https://use.fontawesome.com/releases/v5.3.1/js/all.js",
        "https://cdn.conekta.io/js/latest/conekta.js",
        "https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.8/js/select2.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.1/Chart.min.js",
        "https://unpkg.com/vue-chartjs/dist/vue-chartjs.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/jsbarcode/3.11.3/JsBarcode.all.min.js"
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
</script>

<style>


/* @import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css'; */
  /* @import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'; */
  /* @import 'https://cdnjs.cloudflare.com/ajax/libs/selectize.js/0.12.6/css/selectize.bootstrap3.min.css'; */
  @import "dist/css/app.css";
/*body{
 background-image: url("./assets/fondo4.png");
 min-width: 100%;
 min-height: 100%;
  background-position: center left;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: 0px;
}
 
.fondo{
background: url("./assets/naranja.jpg");
background-position: center center;
background-repeat: no-repeat;
background-attachment: fixed;
background-size: cover;
min-width: 100%;
min-height: 100%;
} */
</style>