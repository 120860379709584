<!--Creacion del menu-->
<template>
  <Menu>
    <!-- menu responsive-->
    <h1 class="p" style="color: #fff">
      <FONT FACE="arial" SIZE="6">
        <i class="fas fa-address-card"></i> Perfil</FONT>
      <small class="text-muted"></small>
    </h1>
    <hr size="2px" color="white" />
    <div class="card-">
      <div class="text-dark" style="background-color: #ffad00">
        <h5 class="card-header navbar-warning text-dark">Información</h5>
      </div>

      <ul class="list-group list-group-flush">
        <br />
        <div
          class="alert alert alert-dismissible fade show"
          role="alert"
          style="
            background-color: rgba(179, 179, 179, 0.57);
            color: #fdfefe;
            border-color: white;
          ">
          <i class="fas fa-user mr-1"></i>
          <strong>Nombre:</strong> {{ Nombre }}
        </div>
        <div
          class="alert alert alert-dismissible fade show"
          role="alert"
          style="
            background-color: rgba(179, 179, 179, 0.57);
            color: #fdfefe;
            border-color: white;
          ">
          <i class="fas fa-pen mr-1"></i>
          <strong>Apellidos:</strong> {{ Apellidos }}
        </div>
        <div
          class="alert alert alert-dismissible fade show"
          role="alert"
          style="
            background-color: rgba(179, 179, 179, 0.57);
            color: #fdfefe;
            border-color: white;
          ">
          <i class="fas fa-envelope mr-1"></i>
          <strong>Email:</strong> {{ Correo }}
        </div>
        <div
          class="alert alert alert-dismissible fade show"
          role="alert"
          style="
            background-color: rgba(179, 179, 179, 0.57);
            color: #fdfefe;
            border-color: white;
          ">
          <i class="fas fa-check-circle mr-1"></i>
          <strong>Rfc:</strong> {{ Rfc }}
        </div>
      </ul>
    </div>

    <!-- <hr size="2px" color="white" /> -->

  </Menu>
</template>
<script>
import Menu from "../components/Menu.vue";
import myRouter from "../router/index.js";
import axios from "axios";

export default {
  name: "perfilview",
  components: {
    Menu,
  },
  methods: {
    PagoVig(a, o, u) {
      if (o != null) {
        myRouter
          .push({
            name: "Pagosview",
            params: { OidV: u, numContrato: a, Monto: o },
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      } else {
        myRouter.push({ name: "perfilview" }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },
  },
  data() {
    var data = [];
    var data1 = [];
    for (
      var i = 0;
      i < JSON.parse(localStorage.getItem("PagoVigente")).length;
      i++
    ) {
      data
        .push(JSON.parse(localStorage.getItem("PagoVigente"))[i].Fecha)
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    }

    for (
      var i = 0;
      i < JSON.parse(localStorage.getItem("PagoVigente")).length;
      i++
    ) {
      var sorted = data.slice().sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      var objet = JSON.parse(localStorage.getItem("PagoVigente"))[i].Fecha;
      var shi = sorted.shift();
      if (objet == shi) {
        data1 = JSON.parse(localStorage.getItem("PagoVigente"))[i];
      }
    }
    var Fecha = data1;
    return {
      fecha: Fecha.FechaDeCobro,
      cantidad: Fecha.Monto,
      oid: Fecha.oidVigente,
      NumContrato: Fecha.NumeroDeContrato,
      Nombre: localStorage.Nombre,
      Apellidos: localStorage.Apellidos,
      Correo: localStorage.Correo,
      Rfc: localStorage.Rfc,
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      PagoVencido: JSON.parse(localStorage.getItem("PagoVencido")).length,
    };
  },
  mounted() {
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "perfilview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  created() {
    ///iteracion del json de pagos vencidos
    var ven = JSON.parse(localStorage.getItem("PagoVencido"));
    var total = 0;
    var sum = 0;
    for (var i in ven) {
      total = sum += ven[i].Monto;
      ////console.log(total)
    }
    ///////////////////////////////////////////////////////////////////////////
    var vig = JSON.parse(localStorage.getItem("PagoVigente"));
    var total1 = 0;
    var suma1 = 0;
    for (var i in vig) {
      total1 = suma1 += vig[i].Monto;
      // //console.log(total1)
    }
    //////////////////////////////////////////////////////////////////////////////
    var his = JSON.parse(localStorage.getItem("Historial"));
    var total2 = 0;
    var suma2 = 0;
    for (var i in his) {
      total2 = suma2 += his[i].Monto;
      ////console.log(total2)
    }
    ////////////////////////////////////////////////////////////////////////////////
    var Vencido = JSON.parse(localStorage.getItem("PagoVencido")).length;
    var Saldado = JSON.parse(localStorage.getItem("Historial")).length;
    var Vigente = JSON.parse(localStorage.getItem("PagoVigente")).length;
    $(function () {
      var ctx = document.getElementById("myChart").getContext("2d");
      Chart.defaults.global.defaultFontFamily = "Arial";
      Chart.defaults.global.defaultFontSize = 10;
      Chart.defaults.global.defaultFontColor = "white";
      var myChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: [
            "Vigentes total: $ " + total1,
            "Saldados: $ " + total2,
            "Vencidos total: $ " + total,
          ],
          datasets: [
            {
              backgroundColor: ["#E0FFFF", "#87CEEB", "#FF7F50"],
              data: [Vigente, Saldado, Vencido],
            },
          ],
        },
      });
    });
    ////////////////////////////////////////////////////////////////////////////////////////////////
    $(function () {
      var prueba = JSON.parse(localStorage.getItem("PagoVencido"));
      var resultado;
      for (var i in prueba) {
        resultado = prueba[i].FechaDeCobro + " " + prueba[i].Monto;
        ////console.log(resultado)
      }

      new Chart(document.getElementById("mixed-chart"), {
        type: "bar",
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          datasets: [
            {
              label: "Vencidos",
              type: "line",
              borderColor: "#8e5ea2",
              data: [total1, 547, 675, 734],
              fill: false,
            },
            {
              label: "Saldados",
              type: "line",
              borderColor: "#3e95cd",
              data: [133, 221, 783, 2478],
              fill: false,
            },
            {
              label: "Vencidos",
              type: "bar",
              backgroundColor: "#FF7F50",
              data: [total1, 547, 675, 734],
            },
            {
              label: "Saldados",
              type: "bar",
              backgroundColor: "#87CEEB",
              backgroundColorHover: "#3e95cd",
              data: [133, 221, 783, 2478],
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: "Pagos por mes: Vencidos & Saldados",
          },
          legend: { display: false },
        },
      });
    });
    ////////////////////////////////////////////////////////////////////////
    $(function () {
      new Chart(document.getElementById("bar-chart-horizontal"), {
        type: "horizontalBar",
        data: {
          labels: ["2014", "2015", "2016", "2017", "2018"],
          datasets: [
            {
              label: "Population (millions)",
              backgroundColor: [
                "#3e95cd",
                "#8e5ea2",
                "#3cba9f",
                "#e8c3b9",
                "#c45850",
              ],
              data: [2478, 5267, 734, 784, 433],
            },
          ],
        },
        options: {
          legend: { display: false },
          title: {
            display: true,
            text: "Pagos por año",
          },
        },
      });
    });
  },
};
</script>
<style>
.bg-white {
  background-color: #2e2d2dad !important;
}
</style>
