var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"card-body",staticStyle:{"background-color":"rgb(0 0 0 / 57%)"}},[_vm._m(0),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10 order-md-1"},[_c('h4',{staticClass:"mb-3"},[_vm._v("Datos Personales: ")]),_c('div',{staticClass:"needs-validation",attrs:{"id":"formularioUser","method":"post","novalidate":""}},[_vm._m(1),_vm._m(2),_c('hr',{staticClass:"mb-4",staticStyle:{"background-color":"#FDFEFE"}}),_c('h4',{staticClass:"mb-3"},[_vm._v("Cuenta: ")]),_vm._m(3),_vm._m(4),_vm._m(5),_c('br'),_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"same-address"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"same-address"}},[_c('router-link',{attrs:{"to":"#"}},[_vm._v("Mis datos son correctos")])],1)]),_c('hr',{staticClass:"mb-4",staticStyle:{"background-color":"#FDFEFE"}}),_c('button',{staticClass:"btn btn-primary btn-lg btn-block",attrs:{"ion-button":"","id":"registrar"},on:{"click":function($event){return _vm.registrar()}}},[_vm._v("REGISTRAR")]),_c('br')])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-4 text-center"},[_c('div',{staticClass:"corazon"},[_c('img',{staticClass:"d-block mx-auto",staticStyle:{"max-width":"95%","min-width":"90%"},attrs:{"src":require("../assets/logoP.svg"),"alt":"","width":"300"}})]),_c('h2',[_vm._v("Registro")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre(s): ")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"nombre","name":"nombre","placeholder":"","value":"","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El Nombre es Requerido. ")])]),_c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"apaterno"}},[_vm._v("Apellido Paterno: ")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"apaterno","name":"Apaterno","placeholder":"","value":"","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El Apellido Paterno es Requerido. ")])]),_c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"amaterno"}},[_vm._v("Apellido Materno: ")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"amaterno","name":"Amaterno","placeholder":"","value":"","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El Apellido Materno es Requerido. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_c('label',{attrs:{"for":"telefono"}},[_vm._v("Telefono: ")]),_c('input',{staticClass:"form-control",attrs:{"type":"number","maxlength":"10","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","id":"telefono","name":"Telefono","placeholder":"Telefono","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Ppr favor ingresa tu Telefono, es indispensable. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Correo Electronico: ")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","id":"email","autocomplete":"off","name":"correo","placeholder":"tucorreo@ejemplo.com"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Por favor ingresa un correo electronico valido. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Username: ")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"userName","autocomplete":"off","name":"userName","placeholder":"Username","value":"","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El userName es Requerido. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"pass"}},[_vm._v("Contraseña: ")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","id":"password","autocomplete":"off","name":"contraseña","placeholder":"Contraseña"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Por favor ingresa una Contraseña. ")])]),_c('div',{staticClass:"col-md-6 mb-3"},[_c('label',{attrs:{"for":"repassword"}},[_vm._v("Repite Contraseña: ")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","id":"repassword","autocomplete":"off","name":"repassword","placeholder":"Repite Contraseña"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Por favor repite la Contraseña. ")])])])
}]

export { render, staticRenderFns }