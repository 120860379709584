<template>
  <Menu>
    <div class="intro-y flex flex-col-reverse items-center sm:flex-row">
      <h2 class="p-2 intro-y text-lg font-medium mr-auto mt-2"></h2>
      <div class="w-full flex sm:w-auto">
      </div>
      <div class="relative" style="width: 25%;">
        <button id="btnReport" type="button" class="form-control btn-success btn-lg" v-on:click="makeReport()" style="width:100%"><i class="fas fa-file-alt mr-1"></i> Generar Reporte</button>
      </div>
    </div>
    <center>
      <div class="grid grid-cols-12 gap-6 mt-3">
        <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
          <div class="box" style="color: white; border-color: white">
            <div class="card-body">
              <center>
                <h5 style="float: right;" class="card-title"><i class="fab fa-cc-visa"></i></h5>
                <h6 class="-intro-x mr-auto hidden sm:flex">Proximo pago</h6>
                <p class="card-text">{{ fecha }}</p>
                <p class="card-text" style="font-size: 51px">
                  $ {{ cantidad == undefined ? 0 : cantidad }}
                </p>
                <router-link to="/" class="card-link" style="color: #ffc107" v-on:click="PagoVig(NumContrato, cantidad, oid)">Pagar</router-link>
              </center>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
          <div class="box" style="color: white; border-color: white">
            <div class="card-body">
              <center>
                <h5 style="float: right;" class="card-title"><i class="fas fa-list-alt"></i></h5>
                <h6 class="-intro-x mr-auto hidden sm:flex">Servicios</h6>
                <p class="card-text"></p>
                <p class="card-text" style="font-size: 51px">
                  {{ Services }}
                </p>
                <router-link to="/Contratos" class="card-link" style="color: #ffc107">Agregar</router-link>
              </center>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
          <div class="box" style="color: white; border-color: white">
            <div class="card-body">
              <center>
                <h5 style="float: right;" class="card-title"><i class="fas fa-frown"></i></h5>
                <h6 class="-intro-x mr-auto hidden sm:flex">Pagos vencidos</h6>
                <p class="card-text"></p>
                <p class="card-text" style="font-size: 51px">
                  {{ PagoVencido }}
                </p>
                <router-link to="/ProximoPago" class="card-link" style="color: #ffc107">Pagos</router-link>
              </center>
            </div>
          </div>
        </div>
      </div>
    </center>

    <div class="col-span-12 md:col-span-6 xl:col-span-12 xxl:col-span-12 xl:col-start-1 xl:row-start-1 xxl:col-start-auto xxl:row-start-auto mt-3">
    <div class="intro-x flex items-center h-10">
        <h2 class="text-lg font-medium truncate mr-auto">
            Pagos
        </h2>
    </div>
    <div class="mt-5 intro-x">
        <canvas id="bar-chart-horizontal" height="80px"></canvas>
    </div>


      <div class="xxl:pl-6 grid grid-cols-12 gap-6">
        <div
          class="col-span-12 md:col-span-6 xl:col-span-8 xxl:col-span-12 mt-3 xxl:mt-8">
          <div class="intro-x flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Transacciones</h2>
          </div>
          <div class="mt-5">
            <canvas id="mixed-chart"></canvas>
          </div>
        </div>
        <div
          class="col-span-12 md:col-span-6 xl:col-span-4 xxl:col-span-12 mt-3 xxl:mt-8">
          <div class="intro-x flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Estado de Servicios</h2>
          </div>
          <div class="mt-5">
            <canvas id="myChart" width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
  </Menu>
</template>
<script>
import Menu from "../components/Menu.vue";
import myRouter from "../router/index.js";
import axios from "axios";
import * as api from "../router/api.js";

export default {
  name: "Adminview",
  components: {
    Menu,
  },
methods: {
    makeReport: function () {
    this.$swal({
      title: 'Generer Reporte',
      showCloseButton: true,
      html:
      `<div>
        <label style="width: 20%">Servicio: </label>
        <select id="servRep" class="swal2-input" style="width: 70%">
          <option value='PREDIAL'>PREDIAL</option>
          <option value='AGUA POTABLE'>AGUA POTABLE</option>
        </select>
      </div>`,
      preConfirm: function() {
        return new Promise(function(resolve) {
        if (true) {
          resolve([
            document.getElementById('servRep').value
          ]);
        }
        });
      }
    }).then(function(result) {
      var usuario = JSON.parse(localStorage.Usuario)[0].proyectoID
      var resultado=result.value[0];
      var filename = "REP_"+resultado+"_"+usuario;
      var pageSize = "letter";
      var orientation = "landscape";
      api
        .createReportePDF(resultado,filename, pageSize, orientation)
        .then((response) => {

          if (response = 'Acepted') {
            //console.log(response)
            window.open("https://pagoob.azurewebsites.net/print/" + filename + ".pdf", '_blank');
          } else {
            //console.log(response)
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    })

    },
    PagoVig(a, o, u) {
      if (o != null) {
        myRouter
          .push({
            name: "Pagosview",
            params: { OidV: u, numContrato: a, Monto: o },
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      } else {
        myRouter.push({ name: "Adminview" }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },
  },
  data() {
    var data = [];
    var data1 = [];
    for (
      var i = 0;
      i < JSON.parse(localStorage.getItem("PagoVigente")).length;
      i++
    ) {
      data
        .push(JSON.parse(localStorage.getItem("PagoVigente"))[i].Fecha)
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    }

    for (
      var i = 0;
      i < JSON.parse(localStorage.getItem("PagoVigente")).length;
      i++
    ) {
      var sorted = data.slice().sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      var objet = JSON.parse(localStorage.getItem("PagoVigente"))[i].Fecha;
      var shi = sorted.shift();
      if (objet == shi) {
        data1 = JSON.parse(localStorage.getItem("PagoVigente"))[i];
      }
    }
    var Fecha = data1;
    return {
      fecha: Fecha.FechaDeCobro,
      cantidad: Fecha.Monto,
      oid: Fecha.oidVigente,
      NumContrato: Fecha.NumeroDeContrato,
      Nombre: localStorage.Nombre,
      Apellidos: localStorage.Apellidos,
      Correo: localStorage.Correo,
      Rfc: localStorage.Rfc,
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      PagoVencido: JSON.parse(localStorage.getItem("PagoVencido")).length,
    };
  },
  mounted() {
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "Adminview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  created() {
    ///iteracion del json de pagos vencidos
    var ven = JSON.parse(localStorage.getItem("PagoVencido"));
    var total = 0;
    var sum = 0;
    for (var i in ven) {
      total = sum += ven[i].Monto;
      ////console.log(total)
    }
    ///////////////////////////////////////////////////////////////////////////
    var vig = JSON.parse(localStorage.getItem("PagoVigente"));
    var total1 = 0;
    var suma1 = 0;
    for (var i in vig) {
      total1 = suma1 += vig[i].Monto;
      // //console.log(total1)
    }
    //////////////////////////////////////////////////////////////////////////////
    var his = JSON.parse(localStorage.getItem("Historial"));
    var total2 = 0;
    var suma2 = 0;
    for (var i in his) {
      total2 = suma2 += his[i].Monto;
      ////console.log(total2)
    }
    ////////////////////////////////////////////////////////////////////////////////
    var Vencido = JSON.parse(localStorage.getItem("PagoVencido")).length;
    var Saldado = JSON.parse(localStorage.getItem("Historial")).length;
    var Vigente = JSON.parse(localStorage.getItem("PagoVigente")).length;
    $(function () {
      var ctx = document.getElementById("myChart").getContext("2d");
      Chart.defaults.global.defaultFontFamily = "Arial";
      Chart.defaults.global.defaultFontSize = 10;
      Chart.defaults.global.defaultFontColor = "white";
      var myChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: [
            "Vigentes total: $ " + total1,
            "Saldados: $ " + total2,
            "Vencidos total: $ " + total,
          ],
          datasets: [
            {
              backgroundColor: ["#E0FFFF", "#87CEEB", "#FF7F50"],
              data: [Vigente, Saldado, Vencido],
            },
          ],
        },
      });
    });
    ////////////////////////////////////////////////////////////////////////////////////////////////
    $(function () {
      var prueba = JSON.parse(localStorage.getItem("PagoVencido"));
      var resultado;
      for (var i in prueba) {
        resultado = prueba[i].FechaDeCobro + " " + prueba[i].Monto;
        ////console.log(resultado)
      }

      new Chart(document.getElementById("mixed-chart"), {
        type: "bar",
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          datasets: [
            {
              label: "Vencidos",
              type: "line",
              borderColor: "#8e5ea2",
              data: [total1, 547, 675, 734],
              fill: false,
            },
            {
              label: "Saldados",
              type: "line",
              borderColor: "#3e95cd",
              data: [133, 221, 783, 2478],
              fill: false,
            },
            {
              label: "Vencidos",
              type: "bar",
              backgroundColor: "#FF7F50",
              data: [total1, 547, 675, 734],
            },
            {
              label: "Saldados",
              type: "bar",
              backgroundColor: "#87CEEB",
              backgroundColorHover: "#3e95cd",
              data: [133, 221, 783, 2478],
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: "Pagos por mes: Vencidos & Saldados",
          },
          legend: { display: false },
        },
      });
    });
    ////////////////////////////////////////////////////////////////////////
    $(function () {
      new Chart(document.getElementById("bar-chart-horizontal"), {
        type: "horizontalBar",
        data: {
          labels: ["2014", "2015", "2016", "2017", "2018"],
          datasets: [
            {
              label: "Population (millions)",
              backgroundColor: [
                "#3e95cd",
                "#8e5ea2",
                "#3cba9f",
                "#e8c3b9",
                "#c45850",
              ],
              data: [2478, 5267, 734, 784, 433],
            },
          ],
        },
        options: {
          legend: { display: false },
          title: {
            display: true,
            text: "Pagos por año",
          },
        },
      });
    });
  },
};
</script>
