<!--Creacion del menu-->
<template>
  <section>
    <Menu>
      <div class="col-md-10 order-md-1">
        <h4 class="mb-3" style="color: #fdfefe">
          Ingresa el número de tu contrato
        </h4>
        <div id="formularioUser" class="fadeInUp">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="firstName" style="color: #fdfefe"
                >Buscar por:
              </label>
              <select style="height: 20px;" class="js-data-example-ajax" id="mySelect2">
        <option>Ingresa Nombre/Apellido/Clave Catastral</option>
      </select>
              <!-- <input
                type="text"
                class="input w-full border"
                id="numContrato"
                name="nombre"
                placeholder="Clave Catastral"
                value=""
                required
              /> -->
              <div class="invalid-feedback">El Nombre es Requerido.</div>
            </div>
            <div class="col-md-3 mb-3 d-sm-block">
              <br />
              <button
                v-on:click="BuscaContrato()"
                type="button"
                class="form-control btn-primary btn-lg"
              >
                <i class="fas fa-search mr-1"></i> Buscar
              </button>
            </div>
            <div class="col-md-3 mb-3 d-sm-block">
              <br />
              <button
                v-on:click="Limpiar()"
                type="button"
                class="form-control btn-primary btn-lg"
              >
                <i class="fas fa-paint-brush mr-1"></i> Limpiar
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="contribuyente" style="color: #fdfefe">Nombre del Contribuyente: </label>
              <input
                type="text"
                style=""
                class="input w-full border"
                id="contribuyente"
                name="contribuyente"
                disabled="disabled"
                placeholder="Contribuyente"
                required
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="col-md-3 mb-3">
              <label for="servicio" style="color: #fdfefe">Servicio: </label>
              <input
                type="text"
                style=""
                class="input w-full border"
                id="Servicio"
                name="servicio"
                disabled="disabled"
                placeholder="Servicio"
                required
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="col-md-3 mb-3">
              <label for="Descripcion" style="color: #fdfefe"
                >Descripción:
              </label>
              <input
                type="text"
                style=""
                class="input w-full border"
                id="Descripcion"
                disabled="disabled"
                name="telefono"
                placeholder="Descripción"
                required
              />
              <div class="invalid-feedback"></div>
            </div>
          </div>

          <hr class="mb-4" style="background-color: #fdfefe" />
          <h4 class="mb-3" style="color: #fdfefe">Domicilio</h4>
          <div class="row">
            <div class="col-md-4 mb-3">
              <label for="calle" style="color: #fdfefe">Calle</label>
              <input
                type="text"
                style=""
                disabled="disabled"
                class="input w-full border"
                id="calle"
                name="calle"
                placeholder="Calle"
                required
              />
              <div class="invalid-feedback">La Calle es Requerida.</div>
            </div>
            <div class="col-md-2 mb-3">
              <label for="numint" style="color: #fdfefe">Núm. Exterior</label>
              <input
                type="text"
                style=""
                disabled="disabled"
                class="input w-full border"
                id="numext"
                name="numext"
                placeholder="No. Ext."
                required
              />
              <div class="invalid-feedback">
                El Número Exterior es Requerida.
              </div>
            </div>
            <div class="col-md-2 mb-3">
              <label for="numint" style="color: #fdfefe">Núm. Interior</label>
              <input
                type="text"
                style=""
                disabled="disabled"
                class="input w-full border"
                id="numint"
                name="numint"
                placeholder="No. Int."
                required
              />
              <div class="invalid-feedback">
                El Número Exterior es Requerida.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="colonia" style="color: #fdfefe">Colonia</label>
              <input
                type="text"
                style=""
                disabled="disabled"
                class="input w-full border"
                id="colonia"
                name="colonia"
                placeholder="Colonia"
                required
              />
              <div class="invalid-feedback">La Colonia es Requerida.</div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="colonia" style="color: #fdfefe">Ciudad</label>
              <input
                type="text"
                style=""
                disabled="disabled"
                class="input w-full border"
                id="ciudad"
                name="ciudad"
                placeholder="Ciudad"
                required
              />
              <div class="invalid-feedback">La Colonia es Requerida.</div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="colonia" style="color: #fdfefe">Municipio</label>
              <input
                type="text"
                style=""
                disabled="disabled"
                class="input w-full border"
                id="Municipio"
                name="codpostal"
                placeholder="Municipio"
                required
              />
              <div class="invalid-feedback">El Municipio es Requerido.</div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="colonia" style="color: #fdfefe">Código Postal</label>
              <input
                type="text"
                style=""
                disabled="disabled"
                class="input w-full border"
                id="codpos"
                name="codpostal"
                placeholder="Código Postal"
                required
              />
              <div class="invalid-feedback">El Código Postal es Requerida.</div>
            </div>
          </div>
          <div id="activaPagar">
            <div class="row">
              <!-- <div v-if="servicio_cont == 'PREDIAL'" class="col-md-6 mb-3 predial">
                <label for="bimestres">Pagos Pendientes:</label>
                <select v-on:change="changePay($event)" class="input w-full border" id="bimestres" v-model="key">
                  <option value="">Selecciona...</option>
                </select>
              </div>
              <div v-if="servicio_cont == 'AGUA POTABLE'" class="col-md-6 mb-3 predial">
                <input v-on:click="toCheck($event)" type="checkbox" id="PagoOtraCantidad"/>
                <label for="PagoOtraCantidad">Pagar otra cantidad</label>
                <input disabled="disabled" class="input w-full border" style="border-color: red;" id="otroMonto" type="number" name="monto" placeholder="Cantidad" />
              </div> -->
              <!-- <div class="col-md-6 mb-3 predial">
                <label for="precio" style="color: #fdfefe">Precio: </label>
                <br />
                <p
                  for="precio"
                  style="color: #ffc107; text-align: center; font-size: 1.8em"
                >
                  $ <u><span id="precio" style="color: #fdfefe">0.00</span></u>
                </p>
                <div class="invalid-feedback"></div>
              </div> -->
              <div class="col-md-12 mb-3">
                <br />
                <button
                  id="btnPay"
                  v-on:click="findContrato()"
                  type="button"
                  class="form-control btn-success btn-lg"
                >
                  <i class="fas fa-search mr-1"></i> Consultar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Menu>
  </section>
</template>
<script>
import Menu from "../components/Menu.vue";
import myRouter from "../router/index.js";
import axios from "axios";
import * as api from "../router/api.js";
import select2 from "vue-select2";

var OidPagos = '';
var montoAgua = '';
export default {
  name: "Consultasview",
  components: {
    Menu,
  },
  props: {
    title: String,
  },
  data() {
    key: ""
    return {
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,
      servicio_cont : 'PREDIAL'

    };
  },
  mounted() {
    this.init();
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "Consultasview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  methods: {
    round: function (num, decimales = 2) {
      var signo = num >= 0 ? 1 : -1;
      num = num * signo;
      if (decimales === 0)
        //con 0 decimales
        return signo * Math.round(num);
      // round(x * 10 ^ decimales)
      num = num.toString().split("e");
      num = Math.round(
        +(num[0] + "e" + (num[1] ? +num[1] + decimales : decimales))
      );
      // x * 10 ^ (-decimales)
      num = num.toString().split("e");
      return (
        signo * (num[0] + "e" + (num[1] ? +num[1] - decimales : -decimales))
      );
    },
    calcularMontos() {
      var total = 0;
      var checks = Array.from(
        document.querySelectorAll('input.pagos[type="checkbox"]:checked')
      );
      checks.forEach((element) => {
        total = total + parseFloat($(element).attr("data"));
      });
      const aMoneda = (numero) => {
        const opciones = {
          numeroDeDecimales: 2,
          separadorDecimal: ".",
          separadorMiles: ",",
          simbolo: "$ ", // Con un espacio, ya que la función no agrega espacios
          posicionSimbolo: "i", // i = izquierda, d = derecha
        };
        // Valores por defecto
        opciones.simbolo = opciones.simbolo || "$";
        opciones.separadorDecimal = opciones.separadorDecimal || ".";
        opciones.separadorMiles = opciones.separadorMiles || ",";
        opciones.numeroDeDecimales =
          opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
        opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
        const CIFRAS_MILES = 3;

        // Redondear y convertir a cadena
        let numeroComoCadena = numero.toFixed(opciones.numeroDeDecimales);

        // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
        let posicionDelSeparador = numeroComoCadena.indexOf(
          opciones.separadorDecimal
        );
        if (posicionDelSeparador === -1)
          posicionDelSeparador = numeroComoCadena.length;
        let formateadoSinDecimales = "",
          indice = posicionDelSeparador;
        // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
        while (indice >= 0) {
          let limiteInferior = indice - CIFRAS_MILES;
          // Agregar separador si cortamos más de 3
          formateadoSinDecimales =
            (limiteInferior > 0 ? opciones.separadorMiles : "") +
            numeroComoCadena.substring(limiteInferior, indice) +
            formateadoSinDecimales;
          indice -= CIFRAS_MILES;
        }
        let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(
          posicionDelSeparador,
          opciones.numeroDeDecimales + 1
        )}`;
        return opciones.posicionSimbolo === "i"
          ? opciones.simbolo + formateadoSinSimbolo
          : formateadoSinSimbolo + opciones.simbolo;
      };
      $(".montoTotal").html(aMoneda(total));
    },
    async findContrato() {
      var servicio = $("#Servicio").val();
      var NumContrato = $("#mySelect2").val();
      var contribuyente = $("#contribuyente").val();
      var municipio = $("#Municipio").val();
      const consultaIniciales = await api.getTablaPeriodo('u', NumContrato, 'o','');
      if(consultaIniciales == ""){
          const mensaje = await this.$swal(
            "¡Error!",
            "Los datos ingresados son incorrectos.",
            "error"
          );
          return mensaje;
      } else {
          this.$swal({
            title: "",
            showCloseButton: true,
            width: "850px",
            html: consultaIniciales,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Pagar',
          }).then((result) => {
            if (result.isConfirmed) {
              var arr = [];
              var checks = Array.from(
                document.querySelectorAll(
                  'input.pagos[type="checkbox"]:checked'
                )
              );
              if (checks.length > 0) {
                checks.forEach((element) => {
                  arr.push({periodo:$(element).attr("periodo"),bimestre:$(element).attr("bimestre")});
                });
                this.newPagarContrato(servicio, NumContrato, arr, $('.montoTotal').html(),contribuyente,municipio)
              } else {
                //console.log("Some checkboxes are not checked");
              }
            }
          });
          this.initial();
      }
    },
    Limpiar: function () {
      $("#mySelect2").val("")
      document.getElementById("Servicio").value = "";
      document.getElementById("contribuyente").value = "";
      document.getElementById("Descripcion").value = "";
      // document.getElementById("precio").innerHTML = "0.00";
      // document.getElementById("precio").value = "";
      document.getElementById("calle").value = "";
      document.getElementById("numext").value = "";
      document.getElementById("numint").value = "";
      document.getElementById("colonia").value = "";
      document.getElementById("ciudad").value = "";
      document.getElementById("Municipio").value = "";
      document.getElementById("codpos").value = "";
      $("#bimestres").empty();
      $("#activaPagar").css("display", "none");
    },
    toCheck: function (event) {
      //console.log($(event).is(":checked"))
        if ($(event.target).is(":checked")) {
          $("#otroMonto").removeAttr("disabled");
          $("#otroMonto").focus();
        } else {
          $("#otroMonto").val("");
          $("#otroMonto").attr("disabled", "disabled");
        }
    },
    BuscaContratoNombre: async function () {

      var NumContrato = $("#mySelect2").val()
      const infoConsultas = await api.infoConsultasNombresApellidos(NumContrato);

      //console.log(infoConsultas);

      if (infoConsultas.length > 0) {
        for (var i = 0; i < infoConsultas.length; i++) {
          var montoR = parseFloat(infoConsultas[i].monto).toFixed(2);
          if (montoR < 0) {
            montoR = 0;
          }
          OidPagos = infoConsultas[i].Oid;
          this.servicio_cont = infoConsultas[i].nombre
          $("#Servicio").val(infoConsultas[i].nombre);
          $("#Descripcion").val(infoConsultas[i].descripcion);
          $("#contribuyente").val(infoConsultas[i].contribuyente);
          $("#calle").val(infoConsultas[i].calle);
          $("#numext").val(infoConsultas[i].numero);
          $("#numint").val(infoConsultas[i].interior);
          $("#colonia").val(infoConsultas[i].colonia);
          $("#ciudad").val(infoConsultas[i].ciudad);
          $("#Municipio").val(infoConsultas[i].municipio);
          $("#codpos").val(infoConsultas[i].CodigoPostal);
          if (infoConsultas[i].nombre == 'PREDIAL') {

              if (infoConsultas[i].bimestre1 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre1)+`'>Bimestre `+infoConsultas[i].periodoString1+` → $`+this.round(infoConsultas[i].bimestre1)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString1+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre2 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre2)+`'>Bimestre `+infoConsultas[i].periodoString2+` → $`+this.round(infoConsultas[i].bimestre2)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString2+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre3 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre3)+`'>Bimestre `+infoConsultas[i].periodoString3+` → $`+this.round(infoConsultas[i].bimestre3)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString3+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre4 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre4)+`'>Bimestre `+infoConsultas[i].periodoString4+` → $`+this.round(infoConsultas[i].bimestre4)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString4+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre5 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre5)+`'>Bimestre `+infoConsultas[i].periodoString5+` → $`+this.round(infoConsultas[i].bimestre5)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString5+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre6 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre6)+`'>Bimestre `+infoConsultas[i].periodoString6+` → $`+this.round(infoConsultas[i].bimestre6)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString6+` → Pagado ✔</option>`);
              }
              $("#bimestres option:last").prop("selected", true);

            $("#precio").val(montoR);
            $("#precio").html(montoR);
            this.key = montoR
            this.changePay(montoR)
          } else {
            $("#precio").val(montoR);
            $("#precio").html(montoR);
            montoAgua = montoR;
          }
          $("#activaPagar").css("display", "block");
          $("#btnPay").prop("disabled", false);
        }
      } else {
        this.$swal("No existe el Contrato!", "", "error");
      }
    },
    BuscaContrato: async function () {
      $("#bimestres").empty();
      $("#bimestres").append(`<option value = ''>Selecciona...</option>`);
      $("#otroMonto").empty();
      $("#otroMonto").val('');
      $("#precio").empty();
      $("#precio").html('0.00');

      var NumContrato = $("#mySelect2").val()
      const infoConsultas = await api.infoConsultas(NumContrato);
      if (infoConsultas.length > 0) {
        for (var i = 0; i < infoConsultas.length; i++) {
          var montoR = parseFloat(infoConsultas[i].monto).toFixed(2);
          if (montoR < 0) {
            montoR = 0;
          }
          OidPagos = infoConsultas[i].Oid;
          this.servicio_cont = infoConsultas[i].nombre
          $("#Servicio").val(infoConsultas[i].nombre);
          $("#Descripcion").val(infoConsultas[i].descripcion);
          $("#contribuyente").val(infoConsultas[i].contribuyente);
          $("#calle").val(infoConsultas[i].calle);
          $("#numext").val(infoConsultas[i].numero);
          $("#numint").val(infoConsultas[i].interior);
          $("#colonia").val(infoConsultas[i].colonia);
          $("#ciudad").val(infoConsultas[i].ciudad);
          $("#Municipio").val(infoConsultas[i].municipio);
          $("#codpos").val(infoConsultas[i].CodigoPostal);
          if (infoConsultas[i].nombre == 'PREDIAL') {

              if (infoConsultas[i].bimestre1 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre1)+`'>Bimestre `+infoConsultas[i].periodoString1+` → $`+this.round(infoConsultas[i].bimestre1)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString1+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre2 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre2)+`'>Bimestre `+infoConsultas[i].periodoString2+` → $`+this.round(infoConsultas[i].bimestre2)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString2+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre3 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre3)+`'>Bimestre `+infoConsultas[i].periodoString3+` → $`+this.round(infoConsultas[i].bimestre3)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString3+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre4 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre4)+`'>Bimestre `+infoConsultas[i].periodoString4+` → $`+this.round(infoConsultas[i].bimestre4)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString4+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre5 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre5)+`'>Bimestre `+infoConsultas[i].periodoString5+` → $`+this.round(infoConsultas[i].bimestre5)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString5+` → Pagado ✔</option>`);
              }
              if (infoConsultas[i].bimestre6 != "0") {
                $("#bimestres").append(`<option value = '`+this.round(infoConsultas[i].bimestre6)+`'>Bimestre `+infoConsultas[i].periodoString6+` → $`+this.round(infoConsultas[i].bimestre6)+`</option>`);
              } else {
                $("#bimestres").append(`<option value = '0' disabled>Bimestre `+infoConsultas[i].periodoString6+` → Pagado ✔</option>`);
              }
              $("#bimestres option:last").prop("selected", true);

            $("#precio").val(montoR);
            $("#precio").html(montoR);
            this.key = montoR
            this.changePay(montoR)
          } else {
            $("#precio").val(montoR);
            $("#precio").html(montoR);
            montoAgua = montoR;
          }
          $("#activaPagar").css("display", "block");
          $("#btnPay").prop("disabled", false);
        }
      } else {
        this.$swal("No existe el Contrato!", "", "error");
      }
    },
    PagarContrato() {
    //   api
    //     .pagarContrato($("#mySelect2").val())
    //     .then((response) => {
    //       if (response[0].DATA) {
    //         api
    //           .payStorage()
    //           .then((response) => {
    //             if (response) {
    //               this.$swal("Ok!", "El servicio fue agregado.", "success");
    //             }
    //           })
    //           .catch((error) => {
    //             //console.log("Error: " + error + " **payStorage Servicio**");
    //           });
    //       } else {
    //         this.$swal(
    //           "Error!",
    //           "El contrato esta agregado o no existe!",
    //           "error"
    //         );
    //       }
    //     })
    //     .catch((error) => {
    //       //console.log(error);
    //       this.$swal(
    //         "Ingresa el numero del Contrato!",
    //         "Da click en el boton!",
    //         "error"
    //       );
    //     });
    // 
    },
    newPagarContrato(servicio,contrato,arr,monto,contribuyente,municipio) {
//       if (servicio == 'PREDIAL') {
//         bimestre = $("#bimestres").children('option:selected').index()
//         monto = $("#bimestres").val();
//       } else if (servicio == 'AGUA POTABLE') {
//         if ($("#PagoOtraCantidad").is(":checked")) {
//           monto = $("#otroMonto").val();
//         } else {
//           monto = montoAgua;
//         }
//       }
      // if (monto == "0" || monto == "") {
      //   this.$swal("Revisa tu cantidad a pagar", "", "warning");
      //   $("#otroMonto").focus();
      // } else {
        // if (parseFloat(monto) <= parseFloat($("#precio").val())) {
          // var restante = (parseFloat($("#precio").val()) - parseFloat(monto)).toFixed(2)
          this.$swal({
            icon: 'warning',
            title: 'Datos del pago',
            html: 'Servicio: <b>'+ servicio +'</b>.<br> Precio Total: <b>'+ monto +'</b>.<br> Municipio: <b>'+ municipio +'</b>.<br> Contribuyente: <b>'+ contribuyente +'</b>.',
            showCancelButton: true,
            confirmButtonText: `Continuar`,
            cancelButtonText: `Cancelar`,
            showCloseButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
          }).then((result) => {
            if (result.isConfirmed) {
              api
                .recibirOrden(servicio, contrato, arr, monto.replace('$ ', ''),contribuyente,municipio)
                .then((response) => {
                  if (response) {
                      this.$swal({
                        icon: 'success',
                        title: 'Pago Realizado ¿Generar Reporte?',
                        text: '¿Deseas enviar el recibo de pago a un correo electrónico?',
                        showCancelButton: true,
                        confirmButtonText: `SI`,
                        cancelButtonText: `NO`,
                        showCloseButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.$swal({
                            icon: 'question',
                            title: 'Ingresa el Correo Electrónico',
                            input: "email",
                            showCancelButton: true,
                            confirmButtonText: `Enviar`,
                            cancelButtonText: `Cancelar`,
                            showCloseButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            inputValidator: (value) => {
                              if (!value) {
                                return 'Necesitas escribir un correo electrónico'
                              }
                            }
                          }).then((result) => {
                            if (result.isConfirmed) {
                              if (result.value) {
                                api.sendEmailRecibo(result.value,response)
                                .then((response) => {
                                  if (response) {
                                    if (response.message == 'Exito') {
                                      this.$swal("Recibo Enviado", "Revisa tu Correo Electrónico para visualizar el recibo", "success");
                                    } else {
                                      this.$swal("Error", "Error al enviar recibo", "error");
                                    }
                                  } else {
                                    this.$swal("Hubo un error", "Error de servidor", "error");
                                  }
                                })
                                .catch((error) => {
                                  //console.log(error);
                                  this.$swal("Hubo un error", "Error de servidor", "error");
                                });
                              } else {

                              }
                            }
                          })
                        }
                      })
                      this.Limpiar();

                  } else {
                      this.$swal("¡Hubo un error!", 'Error al obter datos', "error");
                  }
                })
                .catch((error) => {
                  //console.log(error);
                  this.$swal("Hubo un error", "Error de servidor", "error");
                });
            }
          })
        // } else {
        //   this.$swal("La cantidad a pagar es mayor al monto total", "", "error");
        // }
//      }
    },
    changePay(bimestre) {
      if (this.key != undefined) {
        bimestre = this.key
      }
      if (bimestre != "") {
        $("#precio").html(parseFloat(bimestre).toFixed(2));
      } else {
        $("#precio").html("0.00");
      }
    },
    initial() {
      $(
        function () {
          $("#allchecked").change(
            function (e) {
              $("input:checkbox").prop("checked", $(e.target).prop("checked"));
              $('input.pagos[type="checkbox"]').prop("disabled", $(e.target).prop("checked"));
              this.calcularMontos();
            }.bind(this)
          );
          $("#allchecked").change(
            function (e) {
              $("input:checkbox").prop("checked", $(e.target).prop("checked"));
              $('input.pagos[type="checkbox"]').prop("disabled", $(e.target).prop("checked"));
              this.calcularMontos();
            }.bind(this)
          );
          $('input.pagos[type="checkbox"]').on(
            "click",
            function (e) {
              var checks = Array.from(
                document.querySelectorAll('input.pagos[type="checkbox"]')
              );
              var valid = true;
              var cont = 0;
              checks.forEach((element) => {
                if (valid != false) {
                  if (element.id != e.target.id) {
                    if (element.checked == true) {
                      valid = true;
                    } else {
                      alert("Tu selección debe ser el primer pago pendinte");
                      valid = false;
                      e.target.checked = false;
                    }
                  } else {
                    valid = false;
                    $(e.target).prop("disabled", true)
                  }
                }
              });

              this.calcularMontos();
            }.bind(this)
          );
        }.bind(this)
      );
    },
    init() {
      $(function () {
        // Siempre que salgamos de un campo de texto, se chequeará esta función
        $("#activaPagar").css("display", "none");
        $("#btnPay").prop("disabled", true);
        function // Función para comprobar los campos de texto
        checkCampos(obj) {
          var camposRellenados = true;
          obj.find("input").each(function () {
            var $this = $(this);
            if ($this.val().length <= 0) {
              camposRellenados = false;
              return false;
            }
          });
          if (camposRellenados) {
            return true;
          } else {
            return false;
          }
        }


        $('#mySelect2').select2({
          theme: "classic",
          width: "100%",
          language: "es",
            ajax: {
              url: 'https://pagoob.azurewebsites.net/getnombreSearch',
              method: 'POST',
              dataType: 'json',
              delay: 250,
              data: function (params) {

                var query = {
                  search: params.term,
                  type: 'POST'
                }
                return query;
              },
              processResults: function (data) {
                // Transforms the top-level key of the response object from 'items' to 'results'
                  //console.log(data)
                return {
                  results: data
                };
              }
            }
          });
      });
    },
  },
};
</script>
<style>
.select2-results__option{
  color: black;
}
.select2-search__field{
  color: #de6718;
  font-size: 18px;
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .select2-container .select2-selection--single .select2-selection__clear {
      background-color: transparent;
      border: none;
      font-size: 1em; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px; }
  .select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline;
      list-style: none;
      padding: 0; }
    .select2-container .select2-selection--multiple .select2-selection__clear {
      background-color: transparent;
      border: none;
      font-size: 1em; }
  .select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    margin-left: 5px;
    padding: 0; }
    .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid rgb(192, 16, 16);
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option--selectable {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid rgb(0, 0, 0);;
  border-radius: 4px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    height: 26px;
    margin-right: 20px;
    padding-right: 0px; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: rgb(0, 0, 0); }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 1px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    padding: 0; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    cursor: default;
    padding-left: 2px;
    padding-right: 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-right: 1px solid rgb(0, 0, 0);;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #999;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    padding: 0 4px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
      background-color: #f1f1f1;
      color: #333;
      outline: none; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid rgb(0, 0, 0);;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid rgb(0, 0, 0);; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default .select2-results__option--group {
  padding: 0; }

.select2-container--default .select2-results__option--disabled {
  color: rgb(0, 0, 0); }

.select2-container--default .select2-results__option--selected {
  background-color: #ddd; }

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid rgb(0, 0, 0);;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
  .select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    height: 26px;
    margin-right: 20px; }
  .select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: rgb(0, 0, 0); }
  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid rgb(0, 0, 0);;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }
    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid rgb(0, 0, 0);;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none; }
    .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid rgb(0, 0, 0);;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px; }
  .select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid rgb(0, 0, 0);;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    padding: 0; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
    cursor: default;
    padding-left: 2px;
    padding-right: 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #888;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    padding: 0 4px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #555;
      outline: none; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid rgb(0, 0, 0);;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option--group {
  padding: 0; }

.select2-container--classic .select2-results__option--disabled {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }
</style>
