<!--Creacion del menu-->
<template>
  <Menu>
    <div class="row">
      <div class="col-sm-3" style="margin-top: 10px">
        <div class="input-group mb-2">
          <input
            type="text"
            id="myInput"
            v-on:keyup="busqueda()"
            class="form-control"
            placeholder="Buscar"
            aria-label="Buscar"
            aria-controls="table"
            aria-describedby="basic-addon2"
          />
        </div>
      </div>
    </div>
    <hr size="2px" color="white" style="margin-top: 0rem" />

    <div class="table-responsive" style="max-width: 450px; min-width: 100%">
      <table class="table table-hover fadeInUp" id="table">
        <thead class="thead" style="background-color: rgb(229 134 80)">
          <tr>
            <th scope="col" class="text-center">#</th>
            <th scope="col" class="text-center">
              <i class="fas fa-list-ol mr-1"></i>Clave Catastral
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-cog mr-1"></i>Servicio
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-calendar mr-1"></i>Nombre
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-calendar-check mr-1"></i>Municipio
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-check-circle mr-1"></i>Status
            </th>
            <!-- <th scope="col" class="text-center">
                  <i class="fas fa-coins mr-1"></i>Monto
                </th> -->
            <th scope="col" class="text-center">
              <i class="fas fa-hand-holding-usd mr-1"></i>
            </th>
          </tr>
        </thead>
        <tbody style="background-color: rgba(179, 179, 179, 0.57)">
          <tr v-for="(Ven, key) in Vencido" v-bind:key="key">
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ key + 1 }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Ven.NumeroDeContrato }}
            </td>
            <td
              v-if="Ven.Servicio == 'PREDIAL'"
              scope="row"
              class="text-center"
              style="color: #fdfefe"
            >
              <i class="fas fa-home mr-2"></i>{{ Ven.Servicio }}
            </td>
            <td
              v-if="Ven.Servicio == 'AGUA POTABLE'"
              scope="row"
              class="text-center"
              style="color: #fdfefe"
            >
              <i class="fas fa-tint mr-2"></i>{{ Ven.Servicio }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Ven.NombreContribuyente }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              <div style="text-align: -webkit-center">
                <img
                  style="border-radius: 20px"
                  width="50px"
                  v-bind:src="Ven.logoImgUrl"
                  alt=""
                />
              </div>
              {{ Ven.Municipio }}
            </td>
            <td
              scope="row"
              class="text-center font-weight-bold"
              style="color: rgb(255, 110, 110)"
            >
              {{ Ven.Status }}
            </td>
            <!-- <td scope="row" class="text-center" style="color: #fdfefe">
                  $ {{ Ven.Monto }}
                </td> -->
            <center>
              <td scope="row" class="text-center">
                <button
                  v-if="Ven.Servicio == 'PREDIAL'"
                  id="PagarVen"
                  v-on:click="
                    PagoPredialVen(
                      Ven.NumeroDeContrato,
                      Ven.Monto,
                      Ven.oidVencido,
                      Ven
                    )
                  "
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fas fa-search mr-1"></i>Consultar
                </button>
                <button
                  v-if="Ven.Servicio == 'AGUA POTABLE'"
                  id="PagarVen"
                  v-on:click="
                    PagoAguaVen(Ven.NumeroDeContrato, Ven.Monto, Ven.oidVencido)
                  "
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fas fa-search mr-1"></i>Consultar
                </button>
              </td>
            </center>
          </tr>
          <tr v-for="(Vig, key) in Vigente" v-bind:key="key">
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Vencido.length + 1 + key }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Vig.NumeroDeContrato }}
            </td>
            <td
              v-if="Vig.Servicio == 'PREDIAL'"
              scope="row"
              class="text-center"
              style="color: #fdfefe"
            >
              <i class="fas fa-home mr-2"></i>{{ Vig.Servicio }}
            </td>
            <td
              v-if="Vig.Servicio == 'AGUA POTABLE'"
              scope="row"
              class="text-center"
              style="color: #fdfefe"
            >
              <i class="fas fa-tint mr-2"></i>{{ Vig.Servicio }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Vig.FechaDeCobro }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Vig.PeriodoDePago }}
            </td>
            <td
              scope="row"
              class="text-center font-weight-bold"
              style="color: #13cc29"
            >
              {{ Vig.Status }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              $ {{ Vig.Monto }}
            </td>
            <center>
              <td scope="row" class="text-center">
                <button
                  v-if="Vig.Servicio == 'PREDIAL'"
                  id="PagarVig"
                  v-on:click="
                    PagoPredialVig(
                      Vig.NumeroDeContrato,
                      Vig.Monto,
                      Vig.oidVigente
                    )
                  "
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fas fa-search mr-1"></i>Consultar
                </button>
                <button
                  v-if="Vig.Servicio == 'AGUA POTABLE'"
                  id="PagarVig"
                  v-on:click="
                    PagoAGUAVig(Vig.NumeroDeContrato, Vig.Monto, Vig.oidVigente)
                  "
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fas fa-credit-card mr-1"></i>Consultar
                </button>
              </td>
            </center>
          </tr>
        </tbody>
      </table>
    </div>
  </Menu>
</template>
<script>
import myRouter from "../router/index.js";
import Menu from "../components/Menu.vue";
import * as api from "../router/api.js";
export default {
  name: "ProximoPagoView",
  components: {
    Menu,
  },
  mounted() {
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "ProximoPagoview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },

  /**
   * All of the component's data.
   */
  data() {
    //console.log("Return");
    return {
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,
      Vigente: JSON.parse(localStorage.getItem("PagoVigente")),
      Vencido: JSON.parse(localStorage.getItem("PagoVencido")),
    };
  },

  ready() {
    this.getNotifications();
  },

  methods: {
    busqueda: function () {
      // Declare variables
      var input, filter, table, tr, td, i;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      table = document.getElementById("table");
      tr = table.getElementsByTagName("tr");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[4];
        if (td) {
          if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
    Salir: function () {
      this.$session.destroy();
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    PagoPredialVen(a, o, u, ven) {
      api
        .getTablaPeriodo(u, a, o, ven)
        .then((response) => {
          this.$swal({
            title: "",
            showCloseButton: true,
            width: "850px",
            html: response,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Pagar",
            customClass: {
              // container: 'fadeInUp',
              // popup: 'popup-class',
              // header: 'header-class',
              // title: 'title-class',
              // closeButton: 'close-button-class',
              //content: 'fadeInUp',
              // input: 'input-class',
              // actions: 'actions-class',
              // footer: 'footer-class'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              var arr = [];
              var checks = Array.from(
                document.querySelectorAll(
                  'input.pagos[type="checkbox"]:checked'
                )
              );
              if (checks.length > 0) {
                checks.forEach((element) => {
                  arr.push({OidPor_Pagar: $(element).attr("Oid"), optionPay: $(element).attr("optionPay") ,periodo:$(element).attr("periodo"),bimestre:$(element).attr("bimestre")});
                });
                //console.log(arr);
                myRouter
                  .push({
                    name: "PagosPredialview",
                    params: { OidV: u, numContrato: a, Monto: arr, optionPay: $(checks[0]).attr("optionPay") ,contribuyente: {nombre: ven.NombreContribuyente, municipio: ven.Municipio} },
                  })
                  .catch((error) => {
                    if (error.name != "NavigationDuplicated") {
                      throw error;
                    }
                  });
              } else {
                //console.log("Some checkboxes are not checked");
              }
            }
          });
          this.init();
        })
        .catch((error) => {
          //console.log(error);
        });
      // myRouter.push({
      //     name: "PagosPredialview",
      //     params: { OidV: u, numContrato: a, Monto: o },
      //   })
      //   .catch((error) => {
      //     if (error.name != "NavigationDuplicated") {
      //       throw error;
      //     }
      //   });
    },
    PagoAguaVen(a, o, u) {
      myRouter
        .push({
          name: "PagosAguaview",
          params: { OidV: u, numContrato: a, Monto: o },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    PagoPredialVig(a, o, u) {
      myRouter
        .push({
          name: "PagosPredialview",
          params: { OidV: u, numContrato: a, Monto: o },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    PagoAguaVig(a, o, u) {
      myRouter
        .push({
          name: "PagosAguaview",
          params: { OidV: u, numContrato: a, Monto: o },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    calcularMontos() {
      var total = 0;
      var checks = Array.from(
        document.querySelectorAll('input.pagos[type="checkbox"]:checked')
      );
      checks.forEach((element) => {
        total = total + parseFloat($(element).attr("data"));
      });
      const aMoneda = (numero) => {
        const opciones = {
          numeroDeDecimales: 2,
          separadorDecimal: ".",
          separadorMiles: ",",
          simbolo: "$ ", // Con un espacio, ya que la función no agrega espacios
          posicionSimbolo: "i", // i = izquierda, d = derecha
        };
        // Valores por defecto
        opciones.simbolo = opciones.simbolo || "$";
        opciones.separadorDecimal = opciones.separadorDecimal || ".";
        opciones.separadorMiles = opciones.separadorMiles || ",";
        opciones.numeroDeDecimales =
          opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
        opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
        const CIFRAS_MILES = 3;

        // Redondear y convertir a cadena
        let numeroComoCadena = numero.toFixed(opciones.numeroDeDecimales);

        // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
        let posicionDelSeparador = numeroComoCadena.indexOf(
          opciones.separadorDecimal
        );
        if (posicionDelSeparador === -1)
          posicionDelSeparador = numeroComoCadena.length;
        let formateadoSinDecimales = "",
          indice = posicionDelSeparador;
        // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
        while (indice >= 0) {
          let limiteInferior = indice - CIFRAS_MILES;
          // Agregar separador si cortamos más de 3
          formateadoSinDecimales =
            (limiteInferior > 0 ? opciones.separadorMiles : "") +
            numeroComoCadena.substring(limiteInferior, indice) +
            formateadoSinDecimales;
          indice -= CIFRAS_MILES;
        }
        let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(
          posicionDelSeparador,
          opciones.numeroDeDecimales + 1
        )}`;
        return opciones.posicionSimbolo === "i"
          ? opciones.simbolo + formateadoSinSimbolo
          : formateadoSinSimbolo + opciones.simbolo;
      };
      $(".montoTotal").html(aMoneda(total));
    },
    init() {
      $(
        function () {
          $("#allchecked").change(
            function (e) {
              $("input:checkbox").prop("checked", $(e.target).prop("checked"));
              this.calcularMontos();
            }.bind(this)
          );
          $('input.pagos[type="checkbox"]').on(
            "click",
            function (e) {
              var checks = Array.from(
                document.querySelectorAll('input.pagos[type="checkbox"]')
              );
              var valid = true;
              checks.forEach((element) => {
                if (valid != false) {
                  if (element.id != e.target.id) {
                    if (element.checked == true) {
                      valid = true;
                    } else {
                      alert("Tu selección debe ser el primer pago pendinte");
                      valid = false;
                      e.target.checked = false;
                    }
                  } else {
                    valid = false;
                  }
                }
              });

              this.calcularMontos();
            }.bind(this)
          );
        }.bind(this)
      );
    },
  },
};
</script>
<style>
@media (min-width: 768px) {
  .main {
    padding-left: 243px; /* 180 + 40 */
  }
}
#body-row {
  margin-left: 0;
  margin-right: 0;
}
#sidebar-container {
  min-height: 130vh;
  background-color: #333;
  padding: 0;
}
/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
  width: 230px;
  height: 100%;
}
.sidebar-collapsed {
  width: 60px;
}
/* Menu item*/
#sidebar-container .list-group a {
  height: 50px;
  color: white;
}
/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
  height: 45px;
  padding-left: 30px;
}
.sidebar-submenu {
  font-size: 0.9rem;
}
/* Separators */
.sidebar-separator-title {
  background-color: #333;
  height: 35px;
}
.sidebar-separator {
  background-color: #333;
  height: 25px;
}
.logo-separator {
  background-color: #333;
  height: 60px;
}
/* Closed submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="false"]
  .submenu-icon::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/* Opened submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="true"]
  .submenu-icon::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}

/*TRASICION DEL TITULO*/
.p {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
  }
}

@keyframes latidos {
  from {
    transform: none;
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: none;
  }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
  display: inline-block;
  animation: latidos 4s infinite;
}
</style>
