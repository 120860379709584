<template>
    <div>
 <!-- Bootstrap NavBar -->
<!-- NavBar END -->
<!-- Bootstrap row -->
<div class="row" id="body-row">
    <!-- Sidebar -->
    <div id="sidebar-container" class="sidebar-expanded d-none d-md-block fixed-top"><!-- d-* hiddens the Sidebar in smaller devices. Its itens can be kept on the Navbar 'Menu' -->
        <!-- Bootstrap List Group -->
        <br>
        <div class="corazon">
          <center>
     <img style="width:80%" src='../assets/logoP.svg'/>
     </center>
     </div>
<br>
<li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
    <center>
     <FONT style="color:#fff;" FACE="arial" SIZE=2.5><i class="fas fa-user-circle mr-1"></i>Bani Jesua Rosas Tabarez</FONT>
    </center>
</li>
        <ul class="list-group">
            <!-- Separator with title -->
            <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
                <small style="color:#ffad00;">Menu</small>
            </li>
            <router-link to="#/Dashboard" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fa fa-circle-notch fa-fw mr-3"></span>
                    <span class="menu-collapsed">Dashboard</span>
                </div>
            </router-link>
            <router-link to="#" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fa fa-user fa-fw mr-3"></span>
                    <span class="menu-collapsed">Administrador</span>
                </div>
            </router-link>
            <router-link to="#/Servicios" class="bg-dark list-group-item list-group-item-action">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-envelope fa-fw mr-3"></span>
                    <span class="menu-collapsed">Mensajes<span class="badge badge-pill badge-primary ml-2">0</span></span>
                </div>
            </router-link>
             <router-link to="#/Contratos" class="bg-dark list-group-item list-group-item-action">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-file-alt fa-fw mr-3"></span>
                    <span class="menu-collapsed">Contratos</span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <!-- /END Separator -->
            <!-- Menu with submenu -->
            <!-- Separator with title -->
            <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
                <small style="color:#ffad00;">Opciones</small>
            </li>
            <!-- /END Separator -->
              <router-link to="#submenu1" data-toggle="collapse" aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-map-marker-alt fa-fw mr-3"></span>
                    <span class="menu-collapsed">Mapa</span>
                    <span class="submenu-icon ml-auto"></span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <div id='submenu1' class="collapse sidebar-submenu">
                <router-link to="#" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Charts</span>
                </router-link>
                <router-link to="#/Domicilios" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Direcciones</span>
                </router-link>
                <!--a to="#" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed">Tables</span>
                </a-->
            </div>
           <router-link to="#submenu2" data-toggle="collapse" aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="far fa-credit-card fa-fw mr-3"></span>
                    <span class="menu-collapsed">Pagos</span>
                    <span class="submenu-icon ml-auto"></span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <div id='submenu2' class="collapse sidebar-submenu">
                <router-link to="#/ProximoPago" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Proximo Pago</span>
                </router-link>
                <router-link to="#/HistorialPagos" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Historial De Pagos</span>
                </router-link>
            </div>
             <router-link to="#submenu3" data-toggle="collapse" aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-cog fa-fw mr-3"></span>
                    <span class="menu-collapsed">Configuracion</span>
                    <span class="submenu-icon ml-auto"></span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <div id='submenu3' class="collapse sidebar-submenu">
                <router-link to="#/ActualizaInformacion" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Actualizar Informacion</span>
                </router-link>
            </div>
            <!-- Separator without title -->
            <li class="list-group-item sidebar-separator menu-collapsed"></li>
            <!-- /END Separator -->
            <router-link to="#" data-toggle="sidebar-colapse" class="bg-dark list-group-item list-group-item-action d-flex align-items-center">
                <div  class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span id="collapse-icon" class="fas fa-sign-out-alt mr-3"></span>
                    <span id="collapse-text" class="menu-collapsed">Salir</span>
                </div>
            </router-link>
            <!-- Logo -->
</ul><!-- List Group END-->
    </div><!-- sidebar-container END -->
    <!-- MAIN -->
     <nav class="navbar d-sm-block d-md-none navbar-dark"  style="width: 100%;">

  <button class="navbar-toggler mr-8" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon mr-1"></span>
  </button>
  <img class="corazon" style="width: 10%;min-width: 60px;float: right;" src='../assets/logoP.svg'/>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto d-sm-block d-md-none">
        <center>
  <li class="nav-item active">
 <router-link class="nav-link" to="#/home" style="color:#fff;"><i class="fas fa-home mr-1"></i>Inicio</router-link>
 </li>
  <hr size="2px" color="white" />
 <li class="nav-item active">
 <router-link class="nav-link" to="#/perfil" style="color:#fff;"><i class="fas fa-user mr-1"></i>Perfil</router-link>
 </li>
  <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/Servicios" style="color:#fff;"><i class="fas fa-cog mr-1"></i>Servicios</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/Contratos" style="color:#fff;"><i class="fas fa-file-alt mr-1"></i>Contratos</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/Domicilios" style="color:#fff;"><i class="fas fa-map-marker-alt mr-1"></i>Domicilios</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/ProximoPago" style="color:#fff;"><i class="fas fa-money-check-alt mr-1"></i>Proximo Pago</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/HistorialPagos" style="color:#fff;"><i class="fas fa-list-ol mr-1"></i>Historial De Pagos</router-link>
 </li>
  <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/ActualizaInformacion" style="color:#fff;"><i class="fas fa-pen mr-1"></i>Actualizar Informacion</router-link>
 </li>
 <hr size="2px" color="white" />
<li class="nav-item">
        <router-link class="nav-link " to="#" style="color:#fff;"><i class="fas fa-sign-out-alt mr-1"></i>Salir</router-link>
      </li>
      </center>
      <!-- This menu is hidden in bigger devices with d-sm-none. The sidebar isn't proper for smaller screens imo, so this dropdown menu can keep all the useful sidebar itens exclusively for smaller screens  -->
      </ul>
  </div>
</nav>



</div>
</div>
</template>
<script>
export default {
    name: "Administradorview"
}
</script>
<style>
.sidebar {
     width: 180px;
}

@media (min-width: 768px) {
    .main {
        padding-left: 243px; /* 180 + 40 */
    }
}

.bg-white {
    background-color: #333333ad!important;
}
#body-row {
  margin-left: 0;
  margin-right: 0;
}
#sidebar-container {
  min-height: 130vh;
  background-color: #333;
  padding: 0;
}
/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
  width: 230px;
}
.sidebar-collapsed {
  width: 60px;
}
/* Menu item*/
#sidebar-container .list-group a {
  height: 50px;
  color: white;
}
/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
  height: 45px;
  padding-left: 30px;
}
.sidebar-submenu {
  font-size: 0.9rem;
}
/* Separators */
.sidebar-separator-title {
  background-color: #333;
  height: 35px;
}
.sidebar-separator {
  background-color: #333;
  height: 25px;
}
.logo-separator {
  background-color: #333;
  height: 60px;
}
/* Closed submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="false"]
  .submenu-icon::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/* Opened submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="true"]
  .submenu-icon::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/*TRASICION DEL TITULO*/
.p {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {

  }
}
@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.1); }
    to { transform: none; }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
	display: inline-block;
	animation: latidos 4s infinite;
}
</style>

