<!--Creacion del menu-->
<template>
  <Menu>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-1">
      <h2 class="text-lg font-medium mr-auto">Servicios</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-on:click="newNuevoContrato()"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          Agregar Servicio
        </button>
      </div>
    </div>
    <div class="intro-y chat grid grid-cols-12 gap-5 mt-2">
      <div class="intro-y col-span-12 lg:col-span-12 xxl:col-span-12">
        <div class="box">
          <section>
            <div
              class="table-responsive"
              style="max-width: 450px; min-width: 100%"
            >
              <table class="table table-hover">
                <thead class="thead" style="background-color: rgb(229 134 80)">
                  <tr>
                    <th scope="col" class="text-center">#</th>
                    <th scope="col" class="text-center">
                      <i class="fas fa-list-ol mr-1"></i>Clave Catastral
                    </th>
                    <th scope="col" class="text-center">
                      <i class="fas fa-cog mr-1"></i>Servicio
                    </th>
                    <th scope="col" class="text-center">
                      <i class="fas fa-user mr-1"></i>Contribuyente
                    </th>
                    <th scope="col" class="text-center">
                      <i class="fas fa-map-marker-alt mr-1"></i>Domicilio
                    </th>
                    <th scope="col" class="text-center">
                      <i class="fas fa-times-circle mr-1"></i>Opcion
                    </th>
                  </tr>
                </thead>
                <tbody
                  style="background-color: rgba(179, 179, 179, 0.57)"
                  id="Servi_Remote"
                  v-if="Ser_Remote">
                  <tr v-for="(Ser, key) in Services" v-bind:key="key">
                    <td scope="row" class="text-center" style="color: #fdfefe">
                      {{ key + 1 }}
                    </td>
                    <td scope="row" class="text-center" style="color: #fdfefe; width: 150px">
                      {{ Ser.ClaveCatastralGuion }}
                    </td>
                    <td scope="row" class="text-center" style="color: #fdfefe">
                      <i class="fas fa-home mr-2"></i>
                      PREDIAL
                    </td>
                    <td scope="row" class="text-center" style="color: #fdfefe">
                      {{ Ser.NombreContribuyente }}
                    </td>
                    <td scope="row" class="text-center" style="color: #fdfefe">
                      {{ Ser.Direccion }}
                    </td>
                    <center>
                      <th scope="row" class="text-center">
                        <button
                          v-on:click="eliminarRegistro_Remote(Ser.ClaveCatastral)"
                          class="btn btn-danger"
                        >
                          <i class="fas fa-trash-alt mr-1"></i>Eliminar
                        </button>
                      </th>
                    </center>
                  </tr>
                </tbody>
                <!-- <tbody
                  style="background-color: rgba(179, 179, 179, 0.57)"
                  id="Servi"
                  v-if="Ser">
                  <tr v-for="(Ser, key) in Services" v-bind:key="key">
                    <td scope="row" class="text-center" style="color: #fdfefe">
                      {{ key + 1 }}
                    </td>
                    <td scope="row" class="text-center" style="color: #fdfefe">
                      {{ Ser.NumeroDeContrato }}
                    </td>
                    <td scope="row" class="text-center" style="color: #fdfefe">
                      <i v-if="Ser.Servicio == 'AGUA POTABLE'" class="fas fa-tint mr-2"></i>
                      <i v-if="Ser.Servicio == 'PREDIAL'" class="fas fa-home mr-2"></i>
                      {{ Ser.Servicio }}
                    </td>
                    <td scope="row" class="text-center" style="color: #fdfefe">
                      {{ Ser.NombreContribuyente }}
                    </td>
                    <td scope="row" class="text-center" style="color: #fdfefe">
                      {{ Ser.Calle }} #{{ Ser.Numero }}, {{ Ser.Municipio }}
                    </td>
                    <center>
                      <th scope="row" class="text-center">
                        <button
                          v-on:click="showAlert(Ser.NumeroDeContrato)"
                          class="btn btn-danger"
                        >
                          <i class="fas fa-trash-alt mr-1"></i>Eliminar
                        </button>
                      </th>
                    </center>
                  </tr>
                </tbody> -->
              </table>
            </div>
            <!--Alert dialog -->
            <center>
              <div
                class="modal fade"
                id="modal1"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Eliminar Servicio</h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">Seguro Que Quieres Eliminarlo?</div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Cerrar
                      </button>
                      <button
                        type="button"
                        v-on:click="Delete()"
                        class="btn btn-primary"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </center>
          </section>
        </div>
      </div>
    </div>
  </Menu>
</template>
<script>
import Menu from "../components/Menu.vue";
import myRouter from "../router/index.js";
import * as api from "../router/api.js";
export default {
  name: "Serviciosview",
  components: {
    Menu,
  },
  async mounted() {
    try {
      this.loading("Obteniendo Registros")
      await this.getRegistros_Remote();
      this.$swal.close();
    } catch (error) {
      //console.log("Error: "+ error)
      this.$swal.close();
    }

    // api.updateStorage();
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "Serviciosview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  data() {
    return {
      Serv: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,
      Services: JSON.parse(localStorage.getItem("Servicios")),
      Ser: true,
      Ser_Remote: true,
    };
  },
  methods: {
    async getRegistros_Remote(){
      try {
        const response =  await api.getAllData_Remote()
          if (response.length > 0) {
            this.Services = response;
            this.Ser = true;
          } else {
            //console.log("Sin registros")
          }
      } catch (error) {
        //console.log(
          //   "Error: " + error + " **UpdateStorage Servicio**"
          // );
      }
    },
    async newNuevoContrato() {
      const contrato = await this.$swal.fire({
        title: "Agregar Predial",
        html:
          "<div style=''><b>Ingresa la clave catastral: </b><br><br><span></span></div>",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        showCancelButton: false,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escribir una clave catastral";
          }
        },
        preConfirm: (contrato) => {
          return contrato;
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
      if (!contrato.isConfirmed) {
        return;
      }

      this.$swal({
        title: "Validando información",
        imageUrl: "https://i.pinimg.com/originals/b3/30/0a/b3300a501c0897d36683d6f6d0b000a5.gif",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      const consultaIniciales = await api.getDataPuente((contrato.value).replace(/-/g,""));
      //console.log(consultaIniciales)

      if(Object.prototype.hasOwnProperty.call(consultaIniciales, "code")){
        if(consultaIniciales.code == "ECONNABORTED"){
          const mensaje = await this.$swal(
              "¡Servidor fuera de línea!",
              "El Servidor de Puente de Ixtla no responde, inténtelo más tarde.",
              "error"
            );
            return mensaje;
        }
      }

      var razonSocial= false;
      var titleSwal="";
      var htmlSwal="";
      if (consultaIniciales.length > 0) {
        if (consultaIniciales[0].NombreContribuyenteFiscal != null) {
          razonSocial= true;
          titleSwal="Ingresar Razón Social"
          htmlSwal= "<div style='color: #8d8888'><b>Ejemplo: </b> <b><u>P</u></b>edro <b><u>G</u></b>arcía <b><u>L</u></b>ópez = <b>PGL</b></div>";
          //htmlSwal= "<h6 style='color: #8d8888'><b>Ejemplo: </b> <b><br><u>AVANCE URBANO Y COMERCIAL AMO, S. DE R. L. DE C.V.</u></b></h6>";
        } else {
          razonSocial= false;
          titleSwal="Ingresar Iniciales"
          htmlSwal= "<div style='color: #8d8888'><b>Ejemplo: </b> <b><u>P</u></b>edro <b><u>G</u></b>arcía <b><u>L</u></b>ópez = <b>PGL</b></div>";
        }
      } else {
        const mensaje = await this.$swal(
              "¡Error!",
              "Clave Catastral Incorrecta.",
              "error"
            );
        return mensaje;
      }

      //console.log(razonSocial);

      const iniciales = await this.$swal.fire({
        title: titleSwal,
        html: htmlSwal,
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          onkeyup: "javascript:this.value=this.value.toUpperCase();",
        },
        confirmButtonText: "Consultar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        showCancelButton: false,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escribir las iniciales de tu nombre";
          }
        },
        preConfirm: (inicales) => {
          return inicales;
        },
      });

      if (!iniciales.isConfirmed) {
        return;
      }
      this.$swal({
        title: "Validando información",
        text: "Por favor espera...",
        imageUrl: "https://i.pinimg.com/originals/b3/30/0a/b3300a501c0897d36683d6f6d0b000a5.gif",
        // imageUrl: "https://www.intelaf.com/img/ajax-loader/giphy.gif",
        showConfirmButton: false,
        allowOutsideClick: false,
      });



      if (!consultaIniciales.length > 0) {
        this.$swal.close();
        if (consultaIniciales.length != 0) {
          if (consultaIniciales.response.status == 500) {
            const mensaje = await this.$swal(
              "¡Servidor fuera de línea!",
              "El Servidor de Puente de Ixtla no responde, inténtelo más tarde.",
              "error"
            );
            return mensaje;
          }
        } else {
          const mensaje = await this.$swal(
              "¡Error!",
              "Datos Incorrectos.",
              "info"
            );
            return mensaje;
        }
      }

    if(!razonSocial){
      if (consultaIniciales[0].NombreContribuyente) {
        let resultado = consultaIniciales[0].NombreContribuyente.trim()
          .split(" ")
          .map((w) => w[0].toUpperCase())
          .join("");
        //console.log(resultado)
        if (resultado != iniciales.value) {
          const mensaje = await this.$swal(
            "¡Error!",
            "Los datos ingresados son incorrectos.",
            "error"
          );
          return mensaje;
        }
      }
    } else {
      if (consultaIniciales[0].NombreContribuyenteFiscal) {
        let resultado = consultaIniciales[0].NombreContribuyenteFiscal.trim()
        .split(" ")
        .map((w) => w[0].toUpperCase())
        .join("");
        ////console.log(resultado)
        if (resultado != iniciales.value) {
          const mensaje = await this.$swal(
            "¡Error!",
            "Los datos ingresados son incorrectos.",
            "error"
          );
          return mensaje;
        }
      }
    }


    // //inserta id de predial en base de datos.
    // const res = await api.altaContrato(contrato.value);
    //       if (res.length > 0) {
    //         if (res[0].DATA) {
    //           api.updateStorage().then((response) => {
    //               if (response) {
    //                 this.$swal("Ok!", "El servicio fue agregado.", "success");
    //                 this.Serv = JSON.parse(localStorage.getItem("Servicios")).length;
    //                 this.Nombre = localStorage.Nombre + " " + localStorage.Apellidos;
    //                 this.Services = JSON.parse(localStorage.getItem("Servicios"));
    //                 this.Ser = true;
    //               }
    //             })
    //             .catch((error) => {
    //               //console.log(
    //                 "Error: " + error + " **UpdateStorage Servicio**"
    //               );
    //             });

    //         } else {
    //             this.$swal(
    //               "Error!",
    //               "El contrato esta agregado o no existe!",
    //               "error"
    //             );
    //         }
    //       } else {
    //         //console.log("Se cancelo")
    //       }


          //Insertar a la base de datos Remota
          const respuesta = await api.setContratoRemote((contrato.value).replace(/-/g,""));
          if (respuesta.length > 0) {
            if (respuesta[0].hasOwnProperty('DATA') ? respuesta[0].DATA : true) {
              try {
                await this.getRegistros_Remote();
                this.$swal("Ok!", "El servicio fue agregado.", "success");
              } catch (error) {
                //console.log(
                  //   "Error: " + error + " **UpdateStorage Servicio**"
                  // );
              }
            } else {
              if (respuesta.length == 2) {
                this.$swal(
                  "¡Error!",
                  respuesta[1].MENSAJE,
                  "error"
                );
              } else {
                this.$swal(
                  "Error!",
                  "El contrato esta agregado o no existe!",
                  "error"
                );
              }
            }



          } else {
            //console.log("Se cancelo")
          }



      // const montosPuente = await api.getDataPuenteMontos(contrato.value);
      // //console.log(montosPuente)


      // var data = Object.assign(consultaIniciales[0], montosPuente[0]);
      // const makeAlertPay = await api.makeAlertPay(data);

      // this.$swal({
      //   title: "",
      //   showCloseButton: true,
      //   width: "95%",
      //   html: makeAlertPay.tab,
      //   showCancelButton: true,
      //   cancelButtonText: "Cancelar",
      //   confirmButtonText: "Pagar",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     myRouter
      //       .push({
      //         name: "PagoPuenteIxtla",
      //         params: {
      //           OidV: "inv001",
      //           numContrato: contrato.value,
      //           Monto: makeAlertPay.monto,
      //           optionPay: "3",
      //           contribuyente: {
      //             nombre: consultaIniciales[0].NombreContribuyente,
      //             municipio: "Puente de Ixtla",
      //           },
      //         },
      //       })
      //       .catch((error) => {
      //         if (error.name != "NavigationDuplicated") {
      //           throw error;
      //         }
      //       });
      //   }
      // });

      // if(montosPuente.length == 0){ //  comprueba que ya esta al corriente el predial
      //   setTimeout(() => {
      //     document.querySelector("#swal2-content > h1 > strong > span").innerHTML = "PREDIO SIN ADEUDO"
      //     document.querySelector("body > div.swal2-container.swal2-center.swal2-backdrop-show > div > div.swal2-actions").innerHTML = "Gracias por su pago"
      //   }, 500);
      // }

      // this.init();

    },
     async nuevoContrato() {
      const contrato = await this.$swal
              .fire({
                title: "Ingresar clave catastral",
                input: "text",
                inputAttributes: {
                  autocapitalize: "off",
                },
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                showCancelButton: false,
                preConfirm: (contrato) => {
                  return contrato;
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
              })
      if(!contrato.isConfirmed){
        return;
      }
      const iniciales = await this.$swal.fire({
            title: "Ingresar las iniciales",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
              onkeyup:"javascript:this.value=this.value.toUpperCase();"
            },
            confirmButtonText: "Agregar",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            showCancelButton: false,
            preConfirm: (inicales) => {
              return inicales;
            }
          })
      if(!iniciales.isConfirmed){
        return;
      }
      const consultaIniciales = await api.infoContrato((contrato.value).replace(/-/g,""));

      if(consultaIniciales[0].DATA){
        let resultado= ((JSON.parse(consultaIniciales[0].DATA).Contribuyente[0].NombreContribuyente).trim()).split(' ').map(w => w[0].toUpperCase()).join('');
        if(resultado != iniciales.value){
          let RFC= ((JSON.parse(consultaIniciales[0].DATA).Contribuyente[0].RFC).trim());
          if(RFC != iniciales.value){
            const mensaje = await this.$swal(
              "¡Error!",
              "Los datos ingresados son incorrectos.",
              "error"
            );
            return mensaje;
          }
        }
      }


      const res = await api.altaContrato((contrato.value).replace(/-/g,""));
          if (res.length > 0) {
            if (res[0].DATA) {
              api.updateStorage().then((response) => {
                  if (response) {
                    this.$swal("Ok!", "El servicio fue agregado.", "success");
                    this.Serv = JSON.parse(localStorage.getItem("Servicios")).length;
                    this.Nombre = localStorage.Nombre + " " + localStorage.Apellidos;
                    this.Services = JSON.parse(localStorage.getItem("Servicios"));
                    this.Ser = true;
                  }
                })
                .catch((error) => {
                  //console.log(
                  //   "Error: " + error + " **UpdateStorage Servicio**"
                  // );
                });

            } else {
                this.$swal(
                  "Error!",
                  "El contrato esta agregado o no existe!",
                  "error"
                );
            }
          } else {
            //console.log("Se cancelo")
          }
    },

    eliminarRegistro_Remote(clave){
      this.$swal({
        title: "Desea Eliminarlo?",
        text: "El Servicio Se Eliminara Permanentemente",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminarlo!",
      }).then( async (result) => {
        if (result.value) {
          try {
            this.loading("Eliminando registro")
            const response = await api.deleteRegistros_Remote(clave);
            if (response) {
              await this.getRegistros_Remote();
              this.$swal(
                      "Eliminado!",
                      "El servicio fue eliminado.",
                      "success"
                    );
            } else {

            }
          } catch (error) {
            this.$swal(
                      "Error!",
                      "El servicio no fue eliminado.",
                      "error"
                    );
            //console.log(error)
          }
        }
      });
    },
    loading(mensaje){
      this.$swal({
        title: mensaje,
        imageUrl: "https://i.pinimg.com/originals/b3/30/0a/b3300a501c0897d36683d6f6d0b000a5.gif",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    },
    showAlert(NumeroDeContrato) {
      // Use sweetalret2
      this.$swal({
        title: "Desea Eliminarlo?",
        text: "El Servicio Se Eliminara Permanentemente",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminarlo!",
      }).then((result) => {
        if (result.value) {
          api
            .deleteService(NumeroDeContrato)
            .then((response) => {
              api
                .updateStorage()
                .then((response) => {
                  if (response) {
                    this.$swal(
                      "Eliminado!",
                      "El servicio fue eliminado.",
                      "success"
                    );
                  } else {
                    this.$swal(
                      "Error!",
                      "El servicio no fue eliminado.",
                      "error"
                    );
                  }
                  this._data.Services = JSON.parse(
                    localStorage.getItem("Servicios")
                  );
                  this._data.Ser = false;
                  this._data.Ser = true;
                })
                .catch((error) => {
                  //console.log(
                  //   "Error: " + error + " **UpdateStorage Servicio**"
                  // );
                });
            })
            .catch((error) => {
              //console.log(error);
            });
        }
      });
    },
  },
};
</script>
<style>
@media (min-width: 768px) {
  .main {
    padding-left: 240px; /* 180 + 40 */
  }
}
</style>
