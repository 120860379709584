<template>
     <div>
 <!-- Bootstrap NavBar -->
<!-- NavBar END -->
<!-- Bootstrap row -->
<div class="row" id="body-row">
    <!-- Sidebar -->
    <div id="sidebar-container" class="sidebar-expanded d-none d-md-block fixed-top"><!-- d-* hiddens the Sidebar in smaller devices. Its itens can be kept on the Navbar 'Menu' -->
        <!-- Bootstrap List Group -->
        <br>
        <div class="corazon">
          <center>
     <img style="width:80%" src='../assets/logoP.svg'/>
     </center>
     </div>
<br>
<li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
    <center>
     <FONT style="color:#fff;" FACE="arial" SIZE=2.5><i class="fas fa-user-circle mr-1"></i>Bani Jesua Rosas Tabarez</FONT>
    </center>
</li>
        <ul class="list-group">
            <!-- Separator with title -->
            <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
                <small style="color:#ffad00;">Menu</small>
            </li>
            <router-link to="#/Dashboard" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fa fa-circle-notch fa-fw mr-3"></span>
                    <span class="menu-collapsed">Dashboard</span>
                </div>
            </router-link>
            <router-link to="#" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fa fa-user fa-fw mr-3"></span>
                    <span class="menu-collapsed">Administrador</span>
                </div>
            </router-link>
            <router-link to="#/Servicios" class="bg-dark list-group-item list-group-item-action">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-envelope fa-fw mr-3"></span>
                    <span class="menu-collapsed">Mensajes<span class="badge badge-pill badge-primary ml-2">0</span></span>
                </div>
            </router-link>
             <router-link to="#/Contratos" class="bg-dark list-group-item list-group-item-action">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-file-alt fa-fw mr-3"></span>
                    <span class="menu-collapsed">Contratos</span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <!-- /END Separator -->
            <!-- Menu with submenu -->
            <!-- Separator with title -->
            <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
                <small style="color:#ffad00;">Opciones</small>
            </li>
            <!-- /END Separator -->
              <router-link to="#submenu1" data-toggle="collapse" aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-map-marker-alt fa-fw mr-3"></span>
                    <span class="menu-collapsed">Mapa</span>
                    <span class="submenu-icon ml-auto"></span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <div id='submenu1' class="collapse sidebar-submenu">
                <router-link to="#" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Charts</span>
                </router-link>
                <router-link to="#/Domicilios" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Direcciones</span>
                </router-link>
                <!--a to="#" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed">Tables</span>
                </a-->
            </div>
           <router-link to="#submenu2" data-toggle="collapse" aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="far fa-credit-card fa-fw mr-3"></span>
                    <span class="menu-collapsed">Pagos</span>
                    <span class="submenu-icon ml-auto"></span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <div id='submenu2' class="collapse sidebar-submenu">
                <router-link to="#/ProximoPago" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Proximo Pago</span>
                </router-link>
                <router-link to="#/HistorialPagos" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Historial De Pagos</span>
                </router-link>
            </div>
             <router-link to="#submenu3" data-toggle="collapse" aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-cog fa-fw mr-3"></span>
                    <span class="menu-collapsed">Configuracion</span>
                    <span class="submenu-icon ml-auto"></span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <div id='submenu3' class="collapse sidebar-submenu">
                <router-link to="#/ActualizaInformacion" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Actualizar Informacion</span>
                </router-link>
            </div>
            <!-- Separator without title -->
            <li class="list-group-item sidebar-separator menu-collapsed"></li>
            <!-- /END Separator -->
            <router-link to="#" data-toggle="sidebar-colapse" class="bg-dark list-group-item list-group-item-action d-flex align-items-center">
                <div  class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span id="collapse-icon" class="fas fa-sign-out-alt mr-3"></span>
                    <span id="collapse-text" class="menu-collapsed">Salir</span>
                </div>
            </router-link>
            <!-- Logo -->
</ul><!-- List Group END-->
    </div><!-- sidebar-container END -->
    <!-- MAIN -->
     <nav class="navbar d-sm-block d-md-none navbar-dark"  style="width: 100%;">

  <button class="navbar-toggler mr-8" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon mr-1"></span>
  </button>
  <img class="corazon" style="width: 10%;min-width: 60px;float: right;" src='../assets/logoP.svg'/>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto d-sm-block d-md-none">
        <center>
  <li class="nav-item active">
 <router-link class="nav-link" to="#/home" style="color:#fff;"><i class="fas fa-home mr-1"></i>Inicio</router-link>
 </li>
  <hr size="2px" color="white" />
 <li class="nav-item active">
 <router-link class="nav-link" to="#/perfil" style="color:#fff;"><i class="fas fa-user mr-1"></i>Perfil</router-link>
 </li>
  <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/Servicios" style="color:#fff;"><i class="fas fa-cog mr-1"></i>Servicios</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/Contratos" style="color:#fff;"><i class="fas fa-file-alt mr-1"></i>Contratos</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/Domicilios" style="color:#fff;"><i class="fas fa-map-marker-alt mr-1"></i>Domicilios</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/ProximoPago" style="color:#fff;"><i class="fas fa-money-check-alt mr-1"></i>Proximo Pago</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/HistorialPagos" style="color:#fff;"><i class="fas fa-list-ol mr-1"></i>Historial De Pagos</router-link>
 </li>
  <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/ActualizaInformacion" style="color:#fff;"><i class="fas fa-pen mr-1"></i>Actualizar Informacion</router-link>
 </li>
 <hr size="2px" color="white" />
<li class="nav-item">
        <router-link class="nav-link " to="#" style="color:#fff;"><i class="fas fa-sign-out-alt mr-1"></i>Salir</router-link>
      </li>
      </center>
      <!-- This menu is hidden in bigger devices with d-sm-none. The sidebar isn't proper for smaller screens imo, so this dropdown menu can keep all the useful sidebar itens exclusively for smaller screens  -->
      </ul>
  </div>
</nav>

  <div class="col-sm-12 main">
      <!--menu responsive-->

        <h1 class="p" style="color:#fff;">
          <FONT FACE="arial" SIZE=6>  <i class="fas fa-address-card"></i>
            Mensajeria</FONT>
            <small class="text-muted"></small>
        </h1>
        <hr size="2px" color="white" />
<div class="container">
<div class="messaging">
      <div class="inbox_msg" style="background: white;">
        <div class="inbox_people">
          <div class="headind_srch">
            <div class="recent_heading">
              <h4>En linea</h4>
            </div>
            <div class="srch_bar">
              <div class="stylish-input-group">
                <input type="text" class="search-bar"  placeholder="Search" >
                <span class="input-group-addon">
                <button type="button"> <i class="fa fa-search" aria-hidden="true"></i> </button>
                </span> </div>
            </div>
          </div>
          <div class="inbox_chat">
            <div class="chat_list active_chat">
              <div class="chat_people">
                <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                <div class="chat_ib">
                  <h5>Angel Roman <span class="chat_date" style="color: forestgreen;"><i class="fas fa-circle"></i></span></h5>
                  <p>Nececito soporte, para poder realizar mis pagos en linea.</p>
                </div>
              </div>
            </div>
            <div class="chat_list">
              <div class="chat_people">
                <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                <div class="chat_ib">
                  <h5>David Cardenas <span class="chat_date" style="color: forestgreen;"><i class="fas fa-circle"></i></span></h5>
                  <p>Test, which is a new approach to have all solutions
                    astrology under one roof.</p>
                </div>
              </div>
            </div>
            <div class="chat_list">
              <div class="chat_people">
                <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                <div class="chat_ib">
                  <h5>Bani Rosas <span class="chat_date" style="color: forestgreen;"><i class="fas fa-circle"></i></span></h5>
                  <p>Test, which is a new approach to have all solutions
                    astrology under one roof.</p>
                </div>
              </div>
            </div>
            <div class="chat_list">
              <div class="chat_people">
                <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                <div class="chat_ib">
                  <h5>David Lugo <span class="chat_date" style="color: forestgreen;"><i class="fas fa-circle"></i></span></h5>
                  <p>Test, which is a new approach to have all solutions
                    astrology under one roof.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mesgs">
          <div class="msg_history">
            <div class="incoming_msg">
              <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
              <div class="received_msg">
                <div class="received_withd_msg">
                  <p>Test which is a new approach to have all
                    solutions</p>
                  <span class="time_date"> 11:01 AM    |    June 9</span></div>
              </div>
            </div>
            <div class="outgoing_msg">
              <div class="sent_msg">
                <p>Test which is a new approach to have all
                  solutions</p>
                <span class="time_date"> 11:01 AM    |    June 9</span> </div>
            </div>
            <div class="incoming_msg">
              <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
              <div class="received_msg">
                <div class="received_withd_msg">
                  <p>Test, which is a new approach to have</p>
                  <span class="time_date"> 11:01 AM    |    Yesterday</span></div>
              </div>
            </div>
            <div class="outgoing_msg">
              <div class="sent_msg">
                <p>Apollo University, Delhi, India Test</p>
                <span class="time_date"> 11:01 AM    |    Today</span> </div>
            </div>
            <div class="incoming_msg">
              <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
              <div class="received_msg">
                <div class="received_withd_msg">
                  <p>We work directly with our designers and suppliers,
                    and sell direct to you, which means quality, exclusive
                    products, at a price anyone can afford.</p>
                  <span class="time_date"> 11:01 AM    |    Today</span></div>
              </div>
            </div>
          </div>
          <div class="type_msg">
            <div class="input_msg_write">
              <input type="text" class="write_msg" placeholder="Escribe tu mensaje" />
              <button class="msg_send_btn" type="button"><i class="fas fa-location-arrow"></i></button>
            </div>
          </div>
        </div>
      </div>

    </div></div>

</div>

</div>
</div>
</template>

<script>
export default {
    name: "Mensajeriaview"
}
</script>


<style>
.container{max-width:1170px; margin:auto;}
img{ max-width:100%;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
  padding:a
}
.headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #212529;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg { width: 57%;}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}

 .sent_msg p {
  background: #ff5833 none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {border-top: 1px solid #c4c4c4;position: relative;}
.msg_send_btn {
  background: #ff5833 none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging { padding: 0 0 50px 0;}
.msg_history {
  height: 516px;
  overflow-y: auto;
}

.sidebar {
     width: 180px;
}

@media (min-width: 768px) {
    .main {
        padding-left: 243px; /* 180 + 40 */
    }
}

.bg-white {
    background-color: #333333ad!important;
}
#body-row {
  margin-left: 0;
  margin-right: 0;
}
#sidebar-container {
  min-height: 130vh;
  background-color: #333;
  padding: 0;
}
/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
  width: 230px;
}
.sidebar-collapsed {
  width: 60px;
}
/* Menu item*/
#sidebar-container .list-group a {
  height: 50px;
  color: white;
}
/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
  height: 45px;
  padding-left: 30px;
}
.sidebar-submenu {
  font-size: 0.9rem;
}
/* Separators */
.sidebar-separator-title {
  background-color: #333;
  height: 35px;
}
.sidebar-separator {
  background-color: #333;
  height: 25px;
}
.logo-separator {
  background-color: #333;
  height: 60px;
}
/* Closed submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="false"]
  .submenu-icon::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/* Opened submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="true"]
  .submenu-icon::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/*TRASICION DEL TITULO*/
.p {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {

  }
}
@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.1); }
    to { transform: none; }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
	display: inline-block;
	animation: latidos 4s infinite;
}
</style>
