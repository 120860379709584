<!--Creacion del menu-->
<template>
  <Menu>
    <!--menu responsive-->
    <div class="row">
      <div class="col-sm-6" style="margin-top: 10px">
        <div class="input-group mb-2">
          <input
            style="width:200px;"
            type="text"
            id="myInputClave"
            v-on:keyup="busquedaClave()"
            class="form-control"
            placeholder="Buscar por clave"
            aria-label="Buscar"
            aria-controls="table"
            aria-describedby="basic-addon2"
          />
          <input
           style="width:200px;"
            type="text"
            id="myInput"
            v-on:keyup="busqueda()"
            class="form-control"
            placeholder="Buscar por nombre"
            aria-label="Buscar"
            aria-controls="table"
            aria-describedby="basic-addon2"
          />
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover fadeInUp" id="table">
        <thead class="thead" style="background-color:rgb(229 134 80)">
          <tr>
            <th scope="col" class="text-center">#</th>
            <th scope="col" class="text-center">
              <i class="fas fa-cog mr-1"></i>Servicio
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-calendar mr-1"></i>Clave Catastral
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-calendar mr-1"></i>Nombre
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-calendar-alt mr-1"></i>Fecha De Pago
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-calendar-check mr-1"></i>Periodo De Pago
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-check-circle mr-1"></i>Status
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-hand-holding-usd mr-1"></i>Monto
            </th>
            <th scope="col" class="text-center">
              <i class="fas fa-hand-holding-usd mr-1"></i>Comprobante
            </th>
          </tr>
        </thead>
        <tbody style="background-color: rgba(179, 179, 179, 0.57)">
          <tr v-for="(Histo, key) in Historial" v-bind:key="key">
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ key + 1 }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              <i v-if="Histo.NombreServicio == 'AGUA POTABLE'" class="fas fa-tint mr-2"></i>
              <i v-if="Histo.NombreServicio == 'PREDIAL'" class="fas fa-home mr-2"></i>
              {{ Histo.NombreServicio }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Histo.NumeroDeContrato }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Histo.nombreIniciales }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Histo.FechaDePago }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Histo.periodosPagados }}
            </td>
            <td
              scope="row"
              class="text-center font-weight-bold"
              style="color: #28ff59"
            >
              {{ Histo.Status }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              {{ Histo.Monto }}
            </td>
            <td scope="row" class="text-center" style="color: #fdfefe">
              <button class="button w-30 mr-1 mb-2 border text-gray-700 dark:bg-dark-5 dark:text-gray-300" v-on:click="createPDF(Histo)">Comprobante</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Menu>
</template>
<script>
import myRouter from "../router/index.js";
import Menu from "../components/Menu.vue";
import axios from "axios";
import * as api from "../router/api.js";
import VueBarcode from "vue-barcode";
import JsBarcode from "jsbarcode";
export default {
  name: "HistorialPagosview",
  components: {
    Menu,
  },
  mounted() {
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "HistorialPagosview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  data() {
    var aMoneda = function (numero) {
      const opciones = {
        numeroDeDecimales: 2,
        separadorDecimal: ".",
        separadorMiles: ",",
        simbolo: "$ ", // Con un espacio, ya que la función no agrega espacios
        posicionSimbolo: "i", // i = izquierda, d = derecha
      };
      // Valores por defecto
      opciones.simbolo = opciones.simbolo || "$";
      opciones.separadorDecimal = opciones.separadorDecimal || ".";
      opciones.separadorMiles = opciones.separadorMiles || ",";
      opciones.numeroDeDecimales =
        opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
      opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
      const CIFRAS_MILES = 3;

      // Redondear y convertir a cadena
      let numeroComoCadena = numero.toFixed(opciones.numeroDeDecimales);

      // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
      let posicionDelSeparador = numeroComoCadena.indexOf(
        opciones.separadorDecimal
      );
      if (posicionDelSeparador === -1)
        posicionDelSeparador = numeroComoCadena.length;
      let formateadoSinDecimales = "",
        indice = posicionDelSeparador;
      // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
      while (indice >= 0) {
        let limiteInferior = indice - CIFRAS_MILES;
        // Agregar separador si cortamos más de 3
        formateadoSinDecimales =
          (limiteInferior > 0 ? opciones.separadorMiles : "") +
          numeroComoCadena.substring(limiteInferior, indice) +
          formateadoSinDecimales;
        indice -= CIFRAS_MILES;
      }
      let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(
        posicionDelSeparador,
        opciones.numeroDeDecimales + 1
      )}`;
      return opciones.posicionSimbolo === "i"
        ? opciones.simbolo + formateadoSinSimbolo
        : formateadoSinSimbolo + opciones.simbolo;
    }
    var histo = JSON.parse(localStorage.getItem("Historial"));
    var histData=[];
    histo.forEach(element => {
      element.nombreIniciales= element.NombreContribuyente.split(' ').map(w => w[0].toUpperCase()).join('');
      element.Monto = aMoneda(element.Monto);
      histData.push(element)
    });
    return {
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,
      Historial: histData,
    };
  },

  ready() {
    this.getNotifications();
  },

  methods: {
    textToBase64Barcode: function(text){
         var canvas = document.createElement("canvas");
         JsBarcode(canvas, text, {format: "CODE39"});
         return canvas.toDataURL("image/png");
    },
    createPDF: function (data) {
      var numeroDeContrato = data.NumeroDeContrato;
      var municipio = data.Municipio;
      var barcode = this.textToBase64Barcode(data.OidHistorial);
      var servicio = data.NombreServicio.toLowerCase()
      var transacc = data.idTransaccion.substring(0, 3);
      var idTransaccion = data.idTransaccion;
      var monto = data.Monto;
      var periodosPagados = data.periodosPagados;
      var status = data.Status;
      var fechaDePago = data.FechaDePago;
      var nombreContribuyente = data.NombreContribuyente;
      var OidContribuyente = data.OidContribuyente;
      var filename = "comprobante_"+data.NumeroDeContrato;
      var pageSize = "letter";
      var orientation = "portrait";
      api
        .createPDF(numeroDeContrato, municipio, barcode, servicio, transacc, idTransaccion, monto, status, periodosPagados, fechaDePago,nombreContribuyente, OidContribuyente, filename, pageSize, orientation)
        .then((response) => {
          //console.log(response)
          window.open("http://localhost:3000/print/" + filename + ".pdf", '_blank');
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    comprobante: function(id){

      myRouter.push({
          name: "ComprobantePagoview",
          params: { Oid: id },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });

    },
    busqueda: function () {
      // Declaracion de variables
      var input, filter, table, tr, td, i;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      table = document.getElementById("table");
      tr = table.getElementsByTagName("tr");

      // Recorre todas las filas de la tabla y oculta a quienes no coincidan con la consulta de búsqueda
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[3];
        if (td) {
          if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
    busquedaClave: function () {
      // Declaracion de variables
      var input, filter, table, tr, td, i;
      input = document.getElementById("myInputClave");
      filter = input.value.toUpperCase();
      table = document.getElementById("table");
      tr = table.getElementsByTagName("tr");

      // Recorre todas las filas de la tabla y oculta a quienes no coincidan con la consulta de búsqueda
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[2];
        if (td) {
          if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },

    Salir: function () {
      this.$session.destroy();
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    getNotifications() {
      this.notifications = ["Item 1", "Item 2", "Item 3"];
    },
  },
};
</script>
<style>
@media (min-width: 768px) {
  .main {
    padding-left: 240px; /* 180 + 40 */
  }
}
#body-row {
  margin-left: 0;
  margin-right: 0;
}
#sidebar-container {
  min-height: 130vh;
  background-color: #333;
  padding: 0;
}
/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
  width: 230px;
  height: 100%;
}
.sidebar-collapsed {
  width: 60px;
}
/* Menu item*/
#sidebar-container .list-group a {
  height: 50px;
  color: white;
}
/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
  height: 45px;
  padding-left: 30px;
}
.sidebar-submenu {
  font-size: 0.9rem;
}
/* Separators */
.sidebar-separator-title {
  background-color: #333;
  height: 35px;
}
.sidebar-separator {
  background-color: #333;
  height: 25px;
}
.logo-separator {
  background-color: #333;
  height: 60px;
}
/* Closed submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="false"]
  .submenu-icon::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/* Opened submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="true"]
  .submenu-icon::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/*TRASICION DEL TITULO*/
.p {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
  }
}

@keyframes latidos {
  from {
    transform: none;
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: none;
  }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
  display: inline-block;
  animation: latidos 4s infinite;
}
</style>
