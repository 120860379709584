<!--Creacion del menu-->
<template>
  <Menu>
    <div class="container-fluid px-1 px-md-2 px-lg-4 py-1 mx-auto">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-8 col-lg-9 col-md-9 col-sm-11">
          <div class="card border-0">
            <div class="row justify-content-center">
              <h3
                class="mb-4 text-2xl text-gray-300 dark:text-gray-600 font-medium leading-none mt-3"
              >
                Pago con tarjeta de Crédito / Débito
              </h3>
            </div>
            <div class="row">
              <div class="col-sm-7 border-line pb-3">
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">Titular de la Tarjeta</p>
                  <input
                    style="width: 250px"
                    type="text"
                    id="nomTar"
                    name="name"
                    placeholder="Nombre"
                    size="15"
                  />
                </div>
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">Número de tarjeta</p>
                  <div class="row px-3">
                    <input
                      style="width: 200px"
                      type="text"
                      name="card-num"
                      placeholder="0000 0000 0000 0000"
                      size="18"
                      id="cr_no"
                      minlength="19"
                      maxlength="19"
                    />
                    <p class="mb-0 ml-3">/</p>
                    <img
                      class="image ml-1"
                      src="https://i.imgur.com/WIAP9Ku.jpg"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">Fecha de caducidad</p>
                  <input
                    type="text"
                    name="exp"
                    placeholder="MM/AA"
                    size="6"
                    id="exp"
                    minlength="5"
                    maxlength="5"
                  />
                </div>
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">CVV/CVC</p>
                  <input
                    style="width: 30px"
                    type="password"
                    id="cvv"
                    name="cvv"
                    placeholder="***"
                    size="1"
                    minlength="3"
                    maxlength="3"
                  />
                </div>
                <!-- <div class="form-group mb-0">
                  <div
                    class="custom-control custom-checkbox custom-control-inline"
                  >
                    <input
                      id="chk1"
                      type="checkbox"
                      name="chk"
                      class="custom-control-input"
                      checked
                    />
                    <label
                      for="chk1"
                      class="custom-control-label text-muted text-sm"
                      >guardar mi tarjeta para pagos futuros</label
                    >
                  </div>
                </div> -->
              </div>
              <div class="col-sm-5 text-sm-center justify-content-center">
                <h4
                  class="text-2xl text-gray-300 dark:text-gray-600 font-medium leading-none mt-3"
                >
                  Pagos pendientes
                </h4>
                <!-- <small class="text-sm text-muted">Monto del pago</small> -->
                <div style="text-align: left" class="form-group mb-0">
                  <div class="" style="width: 500px; max-width: 100%">
                    <br />
                    <div class="row px-3 justify-content-sm-center">
                      <h2 class="">
                        <span class="text-md font-weight-bold mr-2"> </span>
                        <span class="text-danger">
                          {{ montoSeleccionado }}</span
                        >
                      </h2>
                    </div>
                    <br /><br />
                    <div>
                      <div color="primary" style="text-align: center">
                        <input
                          type="checkbox"
                          style="width: 20px; height: 15px"
                          id="PagoOtraCantidad"
                        />
                        <label style="font-family: sans-serif; color: black"
                          >Pagar otra cantidad</label
                        >
                      </div>
                      <center>
                        <fieldset>
                          <input
                            disabled="disabled"
                            style="border-color: red"
                            id="otroMonto"
                            type="number"
                            name="monto"
                            placeholder="Cantidad"
                          />
                        </fieldset>
                      </center>
                    </div>
                  </div>
                </div>
                <hr size="2px" color="white" />
                <button
                  v-on:click="pagar()"
                  class="button w-24 mr-1 mb-2 border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
                >
                  Pagar
                </button>
                <!-- <button type="submit" class="btn btn-red text-center mt-4">
                  PAGAR
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="grid offset-md-3 grid-cols-9 gap-6 mt-1">
      <div class="checkout">
        <div class="credit-card-box">
          <div class="flip">
            <div class="front">
              <div class="chip"></div>
              <div class="logo">
                <svg
                  version="1.1"
                  id="visa"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="47.834px"
                  height="47.834px"
                  viewBox="0 0 47.834 47.834"
                  style="enable-background: new 0 0 47.834 47.834"
                >
                  <g>
                    <g>
                      <path
                        d="M44.688,16.814h-3.004c-0.933,0-1.627,0.254-2.037,1.184l-5.773,13.074h4.083c0,0,0.666-1.758,0.817-2.143
                         c0.447,0,4.414,0.006,4.979,0.006c0.116,0.498,0.474,2.137,0.474,2.137h3.607L44.688,16.814z M39.893,26.01
                         c0.32-0.819,1.549-3.987,1.549-3.987c-0.021,0.039,0.317-0.825,0.518-1.362l0.262,1.23c0,0,0.745,3.406,0.901,4.119H39.893z
                         M34.146,26.404c-0.028,2.963-2.684,4.875-6.771,4.875c-1.743-0.018-3.422-0.361-4.332-0.76l0.547-3.193l0.501,0.228
                         c1.277,0.532,2.104,0.747,3.661,0.747c1.117,0,2.313-0.438,2.325-1.393c0.007-0.625-0.501-1.07-2.016-1.77
                         c-1.476-0.683-3.43-1.827-3.405-3.876c0.021-2.773,2.729-4.708,6.571-4.708c1.506,0,2.713,0.31,3.483,0.599l-0.526,3.092
                         l-0.351-0.165c-0.716-0.288-1.638-0.566-2.91-0.546c-1.522,0-2.228,0.634-2.228,1.227c-0.008,0.668,0.824,1.108,2.184,1.77
                         C33.126,23.546,34.163,24.783,34.146,26.404z M0,16.962l0.05-0.286h6.028c0.813,0.031,1.468,0.29,1.694,1.159l1.311,6.304
                         C7.795,20.842,4.691,18.099,0,16.962z M17.581,16.812l-6.123,14.239l-4.114,0.007L3.862,19.161
                         c2.503,1.602,4.635,4.144,5.386,5.914l0.406,1.469l3.808-9.729L17.581,16.812L17.581,16.812z M19.153,16.8h3.89L20.61,31.066
                         h-3.888L19.153,16.8z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="number"></div>
              <div class="card-holder">
                <label>Card holder</label>
                <div></div>
              </div>
              <div class="card-expiration-date">
                <label>Expires</label>
                <div></div>
              </div>
            </div>
            <div class="back">
              <div class="strip"></div>
              <div class="logo">
                <svg
                  version="1.1"
                  id="visa"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="47.834px"
                  height="47.834px"
                  viewBox="0 0 47.834 47.834"
                  style="enable-background: new 0 0 47.834 47.834"
                >
                  <g>
                    <g>
                      <path
                        d="M44.688,16.814h-3.004c-0.933,0-1.627,0.254-2.037,1.184l-5.773,13.074h4.083c0,0,0.666-1.758,0.817-2.143
                         c0.447,0,4.414,0.006,4.979,0.006c0.116,0.498,0.474,2.137,0.474,2.137h3.607L44.688,16.814z M39.893,26.01
                         c0.32-0.819,1.549-3.987,1.549-3.987c-0.021,0.039,0.317-0.825,0.518-1.362l0.262,1.23c0,0,0.745,3.406,0.901,4.119H39.893z
                         M34.146,26.404c-0.028,2.963-2.684,4.875-6.771,4.875c-1.743-0.018-3.422-0.361-4.332-0.76l0.547-3.193l0.501,0.228
                         c1.277,0.532,2.104,0.747,3.661,0.747c1.117,0,2.313-0.438,2.325-1.393c0.007-0.625-0.501-1.07-2.016-1.77
                         c-1.476-0.683-3.43-1.827-3.405-3.876c0.021-2.773,2.729-4.708,6.571-4.708c1.506,0,2.713,0.31,3.483,0.599l-0.526,3.092
                         l-0.351-0.165c-0.716-0.288-1.638-0.566-2.91-0.546c-1.522,0-2.228,0.634-2.228,1.227c-0.008,0.668,0.824,1.108,2.184,1.77
                         C33.126,23.546,34.163,24.783,34.146,26.404z M0,16.962l0.05-0.286h6.028c0.813,0.031,1.468,0.29,1.694,1.159l1.311,6.304
                         C7.795,20.842,4.691,18.099,0,16.962z M17.581,16.812l-6.123,14.239l-4.114,0.007L3.862,19.161
                         c2.503,1.602,4.635,4.144,5.386,5.914l0.406,1.469l3.808-9.729L17.581,16.812L17.581,16.812z M19.153,16.8h3.89L20.61,31.066
                         h-3.888L19.153,16.8z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="ccv">
                <label>CCV</label>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="form" autocomplete="off" novalidate id="payment-form">
          <fieldset>
            <center>
              <label for="card-number" style="font-family: sans-serif">
                Número de tarjeta</label
              >
              <div class="grid grid-cols-12 gap-6 mt-1">
                <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                  <input
                    type="number"
                    id="card-number"
                    class="input-cart-number input w-full border mt-2"
                    pattern="\d*"
                    maxlength="4"
                    style="font-family: sans-serif"
                  />
                </div>
                <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                  <input
                    type="number"
                    id="card-number-1"
                    class="input-cart-number input w-full border mt-2"
                    pattern="\d*"
                    maxlength="4"
                    style="font-family: sans-serif"
                  />
                </div>
                <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                  <input
                    type="number"
                    id="card-number-2"
                    class="input-cart-number input w-full border mt-2"
                    pattern="\d*"
                    maxlength="4"
                    style="font-family: sans-serif"
                  />
                </div>
                <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                  <input
                    type="number"
                    id="card-number-3"
                    class="input-cart-number input w-full border mt-2"
                    pattern="\d*"
                    maxlength="4"
                    style="font-family: sans-serif"
                  />
                </div>
              </div>
            </center>
          </fieldset>
          <center>
            <fieldset>
              <label for="card-holder" style="font-family: sans-serif"
                >Titular de la tarjeta</label
              >
              <input
                type="text"
                id="card-holder"
                class="input-cart-text input w-full border mt-2"
              />
            </fieldset>
          </center>
          <div class="row h-100 justify-content-center align-items-left">
            <fieldset class="fieldset-expiration">
              <label
                for="card-expiration-month"
                class="row text-center"
                style="font-family: sans-serif"
                >Fecha de caducidad</label
              >
              <div class="select" style="background: white">
                <select id="card-expiration-month">
                  <option></option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </select>
              </div>
              <div class="select" style="background: white">
                <select id="card-expiration-year">
                  <option></option>
                  <option>2016</option>
                  <option>2017</option>
                  <option>2018</option>
                  <option>2019</option>
                  <option>2020</option>
                  <option>2021</option>
                  <option>2022</option>
                  <option>2023</option>
                  <option>2024</option>
                  <option>2025</option>
                </select>
              </div>
            </fieldset>
            <fieldset class="fieldset-ccv">
              <label
                for="card-ccv"
                style="font-family: sans-serif; text-align: center"
                >CCV</label
              >
              <input
                style="border-color: red"
                type="text"
                id="card-ccv"
                maxlength="3"
              />
            </fieldset>
          </div>
          <br />
          <center>
            <div class="" style="width: 500px; max-width: 100%">
              <div style="text-align: center">
                <center>
                  <div class="intro-y box p-5">
                    <h3
                      style="
                        font-family: sans-serif;
                        color: black;
                        font-size: 15px;
                      "
                    >
                      &nbsp;&nbsp;&nbsp;TOTAL
                    </h3>
                    <h1 style="font-family: sans-serif; color: black">
                      $ {{ $route.params.Monto }}
                    </h1>
                    <p>Más opciones de pago</p>
                    <div>
                      <label>Vertical Radio Button</label>
                      <div
                        class="flex items-center text-gray-700 dark:text-gray-500 mt-2"
                      >
                        <input
                          type="radio"
                          class="input border mr-2"
                          id="vertical-radio-chris-evans"
                          name="vertical_radio_button"
                          value="vertical-radio-chris-evans"
                        />
                        <label
                          class="cursor-pointer select-none"
                          for="vertical-radio-chris-evans"
                          >Chris evans</label
                        >
                      </div>
                      <div
                        class="flex items-center text-gray-700 dark:text-gray-500 mt-2"
                      >
                        <input
                          type="radio"
                          class="input border mr-2"
                          id="vertical-radio-liam-neeson"
                          name="vertical_radio_button"
                          value="vertical-radio-liam-neeson"
                        />
                        <label
                          class="cursor-pointer select-none"
                          for="vertical-radio-liam-neeson"
                          >Liam Neeson</label
                        >
                      </div>
                      <div
                        class="flex items-center text-gray-700 dark:text-gray-500 mt-2"
                      >
                        <input
                          type="radio"
                          class="input border mr-2"
                          id="vertical-radio-daniel-craig"
                          name="vertical_radio_button"
                          value="vertical-radio-daniel-craig"
                        />
                        <label
                          class="cursor-pointer select-none"
                          for="vertical-radio-daniel-craig"
                          >Daniel Craig</label
                        >
                      </div>
                    </div>
                  </div>
                </center>
                <br />
              </div>
              <div>
                <div color="primary" style="text-align: center">
                  <input
                    type="checkbox"
                    style="width: 20px; height: 15px"
                    id="PagoOtraCantidad"
                  />
                  <label style="font-family: sans-serif; color: white"
                    >Pagar otra cantidad</label
                  >
                </div>
                <center>
                  <fieldset>
                    <input
                      disabled="disabled"
                      style="border-color: red"
                      id="otroMonto"
                      type="number"
                      name="monto"
                      placeholder="Cantidad"
                    />
                  </fieldset>
                </center>
              </div>
            </div>
          </center>
          <center>
            <p class="CardError">
              <span class="card-errors" style="color: white"></span>
            </p>
            <button
              style="width: 55%"
              class="btn"
              v-on:click="pagar()"
              id="payment-form"
            >
              <i class="fa fa-lock"></i> PAGAR
            </button>
          </center>
        </div>
      </div>
    </div> -->
    <ul class="list-group list-group-flush"></ul>
  </Menu>
</template>
<script>
import * as api from "../router/api.js";
import myRouter from "../router/index.js";
import Menu from "../components/Menu.vue";

var montoSeleccionado = 0;
var TotalAnio = "";

export default {
  name: "PagosAguaview",
  components: {
    Menu,
  },
  methods: {
    pagosBimestre: async function () {
      //console.log("aqui");
      const pagosBimestre = await api.pagosBimestre(
        this.$route.params.numContrato
      );
      var valorTotal = pagosBimestre[0].monto;
      var bimestres = pagosBimestre[0];
      this.montoSeleccionado = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(valorTotal);
      this.unoB = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(this.round(bimestres.bimestre1));
      this.dosB = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(this.round(bimestres.bimestre2));
      this.tresB = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(this.round(bimestres.bimestre3));
      this.cuatroB = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(this.round(bimestres.bimestre4));
      this.cincoB = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(this.round(bimestres.bimestre5));
    },

    checked: function (e, valor) {
      //alert(valor);
      var checks = Array.from(
        document.querySelectorAll(".custom-control-input")
      );
      ////console.log(e);
      if (e.srcElement.checked) {
        this.montoSeleccionado = valor;
        checks.forEach((element) => {
          element.checked = false;
        });
        e.srcElement.checked = true;
      } else {
        this.montoSeleccionado = new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(this.$route.params.Monto);
        checks.forEach((element) => {
          if (element.id == "chk6") {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
      }
    },
    round: function (num, decimales = 2) {
      var signo = num >= 0 ? 1 : -1;
      num = num * signo;
      if (decimales === 0)
        //con 0 decimales
        return signo * Math.round(num);
      // round(x * 10 ^ decimales)
      num = num.toString().split("e");
      num = Math.round(
        +(num[0] + "e" + (num[1] ? +num[1] + decimales : decimales))
      );
      // x * 10 ^ (-decimales)
      num = num.toString().split("e");
      return (
        signo * (num[0] + "e" + (num[1] ? +num[1] - decimales : -decimales))
      );
    },
    Salir: function () {
      this.$session.destroy();
      myRouter.push({ name: "LoginView" });
    },
    tarjeta: function () {
      $("#PagoOtraCantidad").change(function () {
        if ($(this).is(":checked")) {
          $("#otroMonto").removeAttr("disabled");
        } else {
          $("#otroMonto").val("");
          $("#otroMonto").attr("disabled", "disabled");
        }
      });

      //For Card Number formatted input
      var cardNum = document.getElementById("cr_no");
      cardNum.onkeyup = function (e) {
        if (this.value == this.lastValue) return;
        var caretPosition = this.selectionStart;
        var sanitizedValue = this.value.replace(/[^0-9]/gi, "");
        var parts = [];

        for (var i = 0, len = sanitizedValue.length; i < len; i += 4) {
          parts.push(sanitizedValue.substring(i, i + 4));
        }
        for (var i = caretPosition - 1; i >= 0; i--) {
          var c = this.value[i];
          if (c < "0" || c > "9") {
            caretPosition--;
          }
        }
        caretPosition += Math.floor(caretPosition / 4);

        this.value = this.lastValue = parts.join(" ");
        this.selectionStart = this.selectionEnd = caretPosition;
      };

      //For Date formatted input
      var expDate = document.getElementById("exp");
      expDate.onkeyup = function (e) {
        if (this.value == this.lastValue) return;
        var caretPosition = this.selectionStart;
        var sanitizedValue = this.value.replace(/[^0-9]/gi, "");
        var parts = [];

        for (var i = 0, len = sanitizedValue.length; i < len; i += 2) {
          parts.push(sanitizedValue.substring(i, i + 2));
        }
        for (var i = caretPosition - 1; i >= 0; i--) {
          var c = this.value[i];
          if (c < "0" || c > "9") {
            caretPosition--;
          }
        }
        caretPosition += Math.floor(caretPosition / 2);

        this.value = this.lastValue = parts.join("/");
        this.selectionStart = this.selectionEnd = caretPosition;
      };
    },

    // //console.log("entro");
    // $("#numTarjeta").keyup(function () {
    //   var valor = this.firstElementChild.value;
    //   if (valor.length == 16) {
    //     this.tarjetaCompleta = this.firstElementChild.value;
    //   } else if (valor.length > 16) {
    //     this.firstElementChild.value = this.tarjetaCompleta;
    //   }
    // });

    // $("#mes").keyup(function () {
    //   var valor = this.firstElementChild.value;
    //   if (this.firstElementChild.value.substring(0, 1) <= 1) {
    //     if (valor.length == 2) {
    //       if (
    //         this.firstElementChild.value.substring(0, 1) == 1 &&
    //         this.firstElementChild.value.substring(1, 2) <= 2
    //       ) {
    //         this.mes = this.firstElementChild.value;
    //       } else if (
    //         this.firstElementChild.value.substring(0, 1) == 0 &&
    //         this.firstElementChild.value.substring(1, 2) >= 0
    //       ) {
    //         this.mes = this.firstElementChild.value;
    //       } else {
    //         this.firstElementChild.value = this.firstElementChild.value.substring(
    //           0,
    //           1
    //         );
    //       }
    //     } else if (valor.length > 2) {
    //       this.firstElementChild.value = this.mes;
    //     }
    //   } else {
    //     this.firstElementChild.value = "";
    //   }
    // });
    // $("#anio").keyup(function () {
    //   var valor = this.firstElementChild.value;
    //   if (valor.length == 4) {
    //     this.anio = this.firstElementChild.value;
    //   } else if (valor.length > 4) {
    //     this.firstElementChild.value = this.anio;
    //   }
    // });

    // $("#ccv").keyup(function () {
    //   var valor = this.firstElementChild.value;
    //   if (valor.length == 4) {
    //     this.ccv = this.firstElementChild.value;
    //   } else if (valor.length > 4) {
    //     this.firstElementChild.value = this.ccv;
    //   }
    // });

    // $("#PagoOtraCantidad").change(function () {
    //   if ($(this).is(":checked")) {
    //     $("#otroMonto").removeAttr("disabled");
    //   } else {
    //     $("#otroMonto").val("");
    //     $("#otroMonto").attr("disabled", "disabled");
    //   }
    // });

    // $("#otroMonto").keyup(function () {
    //   if (
    //     parseInt($("#otroMonto").val()) <= parseInt($("#montoLimpio").val())
    //   ) {
    //   } else {
    //     // alert("El monto que quieres pagar es superior al pago pendiente.")
    //     // $("#otroMonto").val(parseInt(this.$route.params.Monto))
    //   }
    // });

    // // ---- todas las funciones de la tarjeta!

    // $(".input-cart-number").on("keyup change", function () {
    //   var ok = $(this);

    //   if (ok.val().length > 3) {
    //     if (ok[0].id != "card-number-3") {
    //       ok.parent().next()[0].lastChild.focus();
    //     }
    //   }

    //   var card_number = "";
    //   $(".input-cart-number").each(function () {
    //     card_number += $(this).val() + " ";
    //     if ($(this).val().length == 4) {
    //       $(this).next().focus();
    //     }
    //   });

    //   $(".credit-card-box .number").html(card_number);
    // });

    // $("#card-holder").on("keyup change", function () {
    //   var ok = $(this);
    //   $(".credit-card-box .card-holder div").html(ok.val());
    // });

    // $("#card-holder").on("keyup change", function () {
    //   var ok = $(this);
    //   $(".credit-card-box .card-holder div").html(ok.val());
    // });

    // $("#card-expiration-month, #card-expiration-year").change(function () {
    //   var m = $("#card-expiration-month option").index(
    //     $("#card-expiration-month option:selected")
    //   );
    //   m = m < 10 ? "0" + m : m;
    //   var y = $("#card-expiration-year").val().substr(2, 2);
    //   $(".card-expiration-date div").html(m + "/" + y);
    // });

    // $("#card-ccv")
    //   .on("focus", function () {
    //     $(".credit-card-box").addClass("hover");
    //   })
    //   .on("blur", function () {
    //     $(".credit-card-box").removeClass("hover");
    //   })
    //   .on("keyup change", function () {
    //     $(".ccv div").html($(this).val());
    //   });

    // setTimeout(function () {
    //   $("#card-ccv")
    //     .focus()
    //     .delay(1000)
    //     .queue(function () {
    //       $(this).blur().dequeue();
    //     });
    // }, 500);
    pagar: async function () {
      Conekta.setPublicKey("key_KpYZHtawXyRrkJX83KUvywg");

      // Previene hacer submit más de una vez
      $("#payment-form").prop("disabled", true);

      var conektaSuccessResponseHandler = async function (token) {
        // var $form = $("#card-form");
        // Inserta el token_id en la forma para que se envíe al servidor
        // $form.append($('<input type="hidden" name="conektaTokenId" id="conektaTokenId">').val(token.id));
        // $form.get(0).submit(); //Hace submit
        //console.log(this.uuidDevice);
        //console.log(token.id);

        var cantidadAPagar = 0;
        if ($("#PagoOtraCantidad").is(":checked")) {
          cantidadAPagar = parseInt($("#otroMonto").val());
        } else {
          cantidadAPagar = parseInt(this.$route.params.Monto);
        }

        var pagoInfo = {
          Oid: localStorage.Oid,
          OidPagos: this.$route.params.OidV,
          deviceSessionId: this.uuidDevice,
          conektaTokenId: token.id,
          titular: $("#nomTar").val(),
          departamento: "Departamento",
          type: "card",
          monto: cantidadAPagar,
          correo: localStorage.Correo,
          direccion: "Pagoob",
          codigoPostal: "62900",
          contrato: this.$route.params.numContrato,
          telefono: "7771955264",
          bimestre: null,
        };

        let headers = {
          "Content-Type": "application/json",
        };

        //console.log(pagoInfo);

        const orden = await api.pago(pagoInfo, headers);

        if (orden.value) {
          //console.log(orden);
          const response = await api.updateStorage();
          if (response) {
            //console.log("Actualizo UpdateAllStorage.");
            this.$swal(
              "Ok!",
              "¡El pago se completo, se envío el comprobante de pago al correo electrónico.",
              "success"
            );
            myRouter.push({ name: "ProximoPagoview" });
          } else {
            this.$swal("¡ERROR!", "error");
          }
        } else {
          this.$swal("¡ERROR!", orden.message);
        }

        // api
        //   .pago(pagoInfo, headers)
        //   .then((response) => {
        //     if (response == false) {
        //       //console.log("Existe un error");
        //     } else {

        //       // realiza movimiento de trnsacción en la base de datos.
        //       var cantidadAPagar = 0;
        //       if ($("#PagoOtraCantidad").is(":checked")) {
        //         cantidadAPagar = parseInt($("#otroMonto").val());
        //       } else {
        //         cantidadAPagar = parseInt(this.$route.params.Monto);
        //       }
        //       api
        //         .transaccionExitosa(
        //           localStorage.Oid,
        //           this.$route.params.OidV,
        //           this.$route.params.numContrato,
        //           response.exito.id,
        //           cantidadAPagar
        //         )
        //         .then((response) => {
        //           if (!response[0].DATA) {
        //             //console.log("Existe un error");
        //           } else {
        //             //console.log("Transacción realizada en BD.");

        //             // Si el movimiento en la BD es true Actualiza el UpdateAllStorage
        //             api
        //               .updateStorage()
        //               .then((response) => {
        //                 if (response) {
        //                   //console.log("Actualizo UpdateAllStorage.");
        //                   this.$swal(
        //                     "Ok!",
        //                     "¡El pago se completo, se envío el comprobante de pago al correo electrónico.",
        //                     "success"
        //                   );
        //                   myRouter.push({ name: "ProximoPagoview" });
        //                 } else {
        //                   this.$swal("¡ERROR! Update", "error");
        //                 }
        //               })
        //               .catch((error) => {
        //                 //console.log(error);
        //               });
        //             // termina UpdateAllStorage
        //           }
        //         })
        //         .catch((error) => {
        //           //console.log(error);
        //         });
        //     }
        //   })
        //   .catch((error) => {
        //     //console.log(error);
        //   });
      }.bind(this);

      var conektaErrorResponseHandler = function (response) {
        var $form = $("#payment-form");
        $form.find(".card-errors").text(response.message_to_purchaser);
        //console.log(response.message_to_purchaser);
        $form.find("button").prop("disabled", false);
      };

      // Aqui empieza la ejecución del token Card
      var tarjeta = $("#cr_no").val();
      var titular = $("#nomTar").val();
      var mes = $("#exp").val().split("/")[0];
      var anio = $("#exp").val().split("/")[1];
      var code = $("#cvv").val();

      var tokenParams = {
        card: {
          number: tarjeta,
          name: titular,
          exp_year: anio,
          exp_month: mes,
          cvc: code,
          address: {
            street1: "Calle 123 Int 404",
            street2: "Col. Condesa",
            city: "Ciudad de Mexico",
            state: "Distrito Federal",
            zip: "12345",
            country: "Mexico",
          },
        },
      };

      Conekta.Token.create(
        tokenParams,
        conektaSuccessResponseHandler,
        conektaErrorResponseHandler
      );
      return false;
    },
  },

  mounted() {
    this.pagosBimestre();
    // alert(this.$route.params.Monto)
    ////console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" });
    } else {
      myRouter.push({ name: "PagosAguaview" });
    }
    this.tarjeta();
  },
  data() {
    var valorTotal = this.$route.params.Monto;

    return {
      Anual: new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(valorTotal),
      montoSeleccionado: new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(valorTotal),
      unoB: 0,
      dosB: 0,
      tresB: 0,
      cuatroB: 0,
      cincoB: 0,
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,
    };
  },
};

// ---- todas las funciones de la tarjeta!
</script>
<style>
.card {
  padding: 30px 25px 35px 50px;
  border-radius: 30px;
  box-shadow: 0px 4px 8px 0px #de6718;
  margin-top: 50px;
  margin-bottom: 50px;
}

.border-line {
  border-right: 1px solid #bdbdbd;
}

.text-sm {
  font-size: 13px;
}

.text-md {
  font-size: 18px;
}

.image {
  width: 60px;
  height: 30px;
}

::placeholder {
  color: grey;
  opacity: 1;
}

:-ms-input-placeholder {
  color: grey;
}

::-ms-input-placeholder {
  color: grey;
}

input {
  padding: 2px 0px;
  border: none;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 5px;
  margin-top: 2px;
  box-sizing: border-box;
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
}

input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ef5350;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

.btn-red {
  background-color: #ef5350;
  color: #fff;
  padding: 8px 25px;
  border-radius: 50px;
  font-size: 18px;
  letter-spacing: 2px;
  border: 2px solid #fff;
}

.btn-red:hover {
  box-shadow: 0 0 0 2px #ef5350;
}

.btn-red:focus {
  box-shadow: 0 0 0 2px #ef5350 !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ef5350;
}

@media screen and (max-width: 575px) {
  .border-line {
    border-right: none;
    border-bottom: 1px solid #eeeeee;
  }
}
</style>
