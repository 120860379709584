<template>
  <div class="margin">
      <div class="vue-tempalte bodyInicio">
        <center style="padding:10px">
            <img class="corazonn" style="width:50%" src="https://i.ibb.co/TBPdHMC/Captura-de-Pantalla-2022-02-18-a-la-s-9-23-32-removebg-preview.png" aling="top" />
            <!-- <img class="corazon" src="../assets/logoP.svg" aling="top" /> -->
        </center>
        <center>
          <h3 style="color:#313a55;font-variant-caps: all-petite-caps;border-bottom: inset;font-size: 25px;
    font-weight: bold;">PAGO DE IMPUESTO PREDIAL</h3>
        </center>
        <div class="form-group">
          <label>Usuario</label>
          <input
                  type="text"
                  class="form-control"
                  id="user"
                  name="user"
                  aria-describedby="emailHelp"
                  placeholder="Ingresa tu usuario"
                  required=""
                  autofocus=""
                />
        </div>

        <div class="form-group">
          <label>Contraseña</label>
          <input
          type="password"
          class="form-control"
          id="password"
          name="password"
          placeholder="Contraseña"
          required=""
          autofocus=""
        />
        </div>
        <p style="cursor:pointer;text-decoration: underline;color: #313a55;" v-on:click="recuperarContra" class="forgot-password text-right mt-2 mb-4">
          <span>¿Se te olvidó tu contraseña?</span>
        </p>

        <button
        id="acceder"
        v-on:click="validate"
        class="btn btn-dark btn-block"
        style="font-size: 1rem;">INGRESAR</button>
        <div style="text-align:center;color: #313a55;">¿Aún no tienes cuenta? <router-link to="/Registro"><u>Registrate aquí</u></router-link></div>
       <br>
       <center style="text-align:center;color: #313a55;">O</center>
       <br>
        <button
        v-on:click="sinCuenta"
        id="sinCuenta"
        class="btn btn-dark btn-block"
        style="font-size: 1rem;">CONSULTA Y PAGA SIN REGISTRO</button>
        <!-- <div style="text-align:center">Consultar y pagar sin registrarte <router-link to="/InConsultar"><u>Click aquí</u></router-link></div> -->
        <br>
      </div>
      <br>
      <center>
        <img class="corazon" style="width:100px" src="../assets/logoP.svg"  />
      </center>
  </div>

  <!-- <div id="app">
    <div id="login">
      <div id="description">
        <h1>Login</h1>
        <p>
          By logging in you agree to the ridiculously long terms that you didn't
          bother to read.
        </p>
      </div>
      <div id="form">
        <form>
          <label for="email">Email</label>
          <input
            type="text"
            id="user"
            placeholder="Usuario"
            autocomplete="off"
            class="form-control"
            name="user"
            aria-describedby="emailHelp"
            required=""
            autofocus=""
          />
          <label for="password">Password</label>&nbsp;
          <i class="fas fa-eye"></i>
          <input id="password" placeholder="**********" type="password" class="form-control" name="password"/>
          <small id="emailHelp" class="form-text text-muted"><u><a href="">Olvidé Mi Contraseña </a></u></small>
          <button type="submit" id="acceder"
                v-on:click="validate"
                class="btn btn-primary btn-lg"
                style="font-size: 1rem">Iniciar sesión</button>
        </form>
      </div>
    </div>
  </div> -->

  <!-- <div>
    <div class="container">
      <div class="row justify-content-center pt-5">
        <div class="col-md-7">
          <div>
            <div class="form-group">
              <br />
              <center>
                <img class="corazon" src="../assets/logoP.svg" aling="top" />
              </center>
              <label class="h3 mb-3 font-weight-normal">Iniciar Sesión</label
              ><br />
              <label for="userEmail">Correo Electronico</label>
              <input
                type="text"
                class="form-control"
                id="user"
                name="user"
                aria-describedby="emailHelp"
                placeholder="Ingresa Tu Email"
                required=""
                autofocus=""
              />
              <small id="emailHelp" class="form-text text-muted"
                ><u><a href="#/registro">No Tengo Cuenta</a></u></small
              ><br />
              <label for="password">Contraseña</label>
              <input
                type="password"
                class="form-control"
                id="password"
                name="password"
                placeholder="Contraseña"
                required=""
                autofocus=""
              />
              <small id="emailHelp" class="form-text text-muted"
                ><u><a href="">Olvidé Mi Contraseña </a></u></small
              >
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="recuerdaUser"
              />
              <label class="form-check-label" for="exampleCheck1"
                >Recordar Usuario</label
              >
            </div>
            <br />
            <center>
              <button
                id="acceder"
                v-on:click="validate"
                class="btn btn-primary btn-lg"
                style="font-size: 1rem"
              >
                Acceder
              </button>
              <button
                class="btn btn-secondary btn-lg"
                v-on:click="Invitado"
                style="font-size: 1rem"
              >
                Ingresar como Invitado</button
              ><br /><br />
            </center>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import myRouter from "../router/index.js";
import Vue from "vue";
import axios from "axios";
import * as api from "../router/api.js";
export default {
  name: "LoginView",
  methods: {
    loading(mensaje){
      this.$swal({
        title: mensaje,
        imageUrl: "https://i.pinimg.com/originals/b3/30/0a/b3300a501c0897d36683d6f6d0b000a5.gif",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    },
    sinCuenta: function(){
      this.$router.push("inConsultar").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    cargarForm: function () {
      this.$router.push("registro").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    init: function () {
      var ref = this
      $(function () {
        $(this).keypress(
          function(e) {
            if(e.which == 13) {
              ref.validate();
            }
          }.bind(this)
        );
      });

    },
    Invitado: function () {
      myRouter.push({ name: "Invitadoview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    recuperarContra: async function(){
      const correo = await this.$swal.fire({
        title: "Recuperar contraseña",
        html:
          "<div style=''><b>Ingresa tu usuario o correo electrónico: </b><br><br><span></span></div>",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        showCancelButton: false,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escribir tu correo electrónico";
          }
        },
        preConfirm: (correo) => {
          return correo;
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
      if (!correo.isConfirmed) {
        return;
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Cookie", "ARRAffinity=677ac4cf43949190e686e2e535c138184436357e2a68992e493c2138deca65cd; ARRAffinitySameSite=677ac4cf43949190e686e2e535c138184436357e2a68992e493c2138deca65cd");

        var urlencoded = new URLSearchParams();
        urlencoded.append("correo", correo.value);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };

        fetch("/contrasenaRecupera", requestOptions)
          .then(response => response.text())
          .then(result => {
            if(result){
              this.$swal("Información de recuperacion enviada al correo electrónico.", "", "success");
            } else {
              this.$swal("Error en la información", "", "error");
            }
          })
          .catch(error => {
//console.log('error', error)
          }
            
          );
      }
    },
    validate: function () {
      var Usuario = $("#user").val();
      var password = $("#password").val();
      api
        .loginApp(Usuario, password)
        .then((response) => {
          this.loading("Validando Usuario")
          if (response[0].hasOwnProperty("Oid")) {
            //El usuario existe y obtiene AllData
            //Aqui pones todo cuando es correcto el usuario y pass
            (localStorage.Oid = response[0].Oid),
              (localStorage.Nombre = response[0].Nombre);
            localStorage.Apellidos =
              response[0].ApellidoPaterno + " " + response[0].ApellidoMaterno;
            localStorage.Correo = response[0].Correo;
            localStorage.Rfc = response[0].Rfc;

            this.$session.start();
            this.$session.set("session", response[0].Oid);

            api
              .updateStorage()
              .then((response) => {
                setTimeout(() => {
                  myRouter.push({ name: "ConsultarPagarview" }).catch((error) => {
                  if (error.name != "NavigationDuplicated") {
                    throw error;
                  }
                });
                }, 5000);
              })
              .catch((error) => {
                //console.log("Error: " + error + " **UpdateStorage Servicio**");
              });
            //location.reload();
          } else {
            if (response[0].hasOwnProperty("usuario") && !response[0].usuario) {
              this.$swal("Nombre de usuario es incorrecto.", "", "error");
            } else if (
              response[0].hasOwnProperty("password") &&
              !response[0].password
            ) {
              this.$swal("Contraseña incorrecta.", "", "error");
            } else if (
              response[0].hasOwnProperty("EmailConfirm") &&
              !response[0].EmailConfirm
            ) {
              this.$swal(
                "¡Confirma tu cuenta!<br><br>" +
                  "Verifica tu correo electrónico y activa tu cuenta.",
                "",
                "warning"
              );
            }
          }
        })
        .catch((error) => {
          //console.log(error);
          this.$swal("Usuario y/o contraseña invalida!", "", "error");
        });
    },

  },
  mounted() {
    this.init();
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
       this.$router.push("inConsultar").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });

    } else {
      myRouter.push({ name: "homeview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  data() {
    return {
      visible: false,
      Nombre: null,
      Apellidos: null,
      Correo: null,
      Rfc: null,
    };
  },
};
</script>
<style scoped>
@keyframes latidos {
  from {
    transform: none;
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: none;
  }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
  /* display: inline-block;
  animation: latidos 3s infinite; */
  width: 20%;
}
.btn-dark {
    color: #fff;
    background-color: #df6618;
    border-color: #343a40;
}

.margin{
  margin-top:2%
}

@media screen and (max-width: 1980px) and (min-width: 800px) {
  .bodyInicio {
    margin-left: 33%;
    margin-right:35%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 20px;
    background: #ffffff;
  }
}

@media screen and (max-width: 800px) {
  .bodyInicio {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 20px;
    background: #313a55;
  }
}

.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;
}

.inner-block {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.vertical-center .form-control:focus {
  border-color: #2554ff;
  box-shadow: none;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label {
  font-weight: 500;
  color: #7a7a7a;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7a7a7a;
  margin: 0;
}

.forgot-password a {
  color: #54a6fe;
}

.social-icons {
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.5em;
  color: #222222;
}

.social-icons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-icons ul li {
  display: inline-block;
  zoom: 1;
  width: 65px;
  vertical-align: middle;
  border: 1px solid #e3e8f9;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  margin-right: 5px;
  background: #f4f6ff;
}

.social-icons ul li a {
  display: block;
  font-size: 1.4em;
  margin: 0 5px;
  text-decoration: none;
}
.social-icons ul li a i {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.social-icons ul li a:focus i,
.social-icons ul li a:active i {
  transition: none;
  color: #222222;
}
</style>
