<!--Creacion del menu-->
<template>
    <div class="container-fluid px-1 px-md-2 px-lg-4 py-1 mx-auto">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-8 col-lg-9 col-md-9 col-sm-11">
          <div class="card border-0">
            <div class="row justify-content-center">
              <h3
                class="mb-4 text-2xl text-gray-300 dark:text-gray-600 font-medium leading-none mt-3"
              >
                Pago con tarjeta de Crédito / Débito
              </h3>
            </div>
            <div class="row">
              <div class="col-sm-7 border-line pb-3">
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">Titular de la Tarjeta</p>
                  <input
                    style="width: 250px"
                    type="text"
                    id="nomTar"
                    name="name"
                    placeholder="Nombre"
                    size="15"
                  />
                </div>
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">Número de tarjeta</p>
                  <div class="row px-3">
                    <input
                      style="width: 200px"
                      type="text"
                      name="card-num"
                      placeholder="0000 0000 0000 0000"
                      size="18"
                      id="cr_no"
                      minlength="19"
                      maxlength="19"
                    />
                    <p class="mb-0 ml-3">/</p>
                    <img
                      class="image ml-1"
                      src="https://i.imgur.com/WIAP9Ku.jpg"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">Fecha de caducidad</p>
                  <input
                    type="text"
                    name="exp"
                    placeholder="MM/AA"
                    size="6"
                    id="exp"
                    minlength="5"
                    maxlength="5"
                  />
                </div>
                <div class="form-group">
                  <p class="text-muted text-sm mb-0">CVV/CVC</p>
                  <input
                    style="width: 30px"
                    type="password"
                    id="cvv"
                    name="cvv"
                    placeholder="***"
                    size="1"
                    minlength="3"
                    maxlength="3"
                  />
                </div>
              </div>
              <div class="col-sm-5 text-sm-center justify-content-center">
                <h4
                  class="text-2xl text-gray-300 dark:text-gray-600 font-medium leading-none mt-3"
                >
                  Resumen de Pago
                </h4>

                <hr size="2px" color="white" />

                <div class="row px-3 justify-content-sm-center">
                  <h5>
                    <span
                      v-if="optionPay == '1'"
                      class="text-md font-weight-bold mr-2"
                      style="color: #718196"
                      >Bimestres</span
                    >
                    <span
                      v-if="optionPay == '2'"
                      class="text-md font-weight-bold mr-2"
                      style="color: #718196"
                      >Ejercicio Fiscal</span
                    >
                    <span
                      v-if="optionPay == '3'"
                      class="text-md font-weight-bold mr-2"
                      style="color: #718196"
                      >Total de Adeudo</span
                    >
                    <br />
                    <span class="" style="color: #313a55">{{
                      bimestresAPagar
                    }}</span>
                  </h5>
                  <h5>
                    <span
                      class="text-md font-weight-bold mr-2"
                      style="color: #718196"
                      >Cantidad a pagar</span
                    >
                    <br />
                    <span class="" style="color: #313a55">{{ SubTotal }}</span>
                  </h5>
                </div>
                <hr size="2px" color="white" />
                <table
                  style="color: #636363; display: -webkit-inline-box"
                  class=""
                >
                  <tbody>
                    <tr>
                      <td style="text-align: left; width: 200px">SubTotal:</td>
                      <td
                        style="text-align: right; color: #313a55; width: 100%"
                      >
                        {{ SubTotal }}
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: left">Comisión:</td>
                      <td
                        style="text-align: right; color: #313a55; width: 100%"
                      >
                        {{ Comision }}
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: left; width: 50%">
                        Total a Pagar:
                      </td>
                      <td
                        style="text-align: right; color: #313a55; width: 100%"
                      >
                        {{ MontoTotal }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr size="2px" color="white" />
                <!-- <div class="row px-3 justify-content-sm-center">
                  <h2 class="">
                    <span class="text-md font-weight-bold mr-2">Bimestres</span><br>
                    <span class="text-danger"> {{ bimestresAPagar }}</span>
                  </h2>
                  <h2 class="">
                    <span class="text-md font-weight-bold mr-2">Cantidad a pagar:</span><br>
                    <span class="text-danger"> {{ MontoTotal }}</span>
                  </h2>
                </div> -->

                <button
                  v-on:click="pagar()"
                  class="button w-24 mr-1 mb-2 border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
                >
                  Pagar
                </button>
                <!-- <button type="submit" class="btn btn-red text-center mt-4">
                  PAGAR
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import * as api from "../router/api.js";
import myRouter from "../router/index.js";
import Menu from "../components/Menu.vue";
import JsBarcode from "jsbarcode";

export default {
  name: "QuickPagos",
  components: {
    Menu,
  },
  methods: {
    Salir: function () {
      this.$session.destroy();
      myRouter.push({ name: "LoginView" });
    },
    tarjeta: function () {
      //los checked de los montos por bimestre

      //For Card Number formatted input
      var cardNum = document.getElementById("cr_no");
      cardNum.onkeyup = function (e) {
        if (this.value == this.lastValue) return;
        var caretPosition = this.selectionStart;
        var sanitizedValue = this.value.replace(/[^0-9]/gi, "");
        var parts = [];

        for (var i = 0, len = sanitizedValue.length; i < len; i += 4) {
          parts.push(sanitizedValue.substring(i, i + 4));
        }
        for (var i = caretPosition - 1; i >= 0; i--) {
          var c = this.value[i];
          if (c < "0" || c > "9") {
            caretPosition--;
          }
        }
        caretPosition += Math.floor(caretPosition / 4);

        this.value = this.lastValue = parts.join(" ");
        this.selectionStart = this.selectionEnd = caretPosition;
      };

      //For Date formatted input
      var expDate = document.getElementById("exp");
      expDate.onkeyup = function (e) {
        if (this.value == this.lastValue) return;
        var caretPosition = this.selectionStart;
        var sanitizedValue = this.value.replace(/[^0-9]/gi, "");
        var parts = [];

        for (var i = 0, len = sanitizedValue.length; i < len; i += 2) {
          parts.push(sanitizedValue.substring(i, i + 2));
        }
        for (var i = caretPosition - 1; i >= 0; i--) {
          var c = this.value[i];
          if (c < "0" || c > "9") {
            caretPosition--;
          }
        }
        caretPosition += Math.floor(caretPosition / 2);

        this.value = this.lastValue = parts.join("/");
        this.selectionStart = this.selectionEnd = caretPosition;
      };
    },
    pagar: async function () {
      Conekta.setPublicKey("key_KpYZHtawXyRrkJX83KUvywg");

      // Previene hacer submit más de una vez
      $("#payment-form").prop("disabled", true);

      var conektaSuccessResponseHandler = async function (token) {
        var pagoInfo = {
          Oid: localStorage.Oid,
          OidPagos: this.$route.params.OidV,
          deviceSessionId: this.uuidDevice,
          conektaTokenId: token.id,
          titular: $("#nomTar").val(),
          departamento: "Departamento",
          type: "card",
          //monto: cantidadAPagar,
          correo: localStorage.Correo,
          direccion: "Pagoob",
          codigoPostal: "62900",
          contrato: this.$route.params.numContrato,
          telefono: "7771955264",
          //bimestre: ind,
          periodos: this.$route.params.Monto,
          contribuyente: this.$route.params.contribuyente,
          optionPay: this.$route.params.optionPay
        };

        let headers = {
          "Content-Type": "application/json",
        };

        //console.log(pagoInfo);

        // const result = await this.$swal.fire({
        //   title: "Confirmación",
        //   showCancelButton: true,
        //   confirmButtonText: "Pagar",
        //   cancelButtonText: "Cancelar",
        //   showLoaderOnConfirm: true,
        //   preConfirm: async (value) => {
        //     this.$swal({
        //       title: "Procesando Pago",
        //       text: "Por favor espera...",
        //       imageUrl:
        //         "https://finance.advids.co/wp-content/uploads//2017/04/card11.gif",
        //       showConfirmButton: false,
        //       allowOutsideClick: false,
        //     });
        //     const orden = await api.pago(pagoInfo, headers);
        //     return orden;
        //   },
        // });

        this.$swal
          .fire({
            title: "Confirmación de pago",
            html: `<table style="width: 100%;"><tbody><tr><td><h5><span class="text-md font-weight-bold mr-2" style="color: rgb(113, 129, 150);">Bimestres a pagar</span> <br> <span style="color: rgb(49, 58, 85);">`+ this.bimestresAPagar +`</span></h5></td></tr><tr><td><h5><span class="text-md font-weight-bold mr-2" style="color: rgb(113, 129, 150);">Cantidad Total</span> <br> <span style="color: rgb(49, 58, 85);">`+ this.MontoTotal +`</span></h5></td></tr></tbody></table>`,
            showCancelButton: true,
            confirmButtonText: "Pagar",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$swal({
                title: "Procesando Pago",
                text: "Por favor espera...",
                imageUrl:
                  "https://finance.advids.co/wp-content/uploads//2017/04/card11.gif",
                showConfirmButton: false,
                allowOutsideClick: false,
              });
              const orden = await api.pago(pagoInfo, headers);
              if (result.value) {
                //console.log(result.value);
                const response = await api.updateStorage();
                if (response) {
                  //console.log(response);
                  this.$swal({
                    icon: 'success',
                    title: 'Pago Realizado Exitosamente',
                    html: '¿Deseas Generar Tu Comprobante de Pago?',
                    showCancelButton: true,
                    confirmButtonText: `Continuar`,
                    cancelButtonText: `Cancelar`,
                    showCloseButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.dataComprobantePagado(pagoInfo.contrato,orden.ID);
                    }
                  })
                  myRouter.push({ name: "InConsultar" });
                } else {
                  this.$swal("¡ERROR!", "error");
                }
              } else {
                this.$swal("¡ERROR!", orden.message);
              }
            } else if (result.isDenied) {
              ////console.log("Calcelado...")
            }
          });
      }.bind(this);

      var conektaErrorResponseHandler = function (response) {
        var $form = $("#payment-form");
        $form.find(".card-errors").text(response.message_to_purchaser);
        this.$swal("¡ERROR!", response.message_to_purchaser);
        //console.log(response.message_to_purchaser);
        $form.find("button").prop("disabled", false);
      }.bind(this);

      // Aqui empieza la ejecución del token Card
      var tarjeta = $("#cr_no").val();
      var titular = $("#nomTar").val();
      var mes = $("#exp").val().split("/")[0];
      var anio = $("#exp").val().split("/")[1];
      var code = $("#cvv").val();

      var tokenParams = {
        card: {
          number: tarjeta,
          name: titular,
          exp_year: anio,
          exp_month: mes,
          cvc: code,
          address: {
            street1: "Calle 123 Int 404",
            street2: "Col. Condesa",
            city: "Ciudad de Mexico",
            state: "Distrito Federal",
            zip: "12345",
            country: "Mexico",
          },
        },
      };

      Conekta.Token.create(
        tokenParams,
        conektaSuccessResponseHandler,
        conektaErrorResponseHandler
      );
      return false;
    },
    dataComprobantePagado: function(numContrato,OidTrans){
      api
        .dataComprobantePagado(numContrato,OidTrans)
        .then((response) => {
          this.createPDF(response);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    textToBase64Barcode: function(text){
         var canvas = document.createElement("canvas");
         JsBarcode(canvas, text, {format: "CODE39"});
         return canvas.toDataURL("image/png");
    },
    createPDF: function (data) {
      var numeroDeContrato = data[0].NumerodeContrato;
      var municipio = data[0].Municipio;
      var barcode = this.textToBase64Barcode(data[0].Oid);
      var servicio = 'predial';
      var transacc = data[0].idTransaccion.substring(0, 3);
      var idTransaccion = data[0].CodigoDeTransaccion;
      var monto = data[0].Monto;
      var status = data[0].Status;
      var periodosPagados = data[0].periodosPagados;
      var fechaDePago = data[0].FechaDePago;
      var nombreContribuyente = data[0].Nombre;
      var OidContribuyente = data[0].idContribuyente;
      var filename = "comprobante_"+numeroDeContrato;
      var pageSize = "letter";
      var orientation = "portrait";
      api
        .createPDF(numeroDeContrato, municipio, barcode, servicio, transacc, idTransaccion, monto, status,periodosPagados,fechaDePago,nombreContribuyente, OidContribuyente, filename, pageSize, orientation)
        .then((response) => {
          //console.log(response)
          window.open("https://pagoob.azurewebsites.net/print/" + filename + ".pdf", '_blank');
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    TotalMontoBimestres: async function () {

      const total = await api.calcularMonto(
        this.$route.params.Monto,
        this.$route.params.numContrato,
        this.$route.params.optionPay,
      );
      var datos = total;

      if(this.$route.params.optionPay == "3"){
        this.bimestresAPagar = datos[0].periodoString;
      }else {
        this.bimestresAPagar = datos[0].periodoString + " al " + datos[datos.length - 1].periodoString;
      }

      var totalPeriodos = 0;
      datos.forEach((element) => {
        totalPeriodos = totalPeriodos + element.Monto;
      });
      this.SubTotal = this.aMoneda(totalPeriodos);
      this.Comision = this.aMoneda(
        totalPeriodos * 0.029 + 2.5 + (totalPeriodos * 0.029 + 2.5) * 0.16
      );
      this.MontoTotal = this.aMoneda(
        totalPeriodos +
          (totalPeriodos * 0.029 + 2.5 + (totalPeriodos * 0.029 + 2.5) * 0.16)
      );
    },
    aMoneda: function (numero) {
      const opciones = {
        numeroDeDecimales: 2,
        separadorDecimal: ".",
        separadorMiles: ",",
        simbolo: "$ ", // Con un espacio, ya que la función no agrega espacios
        posicionSimbolo: "i", // i = izquierda, d = derecha
      };
      // Valores por defecto
      opciones.simbolo = opciones.simbolo || "$";
      opciones.separadorDecimal = opciones.separadorDecimal || ".";
      opciones.separadorMiles = opciones.separadorMiles || ",";
      opciones.numeroDeDecimales =
        opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
      opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
      const CIFRAS_MILES = 3;

      // Redondear y convertir a cadena
      let numeroComoCadena = numero.toFixed(opciones.numeroDeDecimales);

      // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
      let posicionDelSeparador = numeroComoCadena.indexOf(
        opciones.separadorDecimal
      );
      if (posicionDelSeparador === -1)
        posicionDelSeparador = numeroComoCadena.length;
      let formateadoSinDecimales = "",
        indice = posicionDelSeparador;
      // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
      while (indice >= 0) {
        let limiteInferior = indice - CIFRAS_MILES;
        // Agregar separador si cortamos más de 3
        formateadoSinDecimales =
          (limiteInferior > 0 ? opciones.separadorMiles : "") +
          numeroComoCadena.substring(limiteInferior, indice) +
          formateadoSinDecimales;
        indice -= CIFRAS_MILES;
      }
      let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(
        posicionDelSeparador,
        opciones.numeroDeDecimales + 1
      )}`;
      return opciones.posicionSimbolo === "i"
        ? opciones.simbolo + formateadoSinSimbolo
        : formateadoSinSimbolo + opciones.simbolo;
    },
  },

  mounted() {
    this.TotalMontoBimestres();
      if (!this.$session.exists()) {
        myRouter.push({ name: "QuickPagos" }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    } else {
      myRouter.push({ name: "homeview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
    this.tarjeta();
  },
  data() {
    var valorTotal = this.$route.params.Monto;

    return {
      optionPay: this.$route.params.optionPay,
      bimestresAPagar: 0,
      Comision: 0,
      SubTotal: 0,
      MontoTotal: 0,
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,
    };
  },
};

// ---- todas las funciones de la tarjeta!
</script>
<style>
.card {
  padding: 30px 25px 35px 50px;
  border-radius: 30px;
  box-shadow: 0px 4px 8px 0px #de6718;
  margin-top: 50px;
  margin-bottom: 50px;
}

.border-line {
  border-right: 1px solid #bdbdbd;
}

.text-sm {
  font-size: 13px;
}

.text-md {
  font-size: 18px;
}

.image {
  width: 60px;
  height: 30px;
}

::placeholder {
  color: grey;
  opacity: 1;
}

:-ms-input-placeholder {
  color: grey;
}

::-ms-input-placeholder {
  color: grey;
}

input {
  padding: 2px 0px;
  border: none;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 5px;
  margin-top: 2px;
  box-sizing: border-box;
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
}

input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ef5350;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

.btn-red {
  background-color: #ef5350;
  color: #fff;
  padding: 8px 25px;
  border-radius: 50px;
  font-size: 18px;
  letter-spacing: 2px;
  border: 2px solid #fff;
}

.btn-red:hover {
  box-shadow: 0 0 0 2px #ef5350;
}

.btn-red:focus {
  box-shadow: 0 0 0 2px #ef5350 !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ef5350;
}

@media screen and (max-width: 575px) {
  .border-line {
    border-right: none;
    border-bottom: 1px solid #eeeeee;
  }
}
</style>
