<!--Creacion del menu-->
<template>
<section>
<div>
 <!-- Bootstrap NavBar -->
<div class="row" id="body-row">
    <!-- Sidebar -->
    <div id="sidebar-container" class="sidebar-expanded d-none d-md-block fixed-top"><!-- d-* hiddens the Sidebar in smaller devices. Its itens can be kept on the Navbar 'Menu' -->
        <!-- Bootstrap List Group -->
         <li class="list-group-item logo-separator d-flex justify-content-center">
        <div class="corazon">
          <center>
     <img style="width:80%" src='../assets/logoP.svg'/>
     </center>
     </div>
</li>
<center>
<h1 style="color:#fff;"> <FONT FACE="arial" SIZE=2.5><i class="fas fa-user-circle mr-1"></i>{{Nombre}}</FONT></h1>
 </center>
        <ul class="list-group">
            <!-- Separator with title -->
            <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
                <small style="color:#ffad00;">Menu</small>
            </li>
            <router-link to="#/home" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fa fa-home fa-fw mr-3"></span>
                    <span class="menu-collapsed">Inicio</span>
                </div>
            </router-link>
            <router-link to="#/perfil" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fa fa-user fa-fw mr-3"></span>
                    <span class="menu-collapsed">Perfil</span>
                </div>
            </router-link>
            <router-link to="#/Servicios" class="bg-dark list-group-item list-group-item-action">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fa fa-tasks fa-fw mr-3"></span>
                    <span class="menu-collapsed">Servicios<span class="badge badge-pill badge-primary ml-2">{{Services}}</span></span>
                </div>
            </router-link>
             <router-link to="#/Contratos" class="bg-dark list-group-item list-group-item-action">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-file-alt fa-fw mr-3"></span>
                    <span class="menu-collapsed">Contratos</span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <!-- /END Separator -->
            <!-- Menu with submenu -->
            <!-- Separator with title -->
            <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
                <small style="color:#ffad00;">Opciones</small>
            </li>
            <!-- /END Separator -->
              <router-link to="#submenu1" data-toggle="collapse" aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-map-marker-alt fa-fw mr-3"></span>
                    <span class="menu-collapsed">Mapa</span>
                    <span class="submenu-icon ml-auto"></span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <div id='submenu1' class="collapse sidebar-submenu">
                <router-link to="#" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Charts</span>
                </router-link>
                <router-link to="#/Domicilios" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Direcciones</span>
                </router-link>
                <!--a href="#" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed">Tables</span>
                </a-->
            </div>
           <router-link to="#submenu2" data-toggle="collapse" aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="far fa-credit-card fa-fw mr-3"></span>
                    <span class="menu-collapsed">Pagos</span>
                    <span class="submenu-icon ml-auto"></span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <div id='submenu2' class="collapse sidebar-submenu">
                <router-link to="#/ProximoPago" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Proximo Pago</span>
                </router-link>
                <router-link to="#/HistorialPagos" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Historial De Pagos</span>
                </router-link>
            </div>
             <router-link to="#submenu3" data-toggle="collapse" aria-expanded="false" class="bg-dark list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span class="fas fa-cog fa-fw mr-3"></span>
                    <span class="menu-collapsed">Configuracion</span>
                    <span class="submenu-icon ml-auto"></span>
                </div>
            </router-link>
            <!-- Submenu content -->
            <div id='submenu3' class="collapse sidebar-submenu">
                <router-link to="#/ActualizaInformacion" class="list-group-item list-group-item-action bg-dark text-white">
                    <span class="menu-collapsed" style="color:#fff;">Actualizar Informacion</span>
                </router-link>
            </div>
            <!-- Separator without title -->
            <li class="list-group-item sidebar-separator menu-collapsed"></li>
            <!-- /END Separator -->
            <router-link v-on:click="Salir()" to="#" data-toggle="sidebar-colapse" class="bg-dark list-group-item list-group-item-action d-flex align-items-center">
                <div class="d-flex w-100 justify-content-start align-items-center" style="color:#fff;">
                    <span id="collapse-icon" class="fas fa-sign-out-alt mr-3"></span>
                    <span id="collapse-text" class="menu-collapsed">Salir</span>
                </div>
            </router-link>
            <!-- Logo -->
</ul><!-- List Group END-->
    </div><!-- sidebar-container END -->
    <!-- MAIN -->
 <nav class="navbar d-sm-block d-md-none navbar-dark" style="width: 100%;">

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon mr-1"></span>
  </button>
   <img class="corazon" style="width: 10%;min-width: 60px;float: right;" src='../assets/logoP.svg'/>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto d-sm-block d-md-none">
       <center>
  <li class="nav-item active">
 <router-link class="nav-link" to="#/home" style="color:#fff;"><i class="fas fa-home mr-1"></i>Inicio</router-link>
 </li>
  <hr size="2px" color="white" />
 <li class="nav-item active">
 <router-link class="nav-link" to="#/perfil" style="color:#fff;"><i class="fas fa-user mr-1"></i>Perfil</router-link>
 </li>
  <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/Servicios" style="color:#fff;"><i class="fas fa-cog mr-1"></i>Servicios</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/Contratos" style="color:#fff;"><i class="fas fa-file-alt mr-1"></i>Contratos</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/Domicilios" style="color:#fff;"><i class="fas fa-map-marker-alt mr-1"></i>Domicilios</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/ProximoPago" style="color:#fff;"><i class="fas fa-money-check-alt mr-1"></i>Proximo Pago</router-link>
 </li>
 <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/HistorialPagos" style="color:#fff;"><i class="fas fa-list-ol mr-1"></i>Historial De Pagos</router-link>
 </li>
  <hr size="2px" color="white" />
 <li class="nav-item">
<router-link class="nav-link" to="#/ActualizaInformacion" style="color:#fff;"><i class="fas fa-pen mr-1"></i>Actualizar Informacion</router-link>
 </li>
 <hr size="2px" color="white" />
<li class="nav-item">
        <router-link v-on:click="Salir()" class="nav-link " to="#" style="color:#fff;"><i class="fas fa-sign-out-alt mr-1"></i>Salir</router-link>
      </li>
      </center>
      <!-- This menu is hidden in bigger devices with d-sm-none. The sidebar isn't proper for smaller screens imo, so this dropdown menu can keep all the useful sidebar itens exclusively for smaller screens  -->
      </ul>
  </div>
</nav>

    <div class="col-sm-12 main">
      <!--menu responsive-->
        <h1 class="p" style="color:#fff;">
         <FONT FACE="arial" SIZE=6><i class="fas fa-pen"></i>
         Actualiza tu información</FONT>
            <small class="text-muted"></small>
        </h1>
        <hr size="2px" color="white" />
        <div class="card" style="background-color:rgba(179, 179, 179, 0.57);">
            <h4 class="card-header navbar-warning text-dark" style="background-color:#ff9e02;">Información</h4>
            <div class="card-body">
                <ul>
      <div class="row justify-content-md-center">
        <div class="col-md-10 order-md-1">
          <h4 class="mb-3" style="color:#FDFEFE;">Datos del usuario</h4>
          <div class="needs-validation" novalidate>
             <div id="formularioUser">
            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="firstName" style="color:#FDFEFE;">Nombre(s): </label>
                <input type="text" class="form-control" id="nombre" name="nombre" placeholder="" value="" required>
                <div class="invalid-feedback">
                  El Nombre es Requerido.
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="lastName" style="color:#FDFEFE;">Apellido Paterno: </label>
                <input type="text" class="form-control" id="apaterno" name="apaterno" placeholder="" value="" required>
                <div class="invalid-feedback">
                  El Apellido Paterno es Requerido.
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="lastName" style="color:#FDFEFE;">Apellido Materno: </label>
                <input type="text" class="form-control" id="amaterno" name="amaterno" placeholder="" value="" required>
                <div class="invalid-feedback">
                  El Apellido Materno es Requerido.
                </div>
              </div>
            </div>
            <div class="row">
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">RFC: </label>
              <input type="text" class="form-control" id="rfc" name="rfc" placeholder="RFC" required>
              <div class="invalid-feedback">
                Ppr favor ingresa tu RFC, es indispensable.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">Telefono: </label>
              <input type="text" class="form-control" id="telefono" name="telefono" placeholder="Telefono" required>
              <div class="invalid-feedback">
                Ppr favor ingresa tu Telefono, es indispensable.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">Razón Social: </label>
              <input type="text" class="form-control" id="razonsocial" name="rasocial" placeholder="Razon Social" required>
              <div class="invalid-feedback">
                Ppr favor ingresa la Razon Social, es indispensable.
              </div>
            </div>
            </div>
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <div class="mb-3">
              <label for="email" style="color:#FDFEFE;">Correo Electronico: </label>
              <input type="email" class="form-control" id="correo" name="correo" placeholder="tucorreo@ejemplo.com">
              <div class="invalid-feedback">
                Por favor ingresa un correo electronico valido.
              </div>
            </div>
             <div class="row">
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">Contraseña Actual: </label>
              <input type="password" class="form-control" id="passoword" name="password" placeholder="" required>
              <div class="invalid-feedback">
                Por favor ingresa tu password.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">Contraseña nueva: </label>
              <input type="password" class="form-control" id="newpassword" name="newpassword" placeholder="" required>
              <div class="invalid-feedback">
                Por favor ingresa tu nueva contraseña, es indispensable.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="rfc" style="color:#FDFEFE;">Repetir contraseña: </label>
              <input type="password" class="form-control" id="repassword" name="repassword" placeholder="" required>
              <div class="invalid-feedback">
                Por favor ingresa de nuevo la contraseña, es indispensable.
              </div>
            </div>
            </div>
            <!-- <hr class="mb-4">
            <h4 class="mb-3">Pago</h4>
            <div class="d-block my-3">
              <div class="custom-control custom-radio">
                <input id="credit" name="paymentMethod" type="radio" class="custom-control-input" checked required>
                <label class="custom-control-label" for="credit">Tarjeta de Credito</label>
              </div>
              <div class="custom-control custom-radio">
                <input id="debit" name="paymentMethod" type="radio" class="custom-control-input" required>
                <label class="custom-control-label" for="debit">Tarjeta de Débito</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="cc-name">Propietario:</label>
                <input type="text" class="form-control" id="cc-name" placeholder="" required>
                <small class="text-muted">Escribe el nombre completo del propietario</small>
                <div class="invalid-feedback">
                  El propietario es Requerido
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="cc-number">Número de tarjeta</label>
                <input type="text" class="form-control" id="cc-number" placeholder="" required>
                <div class="invalid-feedback">
                  Tu numero de tarjeta es Requerido
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 mb-3">
                <label for="cc-expiration">Vencimiento</label>
                <input type="text" class="form-control" id="cc-expiration" placeholder="" required>
                <div class="invalid-feedback">
                  Se Requiere Fecha de Vencimiento
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="cc-cvv">CVV</label>
                <input type="text" class="form-control" id="cc-cvv" placeholder="" required>
                <div class="invalid-feedback">
                  Se Requiere Código de Seguridad
                </div>
              </div>
            </div>
            -->
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <button v-on:click="actualizar()" id="registrar" class="btn btn-primary btn-lg btn-block">Actualizar</button>
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <hr class="mb-4" style="background-color:#FDFEFE;">
            <hr class="mb-4" style="background-color:#FDFEFE;">
          </div>
          </div>
        </div>
      </div>
                </ul>
            </div>
        </div>
    </div><!-- Main Col END -->
</div><!-- body-row END -->
</div>
</section>
</template>
<script>
import myRouter from '../router/index.js'
import * as api from '../router/api.js'
import axios from 'axios'
export default{
  name: 'ActualizaInformacionview',
  data () {
    return {
      Services: JSON.parse(localStorage.getItem('Servicios')).length,
      Nombre: localStorage.Nombre + ' ' + localStorage.Apellidos
    }
  },
  mounted () {
     this.init()
    // api.updateStorage();
    //console.log('Notifications component ready.')
    if (!this.$session.exists()) {
      myRouter.push({name: 'LoginView'}).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({name: 'ActualizaInformacionview'}).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },

  methods: {
    Salir: function () {
      this.$session.destroy()
      myRouter.push({name: 'LoginView'})
    },

    actualizar: function () {
      var Oid = localStorage.getItem('Oid')
      var Nombre = $('#nombre').val()
      var Apaterno = $('#apaterno').val()
      var Amaterno = $('#amaterno').val()
      var Correo = $('#correo').val()
      var Newpassword = $('#newpassword').val()
      var Rfc = $('#rfc').val()
      var Telefono = $('#telefono').val()
      var razonSocial = $('#razonsocial').val()

      if (Newpassword == $('#repassword').val()) {
        api.actualizaInformacion(Oid, Nombre, Apaterno, Amaterno, Correo, Newpassword, Telefono, Rfc, razonSocial).then(
          response => {
            if (response[0].DATA) {
              api.updateStorage().then(
                response => {
                  this.$swal(
                    'Ok!',
                    'Actualizacion Correcta.',
                    'success'
                  )
                  this.$session.destroy()
                  myRouter.push({name: 'LoginView'})
                }).catch(error => {
                //console.log('Error: ' + error + ' **UpdateStorage Servicio**')
              })
            } else {
              this.$swal('Puede que el correo ya existe o la nueva contraseña es igual a la actual!', 'Da click en el boton!', 'error')
            }
          }).catch(error => {
          //console.log(error)
          this.$swal('Usuario y/o contraseña invalida!', '', 'error')
        })
      } else {
        this.$swal('Las contraseñas no coinciden!', '', 'error')
      }
    },
      init () {
      $(function () {
      // Siempre que salgamos de un campo de texto, se chequeará esta función
        $('#registrar').prop('disabled', true)
        $('#formularioUser input').keyup(function () {
          var form = $(this).parents('#formularioUser')
          var check = checkCampos(form)
          if (check) {
            $('#registrar').prop('disabled', false)
          } else {
            $('#registrar').prop('disabled', true)
          }
        })

        function // Función para comprobar los campos de texto
        checkCampos (obj) {
          var camposRellenados = true
          obj.find('input').each(function () {
            var $this = $(this)
            if ($this.val().length <= 0) {
              camposRellenados = false
              return false
            }
          })
          if (camposRellenados) {
            return true
          } else {
            return false
          }
        }
      })
    }

  }
}
</script>
<style>
@media (min-width: 768px) {
    .main {
        padding-left: 240px; /* 180 + 40 */
    }
}
#body-row {
    margin-left:0;
    margin-right:0;
}
#sidebar-container {
    min-height: 115vh;
    background-color:#333;
    padding: 0;
}
/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
    width: 230px;
    height: 100%;
}
.sidebar-collapsed {
    width: 60px;
}
/* Menu item*/
#sidebar-container .list-group a {
    height: 50px;
    color:white;
}
/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
    height: 45px;
    padding-left: 30px;
}
.sidebar-submenu {
    font-size: 0.9rem;
}
/* Separators */
.sidebar-separator-title {
    background-color:#333;
    height: 35px;
}
.sidebar-separator {
    background-color:#333;
    height: 25px;
}
.logo-separator {
    background-color:#333;
    height: 60px;
}
/* Closed submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="false"] .submenu-icon::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}
/* Opened submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="true"] .submenu-icon::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}

/*TRASICION DEL TITULO*/
.p {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%
  }

  to {

  }
}

@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.1); }
    to { transform: none; }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
	display: inline-block;
	animation: latidos 4s infinite;
}
</style>
