<template>
  <div class="app">
    <div class="mobile-menu md:hidden">
      <div class="mobile-menu-bar">
        <router-link to="" class="flex mr-auto">
          <img alt="Pagoob" class="w-6" src="../assets/logoP.svg" />
        </router-link>
        <router-link to="javascript:;" id="mobile-menu-toggler">
          <i
            data-feather="bar-chart-2"
            class="w-8 h-8 text-white transform -rotate-90"
          ></i>
        </router-link>
      </div>
      <ul class="border-t border-theme-24 py-5 hidden">
        <li>
          <router-link to="side-menu-dark-dashboard.html" class="menu menu--active">
            <div class="menu__icon"><i data-feather="home"></i></div>
            <div class="menu__title">Dashboard</div>
          </router-link>
        </li>
        <li>
          <router-link to="javascript:;" class="menu">
            <div class="menu__icon"><i data-feather="box"></i></div>
            <div class="menu__title">
              Menu Layout
              <i data-feather="chevron-down" class="menu__sub-icon"></i>
            </div>
          </router-link>
          <ul class="">
            <li>
              <router-link to="side-menu-dark-dashboard.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Side Menu</div>
              </router-link>
            </li>
            <li>
              <router-link to="simple-menu-dark-dashboard.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Simple Menu</div>
              </router-link>
            </li>
            <li>
              <router-link to="top-menu-dark-dashboard.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Top Menu</div>
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="side-menu-dark-inbox.html" class="menu">
            <div class="menu__icon"><i data-feather="inbox"></i></div>
            <div class="menu__title">Inbox</div>
          </router-link>
        </li>
        <li>
          <router-link to="side-menu-dark-file-manager.html" class="menu">
            <div class="menu__icon"><i data-feather="hard-drive"></i></div>
            <div class="menu__title">File Manager</div>
          </router-link>
        </li>
        <li>
          <router-link to="side-menu-dark-point-of-sale.html" class="menu">
            <div class="menu__icon"><i data-feather="credit-card"></i></div>
            <div class="menu__title">Point of Sale</div>
          </router-link>
        </li>
        <li>
          <router-link to="side-menu-dark-chat.html" class="menu">
            <div class="menu__icon"><i data-feather="message-square"></i></div>
            <div class="menu__title">Chat</div>
          </router-link>
        </li>
        <li>
          <router-link to="side-menu-dark-post.html" class="menu">
            <div class="menu__icon"><i data-feather="file-text"></i></div>
            <div class="menu__title">Post</div>
          </router-link>
        </li>
        <li class="menu__devider my-6"></li>
        <li>
          <router-link to="javascript:;" class="menu">
            <div class="menu__icon"><i data-feather="edit"></i></div>
            <div class="menu__title">
              Crud <i data-feather="chevron-down" class="menu__sub-icon"></i>
            </div>
          </router-link>
          <ul class="">
            <li>
              <router-link to="side-menu-dark-crud-data-list.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Data List</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-crud-form.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Form</div>
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="javascript:;" class="menu">
            <div class="menu__icon"><i data-feather="users"></i></div>
            <div class="menu__title">
              Users <i data-feather="chevron-down" class="menu__sub-icon"></i>
            </div>
          </router-link>
          <ul class="">
            <li>
              <router-link to="side-menu-dark-users-layout-1.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Layout 1</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-users-layout-2.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Layout 2</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-users-layout-3.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Layout 3</div>
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="javascript:;" class="menu">
            <div class="menu__icon"><i data-feather="trello"></i></div>
            <div class="menu__title">
              Profile <i data-feather="chevron-down" class="menu__sub-icon"></i>
            </div>
          </router-link>
          <ul class="">
            <li>
              <router-link to="side-menu-dark-profile-overview-1.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Overview 1</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-profile-overview-2.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Overview 2</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-profile-overview-3.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Overview 3</div>
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="javascript:;" class="menu">
            <div class="menu__icon"><i data-feather="layout"></i></div>
            <div class="menu__title">
              Pages <i data-feather="chevron-down" class="menu__sub-icon"></i>
            </div>
          </router-link>
          <ul class="">
            <li>
              <router-link to="javascript:;" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">
                  Wizards
                  <i data-feather="chevron-down" class="menu__sub-icon"></i>
                </div>
              </router-link>
              <ul class="">
                <li>
                  <router-link to="side-menu-dark-wizard-layout-1.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 1</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="side-menu-dark-wizard-layout-2.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 2</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="side-menu-dark-wizard-layout-3.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 3</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="javascript:;" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">
                  Blog
                  <i data-feather="chevron-down" class="menu__sub-icon"></i>
                </div>
              </router-link>
              <ul class="">
                <li>
                  <router-link to="side-menu-dark-blog-layout-1.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 1</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="side-menu-dark-blog-layout-2.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 2</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="side-menu-dark-blog-layout-3.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 3</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="javascript:;" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">
                  Pricing
                  <i data-feather="chevron-down" class="menu__sub-icon"></i>
                </div>
              </router-link>
              <ul class="">
                <li>
                  <router-link to="side-menu-dark-pricing-layout-1.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 1</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="side-menu-dark-pricing-layout-2.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 2</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="javascript:;" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">
                  Invoice
                  <i data-feather="chevron-down" class="menu__sub-icon"></i>
                </div>
              </router-link>
              <ul class="">
                <li>
                  <router-link to="side-menu-dark-invoice-layout-1.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 1</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="side-menu-dark-invoice-layout-2.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 2</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="javascript:;" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">
                  FAQ <i data-feather="chevron-down" class="menu__sub-icon"></i>
                </div>
              </router-link>
              <ul class="">
                <li>
                  <router-link to="side-menu-dark-faq-layout-1.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 1</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="side-menu-dark-faq-layout-2.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 2</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="side-menu-dark-faq-layout-3.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Layout 3</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="login-dark-login.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Login</div>
              </router-link>
            </li>
            <li>
              <router-link to="login-dark-register.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Register</div>
              </router-link>
            </li>
            <li>
              <router-link to="main-dark-error-page.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Error Page</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-update-profile.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Update profile</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-change-password.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Change Password</div>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="menu__devider my-6"></li>
        <li>
          <router-link to="javascript:;" class="menu">
            <div class="menu__icon"><i data-feather="inbox"></i></div>
            <div class="menu__title">
              Components
              <i data-feather="chevron-down" class="menu__sub-icon"></i>
            </div>
          </router-link>
          <ul class="">
            <li>
              <router-link to="javascript:;" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">
                  Grid
                  <i data-feather="chevron-down" class="menu__sub-icon"></i>
                </div>
              </router-link>
              <ul class="">
                <li>
                  <router-link to="side-menu-dark-regular-table.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Regular Table</div>
                  </router-link>
                </li>
                <li>
                  <router-link to="side-menu-dark-tabulator.html" class="menu">
                    <div class="menu__icon"><i data-feather="zap"></i></div>
                    <div class="menu__title">Tabulator</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="side-menu-dark-accordion.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Accordion</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-button.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Button</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-modal.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Modal</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-alert.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Alert</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-progress-bar.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Progress Bar</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-tooltip.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Tooltip</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-dropdown.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Dropdown</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-toast.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Toast</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-typography.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Typography</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-icon.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Icon</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-loading-icon.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Loading Icon</div>
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="javascript:;" class="menu">
            <div class="menu__icon"><i data-feather="sidebar"></i></div>
            <div class="menu__title">
              Forms <i data-feather="chevron-down" class="menu__sub-icon"></i>
            </div>
          </router-link>
          <ul class="">
            <li>
              <router-link to="side-menu-dark-regular-form.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Regular Form</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-datepicker.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Datepicker</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-tail-select.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Tail Select</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-file-upload.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">File Upload</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-wysiwyg-editor.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Wysiwyg Editor</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-validation.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Validation</div>
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="javascript:;" class="menu">
            <div class="menu__icon"><i data-feather="hard-drive"></i></div>
            <div class="menu__title">
              Widgets <i data-feather="chevron-down" class="menu__sub-icon"></i>
            </div>
          </router-link>
          <ul class="">
            <li>
              <router-link to="side-menu-dark-chart.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Chart</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-slider.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Slider</div>
              </router-link>
            </li>
            <li>
              <router-link to="side-menu-dark-image-zoom.html" class="menu">
                <div class="menu__icon"><i data-feather="activity"></i></div>
                <div class="menu__title">Image Zoom</div>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="flex col-sm-12">
      <div class="side-nav">
        <router-link to="" class="intro-x flex items-center pl-5 pt-4">
          <img alt="Pagoob" class="w-60" src="../assets/logoP.svg" />
        </router-link>
        <center>
          <h1 style="color: #fff">
            <FONT FACE="arial" SIZE="2.5"
              ><i class="fas fa-user-circle mr-1"></i>{{ Nombre }}</FONT
            >
          </h1>
        </center>
        <div class="side-nav__devider my-6"></div>
        <ul>
          <li v-if="Rol != '1'">
            <router-link to="/ConsultarPagar" id="navProximo" class="side-menu">
              <div class="side-menu__icon">
                <i data-feather="credit-card"></i>
              </div>
              <div class="side-menu__title">Consulta y Pago</div>
            </router-link>
          </li>
          <li v-if="Rol != '1'">
            <router-link to="/Servicios" id="navServicios" class="side-menu">
              <div class="side-menu__icon">
                <i data-feather="search"></i>
              </div>
              <div class="side-menu__title">Servicios</div>
            </router-link>
          </li>
          <!-- <li v-if="Rol != '1'">
            <router-link to="/HistorialPagos" id="navHistorial" class="side-menu">
              <div class="side-menu__icon">
                <i data-feather="folder"></i>
              </div>
              <div class="side-menu__title">Historial de Pagos</div>
            </router-link>
          </li> -->
          <li v-if="Rol != '1'">
            <router-link to="/Perfil" id="navPerfil" class="side-menu">
              <div class="side-menu__icon">
                <i data-feather="user"></i>
              </div>
              <div class="side-menu__title">Perfil</div>
            </router-link>
          </li>
          <li class="side-nav__devider my-6"></li>
          
          <li>
            <div v-on:click="Salir()" to="" class="side-menu" style="cursor:pointer">
              <div class="side-menu__icon">
                <i data-feather="log-out"></i>
              </div>
              <div class="side-menu__title">Cerrar Sesión</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="content">
        <div class="top-bar">
          <div class="-intro-x mr-auto hidden sm:flex">
            <router-link to="/" class=""></router-link>
              <i data-feather="chevron-right" class="breadcrumb__icon"></i>
            <router-link to="" class="breadcrumb--active">{{ ruta }}</router-link>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-6">

          <div
            class="col-span-12 xxl:col-span-12 xxl:border-l border-theme-5 -mb-10 pb-10"
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myRouter from "../router/index.js";
import $ from 'jquery';
import { updateStorage } from '../router/api.js';
export default {
  name: "Menu",
  // methods: {
  //   Salir: function () {
  //     alert("dasda");
  //     this.$session.destroy();
  //     myRouter.push({ name: "LoginView" });
  //   },
  // },
  data() {
    return {
      ruta: this.$route.matched[0].props.default.pathURL,
      Nombre: (
        localStorage.Nombre +
        " " +
        localStorage.Apellidos
      ).toUpperCase(),
      Apellidos: localStorage.Apellidos,
      Correo: localStorage.Correo,
      Rfc: localStorage.Rfc,
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Rol: JSON.parse(localStorage.Usuario)[0].rol,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      updateStorage();
      //console.log(this.$route);
      const actual = this.$route.fullPath;
      if (actual == "/ProximoPago") {
        $("#navProximo").addClass("side-menu--active");
      } else if (actual == "/Home") {
        $("#navHome").addClass("side-menu--active");
      } else if (actual == "/ConsultaPagar") {
        $("#navHome").addClass("side-menu--active");
      } else if (actual == "/HistorialPagos") {
        $("#navHistorial").addClass("side-menu--active");
      } else if (actual == "/Proximo") {
        $("#navProximo").addClass("side-menu--active");
      } else if (actual == "/Servicios") {
        $("#navServicios").addClass("side-menu--active");
      } else if (actual == "/Perfil") {
        $("#navPerfil").addClass("side-menu--active");
      } else if (actual == "/Contratos") {
        $("#navContratos").addClass("side-menu--active");
      } else if (actual == "/Admin") {
        $("#navHome").addClass("side-menu--active");
      } else if (actual == "/Consultas") {
        $("#navConsultas").addClass("side-menu--active");
      } else if (actual == "/Comprobantes") {
        $("#navComprobantes").addClass("side-menu--active");
      } else if (actual == "/Configuracion") {
        $("#navConfiguracion").addClass("side-menu--active");
      }  else if (actual == "/PagoPuenteIxtla") {
        $("#navConfiguracion").addClass("side-menu--active");
      }
    },

    Salir() {
      this.$session.destroy();
      myRouter.push({ name: "LoginView" });
    },
  },
  created() {

  },
};
</script>

<style>
</style>

