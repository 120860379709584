<!--Creacion del menu-->
<template>
  <section>
    <Menu>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-1">
        <h2 class="text-lg font-medium mr-auto">Servicios</h2>
        <div class="items-center w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
            v-on:click="createPDF()"
            class="button text-white bg-theme-1 shadow-md mr-2"
          >
            Imprimir
          </button>
        </div>
      </div>
      <div id="imprimible" class="printable formatoPagoOrgAux">
        <!-- Encabezado -->
        <div id="headerPage" class="encabezado section">
          <div class="secretaria">
            <div class="logos">
              <img
                src="https://i.ibb.co/d7rNbCS/Logo-PDI-02.jpg"
                class="imgEdo"
              />
            </div>
          </div>
          <div class="gobierno">
            <div class="logos">
              <img
                src="https://www.casesconsorcio.com/fotos/pagob9.png"
                class="imgOrgAux"
              /><img
                src="https://www.casesconsorcio.com/fotos/pagob9.png"
                class="imgGob"
              />
            </div>
          </div>
        </div>

        <div id="bodyPage">
          <div
            style="color: gray; text-align: center; color: black"
          >
            <label class="lblSecretaria" style="font-weight:bold">COMPROBANTE DE PAGO</label>
            <div class="fechas">
            </div>
          </div>
          <br />

          <!-- Datos del Contribuyente -->
          <div
            class="datosContribuyente section"
            style="margin: 0px auto 0px auto"
          >
            <div
              class="fechas"
              style="
                text-align: right;
                font-size: 12px;
                font-weight: bold;
                color: black;
              "
            >
              <p>
                <label style="color: gray" class="nombre">Fecha de Pago:</label>
                <label class="valor" style="color: gray">14 de junio de 2019</label>
              </p>
            </div>
            <label class="title">DATOS DEL CONTRIBUYENTE</label>
            <!-- Campos B&aacute;sicos del Contribuyente -->
            <div class="banda">
              <div class="valores">
                <label class="campoRFC">HEGE810919TS6</label
                ><label class="campoCURP">HEGE810918HMCRYR28</label
                ><label class="campoControl">1</label>
              </div>
              <img
                id="j_idt54"
                width="620"
                height="0"
                alt=""
                src="/recaudacion/faces/javax.faces.resource/spacer/dot_clear.gif?ln=primefaces&amp;v=5.2"
              />
              <div class="nombres">
                <label class="campoRFC">RFC</label>
                <label class="campoCURP">CURP</label>
                <label class="campoControl">NO. CONTROL</label>
              </div>
              <img
                id="j_idt59"
                width="620"
                height="0"
                alt=""
                src="/recaudacion/faces/javax.faces.resource/spacer/dot_clear.gif?ln=primefaces&amp;v=5.2"
              />
              <div class="valores">
                <label class="campoRFC">SSS SSSS SSSS</label>
                <label class="campoCURP">SIN OBSERVACIONES</label>
                <label class="campoControl"></label>
              </div>
              <img
                id="j_idt63"
                width="620"
                height="0"
                alt=""
                src="/recaudacion/faces/javax.faces.resource/spacer/dot_clear.gif?ln=primefaces&amp;v=5.2"
              />
              <div class="nombres">
                <label class="campoRFC">NOMBRE, DENOMINACIÓN O RAZÓN SOCIAL</label>
                <label class="campoCURP">OBSERVACIONES</label>
                <label class="campoControl"></label>
              </div>
            </div>
            <img
              id="j_idt87"
              width="620"
              height="0"
              alt=""
              src="/recaudacion/faces/javax.faces.resource/spacer/dot_clear.gif?ln=primefaces&amp;v=5.2"
            />
            <!-- Campos Especiales del Contribuyente --><img
              id="j_idt90"
              width="620"
              height="0"
              alt=""
              src="/recaudacion/faces/javax.faces.resource/spacer/dot_clear.gif?ln=primefaces&amp;v=5.2"
            />
          </div>

          <!-- Datos de la Contribuci&oacute;n -->
          <div class="datosContribucion group section">
            <label class="title">DATOS DE LA CONTRIBUCIÓN</label>
            <div class="headers">
              <label class="clave">CLAVE</label>
              <label class="descripcion">DESCRIPCIÓN</label>
              <label class="cantidad">CANTIDAD</label>
              <label class="tarifa">TARIFA O TASA</label>
              <label class="importe">SUBTOTAL</label>
            </div>
            <table class="" style="color: black; width: 100%">
              <tbody>
                <tr>
                  <td class="clave"><label>975299</label></td>
                  <td class="descripcion">
                    <label>Cuota de inscripción cuatrimestral</label>
                  </td>
                  <td class="cantidad"><label>1 </label></td>
                  <td class="tarifa"><label>$ 2411.0</label></td>
                  <td class="importe"><label>$ 2,411 </label></td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Datos del Pago -->
          <div class="datosPago section">
            <div class="barcode">
              <barcode
                v-bind:value="barcodeValue"
                width="1"
                format="CODE39"
                background="#F6F6F6"
                font-size="12"
                tag="svg"
              >
              </barcode>
            </div>
            <img
              id="j_idt44"
              width="630"
              height="0"
              alt=""
              src="/recaudacion/faces/javax.faces.resource/spacer/dot_clear.gif?ln=primefaces&amp;v=5.2"
            />
          </div>

          <label class="validez section"
            >ESTE DOCUMENTO ES EL COMPROBANTE DE PAGO, SÓLO ES VÁLIDO SI EL
            FORMATO ES EMITIDO POR LA PLATAFORMA PAGOOB.</label
          >
          <br />
          <br />
        </div>
      </div>
    </Menu>
  </section>
</template>
<script>
import Menu from "../components/Menu.vue";
import myRouter from "../router/index.js";
import * as api from "../router/api.js";
import VueBarcode from "vue-barcode";
import * as $ from 'jquery';

export default {
  name: "ComprobantePagoview",
  components: {
    barcode: VueBarcode,
    Menu,
  },
  props: {
    title: String,
  },
  data() {
    return {
      barcodeValue: this.$route.params.Oid,
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,
    };
  },
  mounted() {
    this.init();
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "ComprobantePagoview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  methods: {
    createPDF: function () {
      var numContrato = "1";
      var html = $(".printable").html();
      var pheaderPage = "<p></p>";
      var pfooterPage = "<p></p>";
      var watermark = "watermark";
      var filename = "comprobante_prueba";
      var pageSize = "letter";
      var orientation = "portrait";
      api
        .createPDF(numContrato, html, pheaderPage, pfooterPage, filename, watermark, pageSize, orientation)
        .then((response) => {
          //console.log(response)
          window.open("https://pagoob.azurewebsites.net/print/" + filename + ".pdf", '_blank');
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    init() {
      $(function () {
        // Siempre que salgamos de un campo de texto, se chequeará esta función
      });
    },
  },
};
</script>
<style>
/*############################## BotÃ³n Imprimir ##############################*/

.imprimir {
  width: 680px;
  text-align: right;
}
@media print {
  body {
    font-family: Arial, Helvetica, sans-serif;
  }
  .imprimir {
    display: none;
  }
  #imprimir {
    display: none;
  }
  .formatoPagoOrgAux {
    background-color: white;
  }
}

.group:after {
  content: "";
  display: table;
  clear: both;
}
/*################################### Body ###################################*/
body {
  background-color: White;
  color: black;
  padding: 0px;
  margin: 0px;
}

/*############################# Mensaje de Error #############################*/
.mensajeError {
  width: 620px;
  text-align: center;
  font-size: 18px;
  color: red;
  margin-top: 20px;
  display: block;
}

/*############################# Formato de Pago ##############################*/
.formatoPagoOrgAux {
  width: 681px;
  text-align: left;
  background-color: #f6f6f6;
  border: none;
  padding: 0px;
  margin: 0px auto 0px auto;
}

/*################################## SecciÃ³n #################################*/
.section {
  width: 640px;
  margin: 50px auto 0px auto;
}
.section .title {
  color: black;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-left: 25px;
  margin-bottom: 15px;
  display: block;
}
.section .banda {
  min-height: 32px;
  font-size: 12px;
}
.section .valores {
  min-height: 16px;
  line-height: 16px;
  font-size: 14px;
  color: black;
}
.section .nombres {
  height: 16px;
  line-height: 12px;
  font-size: 11px;
  font-weight: bold;
  color: black;
}
.section .nombres:after {
  width: 620px;
  content: " ";
  margin-bottom: 9px;
  display: block;
  float: left;
  clear: left;
}

/*############################# SecciÃ³n Encabezado ###########################*/
.encabezado {
  width: 630px;
  height: 20px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.encabezado .secretaria {
  width: 300px;
  float: left;
}
.encabezado .secretaria label {
  color: black;
  width: 300px;
  font-weight: bold;
  text-align: center;
}
.encabezado .secretaria .lblSecretaria {
  font-size: 14px;
}
.encabezado .secretaria .logos img {
  height: 90px;
}
.encabezado .gobierno {
  width: 330px;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  float: left;
}
.encabezado .logos {
  height: 110px;
}
.encabezado .gobierno .logos img {
  float: right;
}
.encabezado .gobierno label {
  font-size: 19px;
  font-weight: bold;
}
.encabezado img,
.encabezado label {
  display: block;
}
.encabezado img {
  padding: 5px 0px 5px 0px;
}
.encabezado .imgGob {
  height: 90px;
  margin-left: 20px;
  border-right: 2px solid gray;
  padding-right: 5px;
  margin-right: 5px;
}
.encabezado .imgOrgAux {
  max-width: 120px;
  max-height: 90px;
}

/*############################ SecciÃ³n Datos Pago ############################*/
.datosPago {
  width: 630px;
  font-size: 12px;
  margin-bottom: 10px;
}
.datosPago .nombre,
.datosPago .valor {
  line-height: 16px;
  display: block;
  float: left;
}
.datosPago .nombre {
  color: gray;
}
.datosPago .valor {
  color: black;
}
.datosPago .barcode {
  width: 350px;
  text-align: left;
  float: left;
}
.datosPago .barcode .nombre {
  width: 80px;
  padding-left: 10px;
}
.datosPago .barcode .valor {
  width: 260px;
}
.datosPago .barcode img {
  width: 300px;
  height: 30px;
  margin: 5px auto 0px 0px;
  display: block;
  float: left;
}
.datosPago .barcode .lineaCaptura {
  color: black;
  width: 300px;
  text-align: center;
  font-weight: bold;
  display: block;
}
.datosPago .barcode .referencia {
  color: black;
  width: 300px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  margin: 5px auto 0px 0px;
  display: block;
  float: left;
}
.datosPago .barcode .sinespacios {
  color: grey;
  width: 300px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 5px auto 0px 0px;
  display: block;
  float: left;
}
.datosPago .fechas {
  width: 280px;
  /*padding: 35px 0px 0px 20px;*/
  padding-top: 18px;
  float: left;
}
.datosPago .fechas .nombre {
  width: 130px;
}
.datosPago .fechas .valor {
  width: 150px;
}
.datosPago .total {
  width: 630px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  color: black;
  /*background-color: #CDCDCD;*/
  margin: 0px auto 0px auto;
  float: left;
}
.datosPago .total label {
  width: auto;
  height: 25px;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  display: block;
  float: right;
  clear: none;
}
.datosPago .total .nombre.total {
  padding-right: 10px;
}
.datosPago .total .valor.total {
  padding-right: 20px;
}

/*##################### SecciÃ³n Datos del Contribuyente ######################*/
.datosContribucion {
  border-bottom: 1px solid black;
}
.datosContribuyente.section {
  margin-bottom: 10px;
}
.datosContribuyente .banda {
  width: 620px;
  margin-left: auto;
  margin-right: auto;
}
.datosContribuyente .campoNombre,
.datosContribuyente .campoObservaciones {
  width: 45%;
}
.datosContribuyente .campoRFC {
  width: 38%;
}
.datosContribuyente .campoCURP {
  width: 40%;
}
.datosContribuyente .campoDireccion {
  width: 195px;
}
.datosContribuyente .campoColonia,
.datosContribuyente .campoLocalidad {
  width: 155px;
}
.datosContribuyente .campoMunicipio {
  width: 115px;
}
.datosContribuyente .campoEstado {
  width: 195px;
}
.datosContribuyente .banda label {
  padding: 0px;
  margin: 0px;
  display: block;
  float: left;
}
.datosContribuyente .banda label.campoNombre,
.datosContribuyente .banda label.campoObservaciones {
  padding-right: 5%;
}
.datosContribuyente .camposEspeciales {
  width: 620px;
  text-align: left;
  background-color: white;
  border: none;
  padding: 0px;
  margin: 0px auto 0px auto;
  display: block;
}

/*##################### SecciÃ³n Datos de la ContribuciÃ³n #####################*/
.datosContribucion .title {
  margin-bottom: 5px;
}
.datosContribucion .headers {
  width: 620px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin: 0px auto 0px auto;
}
.datosContribucion .content {
  width: 620px;
  margin: 0px auto 0px auto;
  padding: 0px;
}
.datosContribucion tr {
  height: 16px;
  line-height: 16px;
  font-size: 12px;
}
.datosContribucion .headers label {
  text-align: center;
  display: block;
  float: left;
}
.datosContribucion .clave {
  width: 80px;
}
.datosContribucion .descripcion {
  width: 240px;
}
.datosContribucion .cantidad {
  width: 90px;
}
.datosContribucion .tarifa {
  width: 120px;
}
.datosContribucion .importe {
  width: 90px;
}

.datosContribucion tr .clave {
  width: 80px;
  padding: 0px;
  text-align: center;
}
.datosContribucion tr .descripcion {
  width: 225px;
  padding: 0px 0px 0px 15px;
}
.datosContribucion tr .cantidad {
  width: 60px;
  padding: 0px 30px 0px 0px;
}
.datosContribucion tr .tarifa {
  width: 80px;
  padding: 0px 40px 0px 0px;
}
.datosContribucion tr .importe {
  width: 65px;
  padding: 0px 25px 0px 0px;
}

.datosContribucion tr .descripcion {
  text-align: left;
}
.datosContribucion tr .cantidad,
.datosContribucion tr .tarifa,
.datosContribucion tr .importe {
  text-align: center;
}

.datosContribucion .subtotal,
.datosContribucion .iva,
.datosContribucion .total {
  width: auto;
  font-weight: bold;
  margin: 5px auto 0px auto;
  float: right;
}
.datosContribucion .subtotal label,
.datosContribucion .iva label,
.datosContribucion .total label {
  width: auto;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  color: black;
  text-align: right;
  display: block;
  float: right;
}
.datosContribucion .total .lblTotal {
  padding-right: 10px;
}
.datosContribucion .total .lblValor {
  padding-right: 36px;
}
/* //.datosContribucion .subtotal label{background-color: #EAEAEA;}
//.datosContribucion .iva label{background-color: #DCDCDC;}
//.datosContribucion .total label{background-color: #CDCDCD;} */
.datosContribucion .total .leyenda {
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  margin: 0px auto 0px auto;
  padding-right: 18px;
  display: block;
  clear: both;
}

.datosContribucion .total .leyendaIVA {
  width: 158px;
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  color: black;
  text-align: right;
  background-color: #fff;
  padding-right: 220px;
  display: block;
  float: right;
}

/*######################### SecciÃ³n Datos de EmisiÃ³n #########################*/
.emision {
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  text-align: center;
  margin-bottom: 15px;
}
.emision .nombre {
  color: gray;
}
.emision .valor {
  color: black;
}

/*############################################################################*/
/*# SecciÃ³n Validez, Verificar, Fundamento, ContribuciÃ³n y Juntos del Formato */
/*############################################################################*/
.validez,
.verificar,
.fundamento,
.contribucion,
.juntos {
  width: 620px;
  line-height: normal;
  font-size: 11px;
  color: gray;
  text-align: justify;
  margin: 10px auto 10px auto;
  display: block;
}
.validez {
  margin: 20px auto 20px auto;
}
.verificar {
  font-size: 10px;
  color: black;
}
.contribucion,
.juntos {
  font-weight: bold;
  color: black;
  text-align: center;
  margin-bottom: 0px;
}
.juntos {
  font-size: 13px;
  margin-top: 5px;
}

/*############################# SecciÃ³n de Bancos ############################*/
.bancos {
  width: 620px;
  text-align: center;
  border: none;
  padding: 0px;
  margin: 10px auto 0px auto;
  display: block;
}
.bancos label {
  width: 155px;
  line-height: 11px;
  font-size: 11px;
  color: gray;
  margin-bottom: 5px;
  display: block;
  float: left;
}
.bancos .leyenda {
  width: 620px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  margin: 0px auto 15px auto;
}

/* INICIO ESTILOS GENERALES */

/* INICIO MENSAJE DE ERROR */

.ui-widget {
  font-family: HelveticaCondensed !important;
}

.ui-growl-message {
  font-size: 16px;
}

.ui-growl-item-container .ui-growl-title {
  display: none;
}

/* FIN MENSAJE DE ERROR */

.ui-panelgrid-content {
  border: none;
}

#formulario {
  max-width: 1090px;
  min-height: 550px;
  margin: 0 auto auto auto;
}

/* FIN ESTILOS GENERALES */

/* INICIO PLECA DEL PROCESO */

#pleca-proceso-b {
  width: 100%;
  height: 50px;
  margin: 30px 0px 30px 0px;
  display: inline-table;
}

#pleca-proceso-logo-b {
  height: 65px;
  width: 70px;
  margin: 0px;
  float: left;
}

#pleca-proceso-subtitulo-b {
  color: #44466d;
  font-size: 15px;
  margin-bottom: 8px;
}

#pleca-proceso-titulo-b {
  color: #44466d;
  font-size: 30px;
}

/* FIN PLECA DEL PROCESO */

/* INICIO PLECA DEL PROCESO OPCION 2*/

#pleca-proceso-c {
  max-width: 960px;
  height: 18px;
  overflow: hidden;
  margin: 0 auto;
}

.seccion {
  width: 33.3%;
  height: 18px;
  color: #000;
  background-color: #d3d9de;
  text-align: center;
  float: left;
}

.seccion.active {
  background-color: #5f6090;
}

/* FIN PLECA DEL PROCESO OPCION 2*/

/* INICIO PASOS FORMULARIO */

.pasosFormulario {
  width: 960px;
  height: 55px;
  border: 0px;
  margin: 0 auto;
}

/* FIN PASOS FORMULARIO */

.textoAvisoObligatorio {
  width: 765px;
  margin: 8px auto 0px auto;
  font-size: 12px;
  font-style: italic;
}

/* INICIA FORM DE INICIO */

.form-inicio {
  width: 100%;
}

.form-inicio p {
  margin: 0px 0px 15px 0px;
  font-size: 12px;
  text-align: justify;
}

.form-inicio-content {
  margin: 5% 8% 5% 15%;
}

.inputPrincipal {
  text-align: center;
  width: 200px;
  display: block;
  margin: 23px 0;
}

/* FIN FORM DE INICIO */

/* INICIO PLECA DE AVISOS */

#pleca-avisos {
  display: table;
  width: 960px;
  min-height: 50px;
  margin: 0 auto;
}

#pleca-avisos-titulo {
  display: inline-block;
  /*vertical-align: middle;*/
  width: 33.22%;
  border: 0px;
  /*background: #4e5760;*/
  color: #fefefe;
  font-weight: normal;
  text-align: center;
  background-color: #7d7eb5;
}

#pleca-avisos-titulo img {
  vertical-align: middle;
}

#pleca-avisos-contenido {
  display: table;
  width: 66.78%;
  border: 0px;
  background: #edeeef;
  color: black;
  float: right;
  min-height: 50px;
}

#aviso-parrafo {
  display: table-cell;
  vertical-align: middle;
  width: 80%;
  font-size: 11px;
  text-align: justify;
  margin: 0;
}

#aviso-parrafo-completo {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  font-size: 11px;
  text-align: justify;
  margin: 0;
}

#aviso-parrafo p {
  margin: 10px;
}

#aviso-parrafo-completo p {
  margin: 10px;
}

.plecaLeyenda {
  max-width: 960px;
  margin: 0 auto;
}

.leyendaPrivacidad {
  background: #87597e;
  color: white;
  font-weight: normal;
  border-radius: 5px;
  padding: 10px;
  width: 64.7%;
  margin-right: 0px;
  margin-left: auto;
}

.plecaSMS {
  max-width: 960px;
  margin: 0 auto;
}

.leyendaSMS {
  background: #5f6090;
  color: white;
  font-weight: normal;
  border-radius: 5px;
  width: 66.78%;
  margin-right: 0px;
  margin-left: auto;
  font-size: 13px;
  position: relative;
  text-align: justify;
}

.leyendaSMS p {
  padding: 10px 65px 10px 10px;
}

.linkCAPS {
  color: black;
  text-decoration: underline;
}

.linkCAPS:hover {
  text-decoration: none;
}

.white2 {
  color: #ffffff;
  text-decoration: none;
  font-size: 13px;
}

.white2:hover {
  text-decoration: underline;
}

#aviso-contenido-icono {
  display: table-cell;
  vertical-align: middle;
  width: 20%;
  text-align: center;
  font-size: 11px;
  margin-top: 10px;
}

#aviso-contenido-icono a {
  color: #010101;
  text-decoration: none;
}

#aviso-contenido-icono img {
  max-width: 30px;
  height: 30px;
  border: 0px;
}

/* FIN PLECA DE AVISOS */

/* INICIO PLECA LINK */

#pleca-link {
  margin: 2px 0px 2px 0px;
  display: none;
  height: 42px;
}

#pleca-link-contenido {
  max-width: 100%;
  height: 42px;
}

#pleca-link-titulo {
  width: 100%;
  height: 100%;
  background: #95969a;
  color: white;
  font-size: 20px;
  border-radius: 12px 12px 12px 12px;
  display: table-cell;
  vertical-align: middle;
}

#pleca-link a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fefefe;
  display: table;
  text-align: center;
  vertical-align: middle;
}

/* FIN PLECA LINK */

/* INICIO PLECA LINK 2*/

#pleca-link-2 {
  margin: 2px 0px 2px 0px;
  display: none;
  height: 42px;
}

#pleca-link-contenido-2 {
  max-width: 100%;
  height: 42px;
}

#pleca-link-titulo-2 {
  width: 100%;
  height: 100%;
  background: #87597e;
  color: white;
  font-size: 20px;
  border-radius: 12px 12px 12px 12px;
  display: table-cell;
  vertical-align: middle;
}

#pleca-link-2 a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fefefe;
  display: table;
  text-align: center;
  vertical-align: middle;
}

/* FIN PLECA LINK 2*/

/* INICIO SECCIONES FORMULARIO*/

.ui-panelgrid-cell.ui-grid-col-2.border-right {
  border-right: 1px #aaa solid;
}

.etiquetaSeccion {
  width: 100%;
  height: 100%;
  min-height: 45px;
  font-size: 14px;
  color: #555;
}

.etiquetaSeccion div {
  float: left;
}

.etiquetaSeccion div:first-child {
  width: 45px;
}

.etiquetaSeccion div:last-child {
  width: 87px;
  padding: 10px 0px 0px 6px;
}
.etiquetaSeccion img {
  width: 45px;
  height: 45px;
  margin-top: -2px;
}

.icono-1 {
  width: 45px;
  height: 45px;
  margin-top: -2px;
}

.icono-2 {
  width: 45px;
  height: 45px;
  margin-top: -2px;
  display: none;
}

.panelDatos > div,
.panelDomicilio > div {
  min-width: 185px;
  margin: 5px 0px 5px 0px;
}

/* FIN SECCIONES FORMULARIO*/

/* INICIA TABLA DATOS*/

.tabla-datos {
  font-size: 12px;
  max-width: 960px;
  margin: 0 auto;
}

.tabla-datos label {
  font-size: 1em;
}

.columna-izquierda-datos {
  min-width: 260px;
}

.columna-derecha-datos {
  font-weight: bold;
}

.columna-datos-bg {
  background: #ebeeef;
}

.columna-datos-bg-dark {
  background: #c8c8c8;
}

.separador {
  max-width: 960px;
}

/* FIN DATOS DEL PREDIO*/

/* INICIO TABLA DE ADEUDOS */

.tabla-dinamica {
  width: 100%;
  font-size: 12px;
}

.tabla-dinamica-columna span {
  color: black;
  font-weight: bold;
}

.tabla-dinamica-columna {
  text-align: center;
}

.tabla-dinamica-columna.expan {
  text-align: left;
  width: 15px;
}

.tabla-dinamica-footer {
  color: black;
  font-weight: bold;
  text-align: left;
}

.highlight {
  background: white;
}

.highlight2 {
  background: #edeeef;
}

.subtabla td:nth-child(1) {
  width: 170px;
}

.periodo-numero {
  display: none;
}

/* FIN TABLA DE ADEUDOS */

/*INICIO BOTONES */

.botones {
  text-align: center;
  font-size: 16px;
  padding-top: 9px;
}

/* FIN BOTONES */

/* INICIO TABLA DE ACTUALIZACION DE DATOS DEL PROPIETARIO */

.tabla-datos-B {
  font-size: 12px;
  max-width: 960px;
  margin: 0 auto;
}

.columna-tabla-datos-B {
  margin: 5px 0px 5px 0px;
}

.input {
  width: 94%;
}

.input2 {
  width: 86%;
}

.combo {
  width: 96% !important;
}

.combo label {
  width: 96% !important;
}

.ui-selectonemenu-items-wrapper {
  font-size: 13px;
}

/* FIN TABLA DE ACTUALIZACION DE DATOS DEL PROPIETARIO */

/*INICIO MAPA DE CONVENIOS */

.mapaMunicipios {
  margin: 0 auto;
  width: 100%;
  height: 400px;
}

/*FIN MAPA DE CONVENIOS */

/* INICIO BLOQUEADOR DE PANTALLA PARA PAGINAS JSP */

.bloqueo-pantalla {
  display: none;
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #aaa;
  opacity: 0.3;
  filter: Alpha(Opacity=30);
}

.bloqueo-mensaje {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 251.41px;
  max-height: 51.8px;
  z-index: 1003;
  display: none;
  margin: auto;

  -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.8);

  position: fixed;
  text-align: center;
  padding: 1em;

  border-radius: 4px;

  border: 1px solid #aaa;
  background: #fff;
  font-size: 17.6px;
}

/* FIN BLOQUEADOR DE PANTALLA PARA PAGINAS JSP */

/*******************/

@media only screen and (max-width: 960px) {
  #formulario {
    width: 100%;
  }

  .pasosFormulario {
    width: 100%;
  }

  .leyendaPrivacidad {
    padding: 9px;
  }

  #pleca-avisos {
    width: 100%;
  }
}

@media only screen and (max-width: 787px) {
  .columna-datos-bg {
    width: 100%;
  }

  .columna-derecha-datos {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 768px) {
  #aviso-parrafo {
    width: 100%;
  }

  .linkCAPS {
    display: none;
  }

  #aviso-contenido-icono {
    display: none;
  }

  .plecaLeyenda {
    display: none;
  }

  #pleca-link {
    display: block;
  }

  #pleca-link-2 {
    display: block;
  }
}

@media only screen and (max-width: 685px) {
  .form-inicio-content {
    margin: 5% 2% 2% 2%;
  }
}

@media only screen and (max-width: 560px) {
  .form-inicio p {
    margin: 15px 0px 0px 0px;
  }

  .form-inicio-content {
    text-align: center;
  }

  .form-inicio-content input {
    width: 98%;
    margin: 23px auto;
  }

  .form-inicio-content button {
    width: 100%;
  }

  #pleca-avisos-titulo {
    width: 100%;
  }

  #pleca-avisos-contenido {
    width: 100%;
  }

  .plecaSMS {
    width: 100%;
    margin-top: 2px;
  }

  .leyendaSMS {
    width: 100%;
    min-height: 65px;
    text-align: center;
  }

  .leyendaSMS p {
    text-align: justify;
    margin: 0px;
    padding: 9px 9px 0px 9px;
  }

  .leyendaSMS img {
    position: inherit !important;
  }
}

@media only screen and (max-width: 500px) {
  .periodo-nombre {
    display: none;
  }

  .periodo-numero {
    display: block;
  }
}

@media only screen and (max-width: 470px) {
  .tabla-dinamica th {
    font-size: 11px;
  }

  .tabla-dinamica-footer {
    font-size: 11px;
  }

  .tabla-dinamica-columna.expan {
    width: 10%;
    padding-left: 5px;
  }

  .tabla-dinamica-columna.ejercicio {
    width: 20%;
  }

  .tabla-dinamica-columna.periodo {
    width: 20%;
  }

  .tabla-dinamica-columna.subtotal {
    width: 30%;
  }

  .tabla-dinamica-columna.select {
    width: 20%;
  }
}

@media only screen and (max-width: 350px) {
  .ui-datatable thead th,
  .ui-datatable tfoot td,
  .ui-datatable tfoot th {
    padding: 4px 0px;
  }
}

@media only screen and (max-width: 300px) {
  .subtabla td:nth-child(1) {
    width: 70%;
  }
}

/*max -width: 480px*/
@media only screen and (max-width: 35em) {
  .ui-panelgrid-cell.ui-grid-col-2 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .seccion {
    width: 100%;
  }
  .seccion.hidden {
    display: none;
  }

  .etiquetaSeccion {
    background: #95969a;
    display: table;
  }

  .etiquetaSeccion > div {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: middle;
    display: table-cell;
    float: none;
  }

  .etiquetaSeccion > div:first-child {
    padding: 5px 0px 5px 10px;
  }

  .etiquetaSeccion > div:last-child {
    width: auto;
    padding: 10px 10px 10px 10px;
  }

  .etiquetaSeccion img {
    margin-top: 0px;
  }

  .icono-1 {
    display: none;
  }

  .icono-2 {
    display: block;
  }

  .botones {
    padding-top: 15px;
  }

  .button {
    width: 100%;
  }

  .input {
    width: 98%;
  }

  .input2 {
    width: 98%;
  }

  .combo {
    width: 99.5% !important;
  }

  input[type="text"] {
    width: 98% !important;
  }

  select,
  textarea,
  button {
    width: 100% !important;
  }
}
</style>
