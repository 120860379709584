<template>
<section>
<div>
<!-- Bootstrap row -->
    <!-- Sidebar -->
    <!-- MAIN -->
    <div class="col">
      <h1 class="p" style="color:#fff;">
           <FONT FACE="arial" SIZE=6>  <i class="fas fa-money-check-alt mr-1"></i>
            Pagos</FONT>
            <small class="text-muted"></small>
        </h1>
        <hr size="2px" color="white" />
  <ul class="list-group list-group-flush">

   <div class="checkout">
  <div class="credit-card-box">
    <div class="flip">
      <div class="front">
        <div class="chip"></div>
        <div class="logo">
          <svg version="1.1" id="visa" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="47.834px" height="47.834px" viewBox="0 0 47.834 47.834" style="enable-background:new 0 0 47.834 47.834;">
            <g>
              <g>
                <path d="M44.688,16.814h-3.004c-0.933,0-1.627,0.254-2.037,1.184l-5.773,13.074h4.083c0,0,0.666-1.758,0.817-2.143
                         c0.447,0,4.414,0.006,4.979,0.006c0.116,0.498,0.474,2.137,0.474,2.137h3.607L44.688,16.814z M39.893,26.01
                         c0.32-0.819,1.549-3.987,1.549-3.987c-0.021,0.039,0.317-0.825,0.518-1.362l0.262,1.23c0,0,0.745,3.406,0.901,4.119H39.893z
                         M34.146,26.404c-0.028,2.963-2.684,4.875-6.771,4.875c-1.743-0.018-3.422-0.361-4.332-0.76l0.547-3.193l0.501,0.228
                         c1.277,0.532,2.104,0.747,3.661,0.747c1.117,0,2.313-0.438,2.325-1.393c0.007-0.625-0.501-1.07-2.016-1.77
                         c-1.476-0.683-3.43-1.827-3.405-3.876c0.021-2.773,2.729-4.708,6.571-4.708c1.506,0,2.713,0.31,3.483,0.599l-0.526,3.092
                         l-0.351-0.165c-0.716-0.288-1.638-0.566-2.91-0.546c-1.522,0-2.228,0.634-2.228,1.227c-0.008,0.668,0.824,1.108,2.184,1.77
                         C33.126,23.546,34.163,24.783,34.146,26.404z M0,16.962l0.05-0.286h6.028c0.813,0.031,1.468,0.29,1.694,1.159l1.311,6.304
                         C7.795,20.842,4.691,18.099,0,16.962z M17.581,16.812l-6.123,14.239l-4.114,0.007L3.862,19.161
                         c2.503,1.602,4.635,4.144,5.386,5.914l0.406,1.469l3.808-9.729L17.581,16.812L17.581,16.812z M19.153,16.8h3.89L20.61,31.066
                         h-3.888L19.153,16.8z"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="number"></div>
        <div class="card-holder">
          <label>Card holder</label>
          <div></div>
        </div>
        <div class="card-expiration-date">
          <label>Expires</label>
          <div></div>
        </div>
      </div>
      <div class="back">
        <div class="strip"></div>
        <div class="logo">
          <svg version="1.1" id="visa" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="47.834px" height="47.834px" viewBox="0 0 47.834 47.834" style="enable-background:new 0 0 47.834 47.834;">
            <g>
              <g>
                <path d="M44.688,16.814h-3.004c-0.933,0-1.627,0.254-2.037,1.184l-5.773,13.074h4.083c0,0,0.666-1.758,0.817-2.143
                         c0.447,0,4.414,0.006,4.979,0.006c0.116,0.498,0.474,2.137,0.474,2.137h3.607L44.688,16.814z M39.893,26.01
                         c0.32-0.819,1.549-3.987,1.549-3.987c-0.021,0.039,0.317-0.825,0.518-1.362l0.262,1.23c0,0,0.745,3.406,0.901,4.119H39.893z
                         M34.146,26.404c-0.028,2.963-2.684,4.875-6.771,4.875c-1.743-0.018-3.422-0.361-4.332-0.76l0.547-3.193l0.501,0.228
                         c1.277,0.532,2.104,0.747,3.661,0.747c1.117,0,2.313-0.438,2.325-1.393c0.007-0.625-0.501-1.07-2.016-1.77
                         c-1.476-0.683-3.43-1.827-3.405-3.876c0.021-2.773,2.729-4.708,6.571-4.708c1.506,0,2.713,0.31,3.483,0.599l-0.526,3.092
                         l-0.351-0.165c-0.716-0.288-1.638-0.566-2.91-0.546c-1.522,0-2.228,0.634-2.228,1.227c-0.008,0.668,0.824,1.108,2.184,1.77
                         C33.126,23.546,34.163,24.783,34.146,26.404z M0,16.962l0.05-0.286h6.028c0.813,0.031,1.468,0.29,1.694,1.159l1.311,6.304
                         C7.795,20.842,4.691,18.099,0,16.962z M17.581,16.812l-6.123,14.239l-4.114,0.007L3.862,19.161
                         c2.503,1.602,4.635,4.144,5.386,5.914l0.406,1.469l3.808-9.729L17.581,16.812L17.581,16.812z M19.153,16.8h3.89L20.61,31.066
                         h-3.888L19.153,16.8z"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="ccv">
          <label>CCV</label>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <form class="form container" autocomplete="off" novalidate>
    <fieldset>
        <center>
      <label for="card-number" style="font-family: sans-serif;">
       Número de tarjeta</label>
      <input type="num" id="card-number" class="input-cart-number" maxlength="4" style="font-family: sans-serif;"/>
      <input type="num" id="card-number-1" class="input-cart-number" maxlength="4" style="font-family: sans-serif;"/>
      <input type="num" id="card-number-2" class="input-cart-number" maxlength="4" style="font-family: sans-serif;"/>
      <input type="num" id="card-number-3" class="input-cart-number" maxlength="4" style="font-family: sans-serif;"/>
        </center>
    </fieldset>
    <center>
    <fieldset>
      <label for="card-holder" style="font-family: sans-serif;">Titular de la tarjeta</label>
      <input type="text" id="card-holder" class="input-cart-text"/>
    </fieldset>
    </center>
    <div class="row h-100 justify-content-center align-items-left">
    <fieldset class="fieldset-expiration">
      <label for="card-expiration-month" class="row text-center" style="font-family: sans-serif;">Fecha de caducidad</label>
      <div class="select" style="background: white;">
        <select id="card-expiration-month">
          <option></option>
          <option>01</option>
          <option>02</option>
          <option>03</option>
          <option>04</option>
          <option>05</option>
          <option>06</option>
          <option>07</option>
          <option>08</option>
          <option>09</option>
          <option>10</option>
          <option>11</option>
          <option>12</option>
        </select>
      </div>
      <div class="select" style="background: white;">
        <select id="card-expiration-year">
          <option></option>
          <option>2016</option>
          <option>2017</option>
          <option>2018</option>
          <option>2019</option>
          <option>2020</option>
          <option>2021</option>
          <option>2022</option>
          <option>2023</option>
          <option>2024</option>
          <option>2025</option>
        </select>
      </div>
    </fieldset>
    <fieldset class="fieldset-ccv">
       <label for="card-ccv" style="font-family: sans-serif;text-align: center;">CCV</label>
       <input style="border-color: red;" type="text" id="card-ccv" maxlength="3" />
     </fieldset>
    </div>
    <br>
    <center>
    <div class="box-blue" style="width: 500px;max-width: 100%;">
            <div style="text-align:center">
              <center>
                <div class="box-white">
                <h3  style="font-family: sans-serif; color: black; font-size: 15px;">&nbsp;&nbsp;&nbsp;TOTAL</h3>
                <h1  style="font-family: sans-serif; color: black;">$ {{$route.params.Monto}}</h1>
                </div>
              </center>
                <br>
            </div>
            <div>
                <div color="primary" style="text-align:center">
                    <input type="checkbox" style="width: 20px;height: 15px;"  id="PagoOtraCantidad">
                    <label style="font-family: sans-serif; color: white;">Pagar otra cantidad</label>
                </div>
                <center>
                <fieldset>
                    <input  disabled="disabled" style="border-color: red;" id="otroMonto" type="number" name="monto" placeholder="Cantidad">
                </fieldset>
                </center>
            </div>
      </div>
    </center>
      <center>
  <p class="CardError"><span class="card-errors"></span></p>
      <button style="width: 55%;" class="btn" v-on:click="pagar()"  id="payment-form"><i class="fa fa-lock"></i> PAGAR </button>
      </center>
  </form>
</div>
  </ul>
    </div><!-- Main Col END -->
</div><!-- body-row END -->
</section>
</template>
<script>
import * as api from '../router/api.js'
import myRouter from '../router/index.js'
export default {
  name: 'PagosInvitadoview',
  methods: {
    tarjeta: function () {
      $('#numTarjeta').keyup(function () {
        var valor = this.firstElementChild.value
        if (valor.length == 16) {
          this.tarjetaCompleta = this.firstElementChild.value
        } else if (valor.length > 16) {
          this.firstElementChild.value = this.tarjetaCompleta
        }
      })

      $('#mes').keyup(function () {
        var valor = this.firstElementChild.value
        if (this.firstElementChild.value.substring(0, 1) <= 1) {
          if (valor.length == 2) {
            if (this.firstElementChild.value.substring(0, 1) == 1 && this.firstElementChild.value.substring(1, 2) <= 2) {
              this.mes = this.firstElementChild.value
            } else if (this.firstElementChild.value.substring(0, 1) == 0 && this.firstElementChild.value.substring(1, 2) >= 0) {
              this.mes = this.firstElementChild.value
            } else {
              this.firstElementChild.value = this.firstElementChild.value.substring(0, 1)
            }
          } else if (valor.length > 2) {
            this.firstElementChild.value = this.mes
          }
        } else {
          this.firstElementChild.value = ''
        }
      })
      $('#anio').keyup(function () {
        var valor = this.firstElementChild.value
        if (valor.length == 4) {
          this.anio = this.firstElementChild.value
        } else if (valor.length > 4) {
          this.firstElementChild.value = this.anio
        }
      })

      $('#ccv').keyup(function () {
        var valor = this.firstElementChild.value
        if (valor.length == 4) {
          this.ccv = this.firstElementChild.value
        } else if (valor.length > 4) {
          this.firstElementChild.value = this.ccv
        }
      })

      $('#PagoOtraCantidad').change(
        function () {
          if ($(this).is(':checked')) {
            $('#otroMonto').removeAttr('disabled')
          } else {
            $('#otroMonto').val('')
            $('#otroMonto').attr('disabled', 'disabled')
          }
        })

      $('#otroMonto').keyup(function () {
        if (parseInt($('#otroMonto').val()) <= parseInt($('#montoLimpio').val())) {

        } else {
          // alert("El monto que quieres pagar es superior al pago pendiente.")
          // $("#otroMonto").val(parseInt(this.$route.params.Monto))
        }
      })

      // ---- todas las funciones de la tarjeta!

      $('.input-cart-number').on('keyup change', function () {
        var ok = $(this)

        if (ok.val().length > 3) {
          ok.next().focus()
        }

        var card_number = ''
        $('.input-cart-number').each(function () {
          card_number += $(this).val() + ' '
          if ($(this).val().length == 4) {
            $(this).next().focus()
          }
        })

        $('.credit-card-box .number').html(card_number)
      })

      $('#card-holder').on('keyup change', function () {
        var ok = $(this)
        $('.credit-card-box .card-holder div').html(ok.val())
      })

      $('#card-holder').on('keyup change', function () {
        var ok = $(this)
        $('.credit-card-box .card-holder div').html(ok.val())
      })

      $('#card-expiration-month, #card-expiration-year').change(function () {
        var m = $('#card-expiration-month option').index($('#card-expiration-month option:selected'))
        m = (m < 10) ? '0' + m : m
        var y = $('#card-expiration-year').val().substr(2, 2)
        $('.card-expiration-date div').html(m + '/' + y)
      })

      $('#card-ccv').on('focus', function () {
        $('.credit-card-box').addClass('hover')
      }).on('blur', function () {
        $('.credit-card-box').removeClass('hover')
      }).on('keyup change', function () {
        $('.ccv div').html($(this).val())
      })

      setTimeout(function () {
        $('#card-ccv').focus().delay(1000).queue(function () {
          $(this).blur().dequeue()
        })
      }, 500)
    },
    pagar: function () {
      debugger
      Conekta.setPublicKey('key_KpYZHtawXyRrkJX83KUvywg')

      // Previene hacer submit más de una vez
      $('#payment-form').prop('disabled', true)

      var conektaSuccessResponseHandler = function (token) {
      // var $form = $("#card-form");
      // Inserta el token_id en la forma para que se envíe al servidor
      // $form.append($('<input type="hidden" name="conektaTokenId" id="conektaTokenId">').val(token.id));
      // $form.get(0).submit(); //Hace submit
        //console.log(this.uuidDevice)
        //console.log(token.id)

        var cantidadAPagar = 0
        if ($('#PagoOtraCantidad').is(':checked')) {
          cantidadAPagar = parseInt($('#otroMonto').val())
        } else {
          cantidadAPagar = parseInt(this.$route.params.Monto)
        }

        var pagoInfo = {
          deviceSessionId: this.uuidDevice,
          conektaTokenId: token.id,
          titular: $('#card-holder').val(),
          departamento: 'Departamento',
          type: 'card',
          monto: cantidadAPagar,
          correo: localStorage.Correo,
          direccion: 'Pagoob',
          codigoPostal: '62900',
          contrato: this.$route.params.numContrato,
          telefono: '7771955264'
        }

        let headers = {
          'Content-Type': 'application/json'
        }

        api.pago(pagoInfo, headers).then(
          (response) => {
            debugger
            if (response == false) {
              //console.log('Existe un error')
            } else {
            // ticket se envia al servidor para procesamiento de ticket correo
              var ticket = {
                'ID': response.exito.id,
                'Status': response.exito.payment_status,
                'Monto': (response.exito.amount / 100) + response.exito.currency,
                'Codigo': response.exito.charges.data[0].payment_method.auth_code,
                'MetodoPago': response.exito.charges.data[0].payment_method.name + ' - ' +
                response.exito.charges.data[0].payment_method.last4 + ' - ' +
                response.exito.charges.data[0].payment_method.brand + ' - ' +
                response.exito.charges.data[0].payment_method.type,
                'Detalles': response.exito.line_items.data[0].quantity + ' - ' +
                response.exito.line_items.data[0].name + ' - ' +
                (response.exito.line_items.data[0].unit_price / 100)
              }

              /*
            //console.log(response);
            //console.log("+++++++++++++++++++++++++++++++++++++++++");
            //console.log("ID: " + response.exito.id);
            //console.log("Status: " + response.exito.payment_status);
            //console.log("$" + (response.exito.amount / 100) + response.exito.currency);
            //console.log("Detalles");
            //console.log(response.exito.line_items.data[0].quantity + " - "
              + response.exito.line_items.data[0].name + " - "
              + (response.exito.line_items.data[0].unit_price / 100));
            //console.log("Información de pago:");
            //console.log("Código: " + response.exito.charges.data[0].payment_method.auth_code);
            //console.log("Metodo de pago: "
              + response.exito.charges.data[0].payment_method.name + " - "
              + response.exito.charges.data[0].payment_method.last4 + " - "
              + response.exito.charges.data[0].payment_method.brand + " - "
              + response.exito.charges.data[0].payment_method.type);
            //console.log("+++++++++++++++++++++++++++++++++++++++++");
            */

              // Enviando confirmación de pago por correo (Ticket)
              var NombreComp = localStorage.Nombre + ' ' + localStorage.Apellidos
              api.sendEmailTicket(localStorage.Oid, NombreComp, localStorage.Correo, ticket).then(
                response => {
                  //console.log('Se envio el comprobante de pago al email.')
                }).catch(error => {
                //console.log(error)
                alert('¡Error al enviar comprobante al email. ')
              })

              // realiza movimiento de trnsacción en la base de datos.
              var cantidadAPagar = 0
              if ($('#PagoOtraCantidad').is(':checked')) {
                cantidadAPagar = parseInt($('#otroMonto').val())
              } else {
                cantidadAPagar = parseInt(this.$route.params.Monto)
              }
              api.transaccionExitosa(localStorage.Oid, this.$route.params.OidV, this.$route.params.numContrato, response.exito.id, cantidadAPagar).then(
                (response) => {
                  if (!response[0].DATA) {
                    //console.log('Existe un error')
                  } else {
                    //console.log('Transacción realizada en BD.')

                    // Si el movimiento en la BD es true Actualiza el UpdateAllStorage
                    api.updateStorage().then(
                      (response) => {
                        if (response) {
                          //console.log('Actualizo UpdateAllStorage.')
                          alert('¡Exito! El pago se completo, se envío el comprobante de pago al correo electrónico.')
                          myRouter.push({name: 'ProximoPagoview'}).catch(error => {
                            if (error.name != "NavigationDuplicated") {
                              throw error;
                            }
                          });
                        } else {
                          alert('¡ERROR! Update')
                        }
                      }
                    ).catch(error => {
                      //console.log(error)
                    })
                  // termina UpdateAllStorage
                  }
                }
              ).catch(error => {
                //console.log(error)
              })
            }
          }
        ).catch(error => {
          //console.log(error)
        })
      }.bind(this)

      var conektaErrorResponseHandler = function (response) {
        var $form = $('#payment-form')
        $form.find('.card-errors').text(response.message_to_purchaser)
        //console.log(response.message_to_purchaser)
        $form.find('button').prop('disabled', false)
      }

      // Aqui empieza la ejecución del token Card
      var tar1 = $('#card-number').val()
      var tar2 = $('#card-number-1').val()
      var tar3 = $('#card-number-2').val()
      var tar4 = $('#card-number-3').val()
      var titular = $('#card-holder').val()
      var mes = $('#card-expiration-month').val()
      var anio = $('#card-expiration-year').val()
      var code = $('#card-ccv').val()

      var tokenParams = {
        'card': {
          'number': tar1 + tar2 + tar3 + tar4,
          'name': titular,
          'exp_year': anio,
          'exp_month': mes,
          'cvc': code,
          'address': {
            'street1': 'Calle 123 Int 404',
            'street2': 'Col. Condesa',
            'city': 'Ciudad de Mexico',
            'state': 'Distrito Federal',
            'zip': '12345',
            'country': 'Mexico'
          }
        }
      }

      Conekta.Token.create(tokenParams, conektaSuccessResponseHandler, conektaErrorResponseHandler)
      return false
    }

  },

  mounted () {
    // alert(this.$route.params.Monto)
    //console.log('Notifications component ready.')
    this.tarjeta()
  },
  data () {
    return {
      Monto: 'hola',
      Services: JSON.parse(localStorage.getItem('Servicios')).length,
      Nombre: localStorage.Nombre + ' ' + localStorage.Apellidos
    }
  }

}

// ---- todas las funciones de la tarjeta!

</script>
<style>
.box-blue {
margin:0 0 25px;
overflow:hidden;
padding:20px;
background-color:#f8f9fa3d;
border:1px solid rgba(0, 0, 0, 0.28);
-webkit-border-radius: 5px;
border-radius: 5px;
}
.box-white {
margin:0 0 25px;
overflow:hidden;
padding:5px;
background-color:#f8f9fa;
border:1px solid rgba(0, 0, 0, 0.28);
-webkit-border-radius: 5px;
border-radius: 10px;
}
/*Diseño de tarjeta de credito*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*--------------------
 Buttons
 --------------------*/
.btn {
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  position: relative;
}
.btn::after {
  /*content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0), 0 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.btn:hover::after {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0.2);*/
}
/*--------------------
 Form
 --------------------*/
.form fieldset {
  /*border: none;
  padding: 0;
  padding: 10px 0;
  position: absolute;
  clear: both;*/
}
.form fieldset.fieldset-expiration {
  /*float: left;
  width: 60%;*/
}
.form fieldset.fieldset-expiration .select {
  width: 100px;
  margin-right: 4px;
  float: left;
  border-color: red;
}
.form fieldset.fieldset-ccv {
  /*clear: none;
  float: right;
  width: 86px;*/
}
.form fieldset label {
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  font-weight: bold;
  font-family: Inconsolata;
}
.form fieldset input,
.form fieldset .select {
  width: 100%;
  height: 38px;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);

}
.form fieldset input.input-cart-text,
.form fieldset .select.input-cart-text {
  width: 45%;
  display: inline-block;
  margin-right: 8px;
}
.form fieldset input.input-cart-text:last-child,
.form fieldset .select.input-cart-text:last-child {
  margin-right: 0;
}
.form fieldset input.input-cart-number,
.form fieldset .select.input-cart-number {
  width: 10%;
  display: inline-block;
  margin-right: 8px;
  border-color: red;
}
.form fieldset input.input-cart-number:last-child,
.form fieldset .select.input-cart-number:last-child {
  margin-right: 0;
}
.form fieldset .select {
  position: relative;
}
.form fieldset .select::after {
  content: "";
  border-top: 8px solid #222;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  z-index: 2;
  top: 14px;
  right: 10px;
  pointer-events: none;
}
.form fieldset .select select {
  appearance: none;
  position: absolute;
  padding: 0;
  border: none;
  width: 100%;
  outline: none !important;
  top: 6px;
  left: 6px;
  background: none;
}
.form fieldset .select select :-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.form button {
 border-radius: 82px 82px 82px 82px;
-moz-border-radius: 82px 82px 82px 82px;
-webkit-border-radius: 82px 82px 82px 82px;
border: 0px solid #000000;
  width: 80%;
  outline: none !important;
  background: linear-gradient(180deg, #fa7100, #fa7100);
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  box-shadow: none;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-left: 10%;
  margin-right: 10%;
}
.form button .fa {
  margin-right: 6px;
}
/*--------------------
 Checkout
 --------------------*/
.checkout {
  margin: 130px auto 30px;
  position: relative;
  background: white;
  border-radius: 15px;
  padding: 130px 0px 30px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
}
/*--------------------
 Credit Card
 --------------------*/

*,
*::before,
*::after {
  box-sizing: border-box;
}
/*--------------------
 Buttons
 --------------------*/
.btn {
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  position: relative;
}
.btn::after {
  content: "";
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0), 0 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.btn:hover::after {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0.2);
}
/*--------------------
 Form
 --------------------*/
.form fieldset {
  /*border: none;
  padding: 0;
  padding: 10px 0;
  position: absolute;
  clear: both;*/
}
.form fieldset.fieldset-expiration {
  /*float: left;
  width: 60%;*/
}
.form fieldset.fieldset-expiration .select {
  width: 100px;
  margin-right: 4px;
  float: left;
}
.form fieldset.fieldset-ccv {
  /*clear: none;
  float: right;
  width: 86px;*/
}
.form fieldset label {
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  color: #eee;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: Inconsolata;
}
.form fieldset input,
.form fieldset .select {
  width: 100%;
  height: 38px;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
}
.form fieldset input.input-cart-text,
.form fieldset .select.input-cart-text {
  width: 45%;
  display: inline-block;
  margin-right: 8px;
  border-color: red;
}
.form fieldset input.input-cart-text:last-child,
.form fieldset .select.input-cart-text:last-child {
  margin-right: 0;
}
.form fieldset input.input-cart-number,
.form fieldset .select.input-cart-number {
  width: 10%;
  display: inline-block;
  margin-right: 8px;
}
.form fieldset input.input-cart-number:last-child,
.form fieldset .select.input-cart-number:last-child {
  margin-right: 0;
}
.form fieldset .select {
  position: relative;
}
.form fieldset .select::after {
  content: "";
  border-top: 8px solid #222;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  z-index: 2;
  top: 14px;
  right: 10px;
  pointer-events: none;
}
.form fieldset .select select {
  appearance: none;
  position: absolute;
  padding: 0;
  border: none;
  width: 100%;
  outline: none !important;
  top: 6px;
  left: 6px;
  background: none;
}
.form fieldset .select select :-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.form button {
  width: 80%;
  outline: none !important;
  background: linear-gradient(180deg, #fa7100, #fa7100);
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  box-shadow: none;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-left: 10%;
  margin-right: 10%;
}
.form button .fa {
  margin-right: 6px;
}
/*--------------------
 Checkout
 --------------------*/
.checkout {
    max-width: 100%;
    min-width: 40px;
  position: relative;
  background: #8484844f;
  border-radius: 15px;
  padding: 160px 0px 30px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
}
/*--------------------
 Credit Card
 --------------------*/
.credit-card-box {
  perspective: 1000;
  width: 400px;
  height: 280px;
  position: absolute;
  top: -112px;
  left: 50%;
  transform: translateX(-50%);
}
.credit-card-box:hover .flip,
.credit-card-box.hover .flip {
  transform: rotateY(180deg);
}
.credit-card-box .front,
.credit-card-box .back {
  width: 400px;
  height: 250px;
  border-radius: 15px;
  backface-visibility: hidden;
  background: linear-gradient(135deg, #dc6300, #af550d);
  position: absolute;
  color: #fff;
  font-family: Inconsolata;
  top: 0;
  left: 0;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
}
.credit-card-box .front::before,
.credit-card-box .back::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("http://cdn.flaticon.com/svg/44/44386.svg") no-repeat center;
  background-size: cover;
  opacity: 0.05;
}
.credit-card-box .flip {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.credit-card-box .logo {
  position: absolute;
  top: 9px;
  right: 20px;
  width: 60px;
}
.credit-card-box .logo svg {
  width: 100%;
  height: auto;
  fill: #fff;
}
.credit-card-box .front {
  z-index: 2;
  transform: rotateY(0deg);
}
.credit-card-box .back {
  transform: rotateY(180deg);
}
.credit-card-box .back .logo {
  top: 185px;
}
.credit-card-box .chip {
  position: absolute;
  width: 60px;
  height: 45px;
  top: 20px;
  left: 20px;
  background: linear-gradient(135deg, #ddccf0 0%, #d1e9f5 44%, #f8ece7 100%);
  border-radius: 8px;
}
.credit-card-box .chip::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 4px solid rgba(128, 128, 128, 0.1);
  width: 80%;
  height: 70%;
  border-radius: 5px;
}
.credit-card-box .strip {
  background: linear-gradient(135deg, #404040, #1a1a1a);
  position: absolute;
  width: 100%;
  height: 50px;
  top: 30px;
  left: 0;
}
.credit-card-box .number {
  position: absolute;
  margin: 0 auto;
  top: 103px;
  left: 19px;
  font-size: 33px;
}
.credit-card-box label {
  font-size: 10px;
  letter-spacing: 1px;
  text-shadow: none;
  text-transform: uppercase;
  font-weight: normal;
  opacity: 0.5;
  display: block;
  margin-bottom: 3px;
}
.credit-card-box .card-holder,
.credit-card-box .card-expiration-date {
  position: absolute;
  margin: 0 auto;
  top: 180px;
  left: 19px;
  font-size: 22px;
  text-transform: capitalize;
}
.credit-card-box .card-expiration-date {
  text-align: right;
  left: auto;
  right: 20px;
}
.credit-card-box .ccv {
  height: 36px;
  background: #fff;
  width: 91%;
  border-radius: 5px;
  top: 110px;
  left: 0;
  right: 0;
  position: absolute;
  margin: 0 auto;
  color: #000;
  text-align: right;
  padding: 10px;
}
.credit-card-box .ccv label {
  margin: -25px 0 14px;
  color: #fff;
}
/*TRASICION DEL TITULO*/
.p {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%
  }

  to {

  }
}
</style>
