<!--Creacion del menu-->
<template>
  <section>
    <Menu>
      <div class="col-md-10 order-md-1">
        <h4 class="mb-3" style="color: #fdfefe">
          Configuración de Municipio
        </h4>
        <hr>
        <div id="formularioUser">
          <div class="row">
            <!-- <div class="col-md-6 mb-3">
              <label for="municipio" style="color: #fdfefe">
                Municipio:
              </label>
              <input
                type="text"
                class="input w-full border"
                id="municipio"
                name="municipio"
                placeholder="Municipio"
                disabled="disabled"
                value=""
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label for="descuento" style="color: #fdfefe">
                Porcentaje de descuento:
              </label>
              <input
                type="text"
                class="input w-full border"
                id="descuento"
                name="descuento"
                placeholder="0.00 %"
                value=""
                required
              />
            </div> -->
            <div class="col-md-12 mb-3">
              <label for="periodo" style="color: #fdfefe">
                Opción de Pago:
              </label>
                <select class="input w-full border" name="periodo" id="periodo">
                  <option value="">Seleccionar... </option>
                  <option value="1">Por Bimestre</option>
                  <option value="2">Por Ejercicio Fiscal</option>
                  <option value="3">Adeudo Total</option>
                </select>
            </div>
          </div>
        </div>
          <div id="buttonArea">
            <div class="row">
              <div class="col-md-12 mb-3">
                <br />
                <button
                  id="btnPay"
                  v-on:click="updatePeriodo()"
                  type="button"
                  class="form-control btn-primary btn-lg">
                  Guardar Cambios
                </button>
              </div>
            </div>
          </div>
      </div>
    </Menu>
  </section>
</template>
<script>
import Menu from "../components/Menu.vue";
import myRouter from "../router/index.js";
import axios from "axios";
import * as api from "../router/api.js";
import VueBarcode from "vue-barcode";
import JsBarcode from "jsbarcode";
var OidPagos = '';
var montoAgua = '';
export default {
  name: "Configuracionview",
  components: {
    Menu,
  },
  props: {
    title: String,
  },
  data() {
    key: ""
    return {
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos
    };
  },
  mounted() {
    this.findProject();
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "Configuracionview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  methods: {
    findProject: async function () {
      api
        .findProject()
        .then((response) => {
          if (response) {
            $('#periodo').val(response[0].PagoBAT)
          }
        })
        .catch((error) => {
          //console.log("Error: " + error + " **UpdateStorage Servicio**");
        });
    },
    updatePeriodo: async function () {
      var perValue = $('#periodo').val();
      if (perValue != '') {
        if (perValue <= 3) {
          api
            .updatePeriodo(perValue)
            .then((response) => {
              if (response) {
                //console.log(response)
                if (response == 'Exito') {
                  this.$swal("¡Éxito!","Información actualizada.","success");
                } else {
                  this.$swal("¡Error!","Algo Salió mal.","error");
                }
              }
            })
            .catch((error) => {
              //console.log("Error: " + error + " **UpdateStorage Servicio**");
            });
        } else {
          this.$swal("¡Error!","Los datos ingresados son incorrectos.","error");
        }
      } else {
        this.$swal("¡Error!","Debes seleccionar una opcion.","error");
      }

    },
  },
};
</script>
<style>
</style>
