<!--Creacion del menu-->
<template>
  <section>
    <Menu>
      <div class="col-md-10 order-md-1">
        <!-- <h4 class="mb-3" style="color: #fdfefe">
            Reporte General
        </h4>
        <div class="relative" style="width: 49%;">
          <button id="btnReport" type="button" class="form-control btn-success btn-lg" v-on:click="makeReport()" style="width:100%"><i class="fas fa-file-alt mr-1"></i> Generar Reporte</button>
        </div>
        <hr>-->
        <h4 class="mb-3" style="color: #fdfefe">
          Consultar pagos por Clave Catastral
        </h4>
        <div id="formularioUser">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="firstName" style="color: #fdfefe"
                >No. de Clave Catastral:
              </label>
              <input
                type="text"
                class="input w-full border"
                id="numContrato"
                name="nombre"
                placeholder="Clave Catastral"
                value=""
                required
              />
              <div class="invalid-feedback">El Nombre es Requerido.</div>
            </div>
            <div class="col-md-3 mb-3 d-sm-block">
              <br />
              <button
                v-on:click="BuscaContrato()"
                type="button"
                class="form-control btn-primary btn-lg"
              >
                <i class="fas fa-search mr-1"></i> Buscar
              </button>
            </div>
            <div class="col-md-3 mb-3 d-sm-block">
              <br />
              <button
                v-on:click="Limpiar()"
                type="button"
                class="form-control btn-primary btn-lg"
              >
                <i class="fas fa-paint-brush mr-1"></i> Limpiar
              </button>
            </div>
          </div>
        </div>
          <div id="activaPagar">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="contribuyente" style="color: #fdfefe">Nombre del Contribuyente: </label>
              <input
                type="text"
                style=""
                class="input w-full border"
                id="contribuyente"
                name="contribuyente"
                disabled="disabled"
                placeholder="Contribuyente"
                required
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="servicio" style="color: #fdfefe">Servicio: </label>
              <input
                type="text"
                style=""
                class="input w-full border"
                id="Servicio"
                name="servicio"
                disabled="disabled"
                placeholder="Servicio"
                required
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="Descripcion" style="color: #fdfefe"
                >Descripción:
              </label>
              <input
                type="text"
                style=""
                class="input w-full border"
                id="Descripcion"
                disabled="disabled"
                name="telefono"
                placeholder="Descripción"
                required
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="colonia" style="color: #fdfefe">Municipio</label>
              <input
                type="text"
                style=""
                disabled="disabled"
                class="input w-full border"
                id="Municipio"
                name="codpostal"
                placeholder="Municipio"
                required
              />
              <div class="invalid-feedback">El Municipio es Requerido.</div>
            </div>
          </div>

            <div class="row">
              <div class="col-md-12 mb-3">
                <br />
                <button
                  id="btnPay"
                  v-on:click="findContrato()"
                  type="button"
                  class="form-control btn-success btn-lg"
                >
                  <i class="fas fa-list mr-1"></i> Consultar historial de pagos
                </button>
              </div>
            </div>
          </div>
      </div>
    </Menu>
  </section>
</template>
<script>
import Menu from "../components/Menu.vue";
import myRouter from "../router/index.js";
import axios from "axios";
import * as api from "../router/api.js";
import VueBarcode from "vue-barcode";
import JsBarcode from "jsbarcode";
var OidPagos = '';
var montoAgua = '';
export default {
  name: "Comprobantesview",
  created () {
     window.dataComprobante = this.dataComprobante.bind(this);
  },
  components: {
    Menu,
  },
  props: {
    title: String,
  },
  data() {
    key: ""
    return {
      Services: JSON.parse(localStorage.getItem("Servicios")).length,
      Nombre: localStorage.Nombre + " " + localStorage.Apellidos,
      servicio_cont : 'PREDIAL'

    };
  },
  mounted() {
    this.init();
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "LoginView" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "Comprobantesview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  methods: {
    dataComprobante: function(numContrato,oidComp){
      api
        .dataComprobante(numContrato,oidComp)
        .then((response) => {
          this.createPDF(response);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    textToBase64Barcode: function(text){
         var canvas = document.createElement("canvas");
         JsBarcode(canvas, text, {format: "CODE39"});
         return canvas.toDataURL("image/png");
    },
    createPDF: function (data) {
      var numeroDeContrato = data[0].NumerodeContrato;
      var municipio = data[0].Municipio;
      var barcode = this.textToBase64Barcode(data[0].Oid);
      var servicio = 'predial';
      var transacc = data[0].idTransaccion.substring(0, 3);
      var idTransaccion = data[0].CodigoDeTransaccion;
      var monto = data[0].Monto;
      var status = data[0].Status;
      var periodosPagados = data[0].periodosPagados;
      var fechaDePago = data[0].FechaDePago;
      var nombreContribuyente = data[0].Nombre;
      var OidContribuyente = data[0].idContribuyente;
      var filename = "comprobante_"+numeroDeContrato;
      var pageSize = "letter";
      var orientation = "portrait";
      api
        .createPDF(numeroDeContrato, municipio, barcode, servicio, transacc, idTransaccion, monto, status,periodosPagados,fechaDePago,nombreContribuyente, OidContribuyente, filename, pageSize, orientation)
        .then((response) => {
          //console.log(response)
          window.open("https://pagoob.azurewebsites.net/print/" + filename + ".pdf", '_blank');
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    makeReport: function () {
    this.$swal({
      title: 'Generer Reporte',
      showCloseButton: true,
      html:
      `<div>
        <label style="width: 20%">Servicio: </label>
        <select id="servRep" class="swal2-input" style="width: 70%">
          <option value='PREDIAL'>PREDIAL</option>
        </select>
      </div>`,
      preConfirm: function() {
        return new Promise(function(resolve) {
        if (true) {
          resolve([
            document.getElementById('servRep').value
          ]);
        }
        });
      }
    }).then(function(result) {
      var usuario = JSON.parse(localStorage.Usuario)[0].proyectoID
      var resultado=result.value[0];
      var filename = "REP_"+resultado+"_"+usuario;
      var pageSize = "letter";
      var orientation = "landscape";
      api
        .createReportePDF(resultado,filename, pageSize, orientation)
        .then((response) => {

          if (response = 'Acepted') {
            //console.log(response)
            window.open("https://pagoob.azurewebsites.net/print/" + filename + ".pdf", '_blank');
          } else {
            //console.log(response)
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    })

    },
    round: function (num, decimales = 2) {
      var signo = num >= 0 ? 1 : -1;
      num = num * signo;
      if (decimales === 0)
        //con 0 decimales
        return signo * Math.round(num);
      // round(x * 10 ^ decimales)
      num = num.toString().split("e");
      num = Math.round(
        +(num[0] + "e" + (num[1] ? +num[1] + decimales : decimales))
      );
      // x * 10 ^ (-decimales)
      num = num.toString().split("e");
      return (
        signo * (num[0] + "e" + (num[1] ? +num[1] - decimales : -decimales))
      );
    },
    calcularMontos() {
      var total = 0;
      var checks = Array.from(
        document.querySelectorAll('input.pagos[type="checkbox"]:checked')
      );
      checks.forEach((element) => {
        total = total + parseFloat($(element).attr("data"));
      });
      const aMoneda = (numero) => {
        const opciones = {
          numeroDeDecimales: 2,
          separadorDecimal: ".",
          separadorMiles: ",",
          simbolo: "$ ", // Con un espacio, ya que la función no agrega espacios
          posicionSimbolo: "i", // i = izquierda, d = derecha
        };
        // Valores por defecto
        opciones.simbolo = opciones.simbolo || "$";
        opciones.separadorDecimal = opciones.separadorDecimal || ".";
        opciones.separadorMiles = opciones.separadorMiles || ",";
        opciones.numeroDeDecimales =
          opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
        opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
        const CIFRAS_MILES = 3;

        // Redondear y convertir a cadena
        let numeroComoCadena = numero.toFixed(opciones.numeroDeDecimales);

        // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
        let posicionDelSeparador = numeroComoCadena.indexOf(
          opciones.separadorDecimal
        );
        if (posicionDelSeparador === -1)
          posicionDelSeparador = numeroComoCadena.length;
        let formateadoSinDecimales = "",
          indice = posicionDelSeparador;
        // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
        while (indice >= 0) {
          let limiteInferior = indice - CIFRAS_MILES;
          // Agregar separador si cortamos más de 3
          formateadoSinDecimales =
            (limiteInferior > 0 ? opciones.separadorMiles : "") +
            numeroComoCadena.substring(limiteInferior, indice) +
            formateadoSinDecimales;
          indice -= CIFRAS_MILES;
        }
        let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(
          posicionDelSeparador,
          opciones.numeroDeDecimales + 1
        )}`;
        return opciones.posicionSimbolo === "i"
          ? opciones.simbolo + formateadoSinSimbolo
          : formateadoSinSimbolo + opciones.simbolo;
      };
      $(".montoTotal").html(aMoneda(total));
    },
    async findContrato() {
      var servicio = $("#Servicio").val();
      var NumContrato = $("#numContrato").val();
      var contribuyente = $("#contribuyente").val();
      var municipio = $("#Municipio").val();
      const consultaHistorial = await api.getTablaHistorial('u', NumContrato, 'o');
      if(consultaHistorial == ""){
          const mensaje = await this.$swal(
            "¡Error!",
            "Los datos ingresados son incorrectos.",
            "error"
          );
          return mensaje;
      } else {
          this.$swal({
            title: "",
            width: "850px",
            html: consultaHistorial,
            showCloseButton: true,
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              //console.log('it works')
            }
          });
          $('#linkRegistrar').css('display','none');
      }
    },
    Limpiar: function () {
      document.getElementById("Servicio").value = "";
      document.getElementById("contribuyente").value = "";
      document.getElementById("Descripcion").value = "";
      document.getElementById("Municipio").value = "";
      $("#activaPagar").css("display", "none");
    },
    BuscaContrato: async function () {
      var NumContrato = $("#numContrato").val();
      const infoConsultas = await api.infoConsultas(NumContrato);
      if (infoConsultas.length > 0) {
        for (var i = 0; i < infoConsultas.length; i++) {
          var montoR = parseFloat(infoConsultas[i].monto).toFixed(2);
          if (montoR < 0) {
            montoR = 0;
          }
          OidPagos = infoConsultas[i].Oid;
          this.servicio_cont = infoConsultas[i].nombre
          $("#Servicio").val(infoConsultas[i].nombre);
          $("#Descripcion").val(infoConsultas[i].descripcion);
          $("#contribuyente").val(infoConsultas[i].contribuyente);
          $("#Municipio").val(infoConsultas[i].municipio);
          $("#activaPagar").css("display", "block");
          $("#btnPay").prop("disabled", false);
        }
      } else {
        this.$swal("No existe ese registro", "", "error");
      }
    },
    initial() {
      $(
        function () {
          $("#allchecked").change(
            function (e) {
              $("input:checkbox").prop("checked", $(e.target).prop("checked"));
              $('input.pagos[type="checkbox"]').prop("disabled", $(e.target).prop("checked"));
              this.calcularMontos();
            }.bind(this)
          );
          $("#allchecked").change(
            function (e) {
              $("input:checkbox").prop("checked", $(e.target).prop("checked"));
              $('input.pagos[type="checkbox"]').prop("disabled", $(e.target).prop("checked"));
              this.calcularMontos();
            }.bind(this)
          );
          $('input.pagos[type="checkbox"]').on(
            "click",
            function (e) {
              var checks = Array.from(
                document.querySelectorAll('input.pagos[type="checkbox"]')
              );
              var valid = true;
              var cont = 0;
              checks.forEach((element) => {
                if (valid != false) {
                  if (element.id != e.target.id) {
                    if (element.checked == true) {
                      valid = true;
                    } else {
                      alert("Tu selección debe ser el primer pago pendinte");
                      valid = false;
                      e.target.checked = false;
                    }
                  } else {
                    valid = false;
                    $(e.target).prop("disabled", true)
                  }
                }
              });

              this.calcularMontos();
            }.bind(this)
          );
        }.bind(this)
      );
    },
    init() {
      $(function () {
        // Siempre que salgamos de un campo de texto, se chequeará esta función
        $("#activaPagar").css("display", "none");
        $("#btnPay").prop("disabled", true);
        function // Función para comprobar los campos de texto
        checkCampos(obj) {
          var camposRellenados = true;
          obj.find("input").each(function () {
            var $this = $(this);
            if ($this.val().length <= 0) {
              camposRellenados = false;
              return false;
            }
          });
          if (camposRellenados) {
            return true;
          } else {
            return false;
          }
        }
      });
    },
  },
};
</script>
<style>
</style>
