import Vue from 'vue'
import Router from 'vue-router'

// views
import homeview from '../views/home'
import LoginView from '../views/login'
import InConsultar from '../views/InConsultar'
// import InConsultarPuenteIxtla from '../views/InConsultarPuenteIxtla'
import QuickPagos from '../views/QuickPagos'
import ActualizaInformacionview from '../views/ActualizaInformacion'
import Domiciliosview from '../views/Domicilios'
import Serviciosview from '../views/Servicios'
import Contratoview from '../views/Contratos'
import HistorialPagosview from '../views/HistorialPagos'
import ProximoPagoview from '../views/ProximoPago'
import ConsultarPagarview from '../views/ConsultarPagar'
import PagosPredialview from '../views/PagosPredial'
import PagosAguaview from '../views/PagosAgua'
import Registroview from '../views/Registro'
import perfilview from '../views/Perfil'
import Invitadoview from '../views/Invitado'
import PagosInvitadoview from '../views/PagosInvitado'
import Dashboardview from '../views/Dashboard'
import Mensajeriaview from '../views/Mensajeria'
import Administradorview from '../views/Administrador'
import ComprobantePagoview from '../views/ComprobantePago'
import Adminview from '../views/Admin'
import Consultasview from '../views/Consultas'
import Comprobantesview from '../views/Comprobantes'
import Configuracionview from '../views/Configuracion'
import PuenteIxtla from "../views/PuenteIxtla";
import PagoPuenteIxtla from "../views/PagoPuenteIxtla";

Vue.use(Router)

export default new Router({
  // mode: 'history',
  mode: 'hash',
  duplicateNavigationPolicy: 'ignore',
  routes: [{
    path: '/home',
    name: 'homeview',
    component: homeview,
    props : { pathURL: "Panel"}
  },
  {
    path: '/',
    name: 'HomeView',
    component: LoginView
  },
  {
    path: '/Login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/QuickPagos',
    name: 'QuickPagos',
    component: QuickPagos
  },
  {
    path: '/PuenteIxtla',
    name: 'PuenteIxtla',
    component: PuenteIxtla
  },
  {
    path: '/PagoPuenteIxtla',
    name: 'PagoPuenteIxtla',
    component: PagoPuenteIxtla
  },
  {
    path: '/InConsultar',
    name: 'InConsultar',
    component: InConsultar
  },
  // {
  //   path: '/InConsultarPuenteIxtla',
  //   name: 'InConsultarPuenteIxtla',
  //   component: InConsultarPuenteIxtla
  // },
  {
    path: '/ActualizaInformacion',
    name: 'ActualizaInformacionview',
    component: ActualizaInformacionview,
    props : { pathURL: "Actualizar Información"}
  },
  {
    path: '/Domicilios',
    name: 'Domiciliosview',
    component: Domiciliosview
  },
  {
    path: '/Servicios',
    name: 'Serviciosview',
    component: Serviciosview,
    props : { pathURL: "Servicios"}
  },
  {
    path: '/Contratos',
    name: 'Contratosview',
    component: Contratoview,
    props : { pathURL: "Contratos"}
  },
  {
    path: '/HistorialPagos',
    name: 'HistorialPagosview',
    component: HistorialPagosview,
    props : { pathURL: "Historial de Pagos"}
  },
  {
    path: '/ProximoPago',
    name: 'ProximoPagoview',
    component: ProximoPagoview,
    props : { pathURL: "Proximos Pagos"}
  },
  {
    path: '/ConsultarPagar',
    name: 'ConsultarPagarview',
    component: ConsultarPagarview,
    props : { pathURL: "Proximos Pagos"}
  },
  {
    path: '/Registro',
    name: 'Registroview',
    component: Registroview
  },
  {
    path: '/PagosPredial',
    name: 'PagosPredialview',
    component: PagosPredialview,
    props : { pathURL: "Pagos"}
  },
  {
    path: '/PagosAgua',
    name: 'PagosAguaview',
    component: PagosAguaview,
    props : { pathURL: "Pagos"}
  },
  {
    path: '/Perfil',
    name: 'perfilview',
    component: perfilview,
    props : { pathURL: "Perfil"}
  },
  {
    path: '/Invitado',
    name: 'Invitadoview',
    component: Invitadoview
  },
  {
    path: '/Comprobante',
    name: 'ComprobantePagoview',
    component: ComprobantePagoview,
    props : { pathURL: "Pagos"}
  },
  {
    path: '/PagosInvitado',
    name: 'PagosInvitadoview',
    component: PagosInvitadoview
  },
  {
    path: '/Dashboard',
    name: 'Dashboardview',
    component: Dashboardview
  },
  {
    path: '/Mensajeria',
    name: 'Mensajeriaview',
    component: Mensajeriaview
  },
  {
    path: '/Administrador',
    name: 'Administradorview',
    component: Administradorview
  },
  {
    path: '/Admin',
    name: 'Adminview',
    component: Adminview,
    props : { pathURL: "Administrador"}
  },
  {
    path: '/Consultas',
    name: 'Consultasview',
    component: Consultasview,
    props : { pathURL: "Consultas"}
  },
  {
    path: '/Comprobantes',
    name: 'Comprobantesview',
    component: Comprobantesview,
    props : { pathURL: "Comprobantes"}
  },
  {
    path: '/Configuracion',
    name: 'Configuracionview',
    component: Configuracionview,
    props : { pathURL: "Configuracion"}
  }
  ]
})
