<template>
  <div class="margin">
    <div
      class="vue-tempalte bodyIniciob"
      style="background: white; text-align: center"
    >
      <center>
        <br />
        <img
          class="corazonn"
          style="width: 40%"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Coat_of_arms_of_Puente_De_Ixtla.svg/500px-Coat_of_arms_of_Puente_De_Ixtla.svg.png"
          aling="top"
        />
      </center>
      <br />
      <center>
        <h3
          style="
            color: #313a55;
            font-variant-caps: all-petite-caps;
            border-bottom: inset;
            font-size: 25px;
            font-weight: bold;
          "
        >
          PAGO DE IMPUESTO PREDIAL
        </h3>
      </center>
      <br />
      <button
        id="consultar"
        v-on:click="newNuevoContrato()"
        class="btn-dark button w-100 inline-block mr-1 mb-2"
        style="font-size: 1rem"
      >
        <i class="fa fa-search" aria-hidden="true"></i> Consultar y Pagar
        <i class="fa fa-credit-card" aria-hidden="true"></i>
      </button>
      <br />
      <label class="side-menu__title" style="padding: 5px">
        Paga tu predial fácil, rápido y seguro</label
      ><label>&nbsp;</label
      ><i class="fa fa-lock" aria-hidden="true" style="color: #313a55"></i>
      <br />
      <!-- <label>Consulta tus recibos de pago</label>
        <br> -->
      <!-- <button
        id="recibo"
        v-on:click="consultaPagos()"
        class="btn-dark button w-100 inline-block mr-1 mb-2 "
        style="font-size: 1rem;"><i class="fa fa-search" aria-hidden="true"></i> Consultar</button>
        <br> -->
      <!-- <br>
        <div style="text-align:center">Para Mayores Beneficios <router-link to="/Registro"><u>Registrate Aquí</u></router-link></div>
        <br>
        <div style="text-align:center">O ¿Ya tienes Cuenta? <router-link to="/Login"><u>Iniciar Sesión</u></router-link></div>
        <br> -->
        <br>
    </div>

    <br>
    <br />
    <center>
      <table style="width: 300px" border="0">
        <tbody>
          <tr>
            <td colspan="2">
              
                
            </td>
          </tr>
          <tr>
            <td>
                  <a
                href="https://play.google.com/store/apps/details?id=com.casesconsorcio.pagoob"
                target="_blank"
              >
                  <img
                  src="https://i0.wp.com/iessantotomasdeaquino.es/wp-content/uploads/2020/04/logo-play-store.png"
                  aling="top"
                  class="corazonn"
                  style="width: 150px"
              /></a>
            </td>
            <td>
              <a
                href="https://apps.apple.com/mx/app/pagoob/id1447971098"
                target="_blank"
                ><img
                  src="https://promomejia.com/wp-content/uploads/2016/09/Logo-Appstore-768x266.png"
                  aling="top"
                  class="corazonn"
                  style="width: 150px"
              /></a>
            </td>
            <td><center>
                 <img
                  data-v-692d08bb=""
                  src="https://i.ibb.co/jvpW5SM/onlink-to-uyde84-small.png"
                  aling="top"
                  class="corazonn"
                  style="width: 100%"
                />
              </center></td>
          </tr>
        </tbody>
      </table>
    </center>
    <center>
                <img
                  style="width: 130px"
                  class=""
                  src="../assets/logoP.svg"
                  aling="top"
                />
                <br>
                v2.0.1
                <br />
              </center>
  </div>
</template>

<script>
import myRouter from "../router/index.js";
import Vue from "vue";
import axios from "axios";
import * as api from "../router/api.js";
import VueBarcode from "vue-barcode";
import JsBarcode from "jsbarcode";
export default {
  name: "InConsultar",
  created() {
    window.dataComprobante = this.dataComprobante.bind(this);
  },
  methods: {
    loading(mensaje) {
      this.$swal({
        title: mensaje,
        imageUrl:
          "https://i.pinimg.com/originals/b3/30/0a/b3300a501c0897d36683d6f6d0b000a5.gif",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    },
    dataComprobante: function (numContrato, oidComp) {
      api
        .dataComprobante(numContrato, oidComp)
        .then((response) => {
          this.createPDF(response);
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    textToBase64Barcode: function (text) {
      var canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE39" });
      return canvas.toDataURL("image/png");
    },
    createPDF: function (data) {
      var numeroDeContrato = data[0].NumerodeContrato;
      var municipio = data[0].Municipio;
      var barcode = this.textToBase64Barcode(data[0].Oid);
      var servicio = "predial";
      var transacc = data[0].idTransaccion.substring(0, 3);
      var idTransaccion = data[0].CodigoDeTransaccion;
      var monto = data[0].Monto;
      var status = data[0].Status;
      var periodosPagados = data[0].periodosPagados;
      var fechaDePago = data[0].FechaDePago;
      var nombreContribuyente = data[0].Nombre;
      var OidContribuyente = data[0].idContribuyente;
      var filename = "comprobante_" + numeroDeContrato;
      var pageSize = "letter";
      var orientation = "portrait";
      api
        .createPDF(
          numeroDeContrato,
          municipio,
          barcode,
          servicio,
          transacc,
          idTransaccion,
          monto,
          status,
          periodosPagados,
          fechaDePago,
          nombreContribuyente,
          OidContribuyente,
          filename,
          pageSize,
          orientation
        )
        .then((response) => {
          //console.log(response)
          window.open(
            "https://pagoob.azurewebsites.net/print/" + filename + ".pdf",
            "_blank"
          );
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    async nuevoContrato() {
      const contrato = await this.$swal.fire({
        title: "Pagar Predial",
        html: "<div style=''><b>Ingresa tu clave catastral: </b></div>",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        showCancelButton: false,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escribir una clave catastral";
          }
        },
        preConfirm: (contrato) => {
          return contrato;
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
      if (!contrato.isConfirmed) {
        return;
      }
      const iniciales = await this.$swal.fire({
        title: "Ingresar tus iniciales",
        html: "<div style='color: #8d8888'><b>Ejemplo: </b> <b><u>P</u></b>edro <b><u>G</u></b>arcía <b><u>L</u></b>ópez = <b>PGL</b></div>",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          onkeyup: "javascript:this.value=this.value.toUpperCase();",
        },
        confirmButtonText: "Consultar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        showCancelButton: false,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escribir las iniciales de tu nombre";
          }
        },
        preConfirm: (inicales) => {
          return inicales;
        },
      });
      if (!iniciales.isConfirmed) {
        return;
      }
      const consultaIniciales = await api.infoContrato(contrato.value);

      if (consultaIniciales[0].DATA) {
        let resultado = JSON.parse(consultaIniciales[0].DATA)
          .Contribuyente[0].NombreContribuyente.trim()
          .split(" ")
          .map((w) => w[0].toUpperCase())
          .join("");
        if (resultado != iniciales.value) {
          const mensaje = await this.$swal(
            "¡Error!",
            "Los datos ingresados son incorrectos.",
            "error"
          );
          return mensaje;
        }
      }
      const getTablaPeriodo = await api.getTablaPeriodo(
        "u",
        contrato.value,
        "o",
        ""
      );
      if (getTablaPeriodo == "") {
        const mensaje = await this.$swal(
          "¡Error!",
          "Hubo un problema al obtener datos.",
          "error"
        );
        return mensaje;
      } else {
        this.$swal({
          title: "",
          showCloseButton: true,
          width: "850px",
          html: getTablaPeriodo,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Pagar",
        }).then((result) => {
          if (result.isConfirmed) {
            var arr = [];
            var checks = Array.from(
              document.querySelectorAll('input.pagos[type="checkbox"]:checked')
            );
            if (checks.length > 0) {
              checks.forEach((element) => {
                arr.push({
                  OidPor_Pagar: $(element).attr("Oid"),
                  optionPay: $(element).attr("optionPay"),
                  periodo: $(element).attr("periodo"),
                  bimestre: $(element).attr("bimestre"),
                });
              });
              //console.log(arr);
              myRouter
                .push({
                  name: "QuickPagos",
                  params: {
                    OidV: "inv001",
                    numContrato: contrato.value,
                    Monto: arr,
                    optionPay: $(checks[0]).attr("optionPay"),
                    contribuyente: { nombre: "", municipio: "" },
                  },
                })
                .catch((error) => {
                  if (error.name != "NavigationDuplicated") {
                    throw error;
                  }
                });
            } else {
              //console.log("Some checkboxes are not checked");
            }
          }
        });
        this.init();
      }
    },
    async newNuevoContrato() {
      const contrato = await this.$swal.fire({
        title: "Pagar Predial",
        html: "<div style=''><b>Ingresa tu clave catastral: </b></div>",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        showCancelButton: false,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escribir una clave catastral";
          }
        },
        preConfirm: (contrato) => {
          return contrato;
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
      if (!contrato.isConfirmed) {
        return;
      }
      const iniciales = await this.$swal.fire({
        title: "Ingresar tus iniciales",
        html: "<div style='color: #8d8888'><b>Ejemplo: </b> <b><u>P</u></b>edro <b><u>G</u></b>arcía <b><u>L</u></b>ópez = <b>PGL</b></div>",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          onkeyup: "javascript:this.value=this.value.toUpperCase();",
        },
        confirmButtonText: "Consultar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        showCancelButton: false,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escribir las iniciales de tu nombre";
          }
        },
        preConfirm: (inicales) => {
          return inicales;
        },
      });
      if (!iniciales.isConfirmed) {
        return;
      }
      this.loading("Validando información");
      const consultaIniciales = await api.getDataPuente(
        contrato.value.replace(/-/g, "")
      );
      if (consultaIniciales.length === 0) {
        const mensaje = await this.$swal(
          "¡Error!",
          "Los datos ingresados son incorrectos.",
          "error"
        );
        return mensaje;
      }
      if (consultaIniciales[0].NombreContribuyente) {
        let resultado = consultaIniciales[0].NombreContribuyente.trim()
          .split(" ")
          .map((w) => w[0].toUpperCase())
          .join("");
        if (resultado != iniciales.value) {
          const mensaje = await this.$swal(
            "¡Error!",
            "Los datos ingresados son incorrectos.",
            "error"
          );
          return mensaje;
        }
      }

      const montosPuente = await api.getDataPuenteMontos(contrato.value);

      var data = Object.assign(consultaIniciales[0], montosPuente[0]);
      data.sinCuenta = true;
      const makeAlertPay = await api.makeAlertPay(data);
      //console.log(makeAlertPay)
      this.$swal({
        title: "",
        showCloseButton: true,
        width: "900px",
        html: makeAlertPay.tab,
        showCancelButton: true,
        showConfirmButton:
          makeAlertPay.monto === 0
            ? false
            : makeAlertPay.BimestresDebe < 24
            ? true
            : makeAlertPay.permisoPago == false
            ? false
            : true,
        cancelButtonText: makeAlertPay.monto === 0 ? "Cerrar" : "Cancelar",
        confirmButtonText: "Pagar",
      }).then((result) => {
        if (result.isConfirmed) {
          myRouter
            .push({
              //name: "PuenteIxtla",
              name: "PagoPuenteIxtla",
              params: {
                OidV: "inv001",
                numContrato: contrato.value,
                dataAll: consultaIniciales[0],
                Monto: makeAlertPay.monto,
                optionPay: "3",
                UltimoBimestrePago: consultaIniciales[0].UltimoBimestrePago,
                UltimoAnioPago: consultaIniciales[0].UltimoAnioPago,
                bimestresAPagar: consultaIniciales[0].BimestresDebe,
                pagoSinCuenta: true,
                contribuyente: {
                  nombre: consultaIniciales[0].NombreContribuyente,
                  municipio: "Puente de Ixtla",
                },
              },
            })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        }
      });
      this.init();
    },
    async consultaPagos() {
      const contrato = await this.$swal.fire({
        title: "Consultar Historial de Pagos",
        html: "<div style=''><b>Ingresa tu clave catastral: </b></div>",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        showCancelButton: false,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escribir la clave catastral";
          }
        },
        preConfirm: (contrato) => {
          return contrato;
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
      if (!contrato.isConfirmed) {
        return;
      }
      const iniciales = await this.$swal.fire({
        title: "Ingresar tus iniciales",
        html: "<div style='color: #8d8888'><b>Ejemplo: </b> <b><u>P</u></b>edro <b><u>G</u></b>arcía <b><u>L</u></b>opez = <b>PGL</b></div>",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          onkeyup: "javascript:this.value=this.value.toUpperCase();",
        },
        confirmButtonText: "Consultar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        showCancelButton: false,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Necesitas escribir las iniciales de tu nombre";
          }
        },
        preConfirm: (inicales) => {
          return inicales;
        },
      });
      if (!iniciales.isConfirmed) {
        return;
      }

      const consultaIniciales = await api.infoContrato(contrato.value);

      if (consultaIniciales[0].DATA) {
        let resultado = JSON.parse(consultaIniciales[0].DATA)
          .Contribuyente[0].NombreContribuyente.trim()
          .split(" ")
          .map((w) => w[0].toUpperCase())
          .join("");
        if (resultado != iniciales.value) {
          const mensaje = await this.$swal(
            "¡Error!",
            "Los datos ingresados son incorrectos.",
            "error"
          );
          return mensaje;
        }
      }
      const consultaHistorial = await api.getTablaHistorial(
        "u",
        contrato.value,
        "o"
      );
      if (consultaHistorial == "") {
        const mensaje = await this.$swal(
          "¡Error!",
          "No existen existen pagos de este servicio.",
          "error"
        );
        return mensaje;
      } else {
        this.$swal({
          title: "",
          width: "850px",
          html: consultaHistorial,
          showCloseButton: true,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            //console.log('it works')
          }
        });
      }
    },
    cargarForm: function () {
      this.$router.push("registro").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    Invitado: function () {
      myRouter.push({ name: "Invitadoview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    validate: function () {
      var Usuario = $("#user").val();
      var password = $("#password").val();
      api
        .loginApp(Usuario, password)
        .then((response) => {
          if (response[0].hasOwnProperty("Oid")) {
            //El usuario existe y obtiene AllData
            //Aqui pones todo cuando es correcto el usuario y pass
            (localStorage.Oid = response[0].Oid),
              (localStorage.Nombre = response[0].Nombre);
            localStorage.Apellidos =
              response[0].ApellidoPaterno + " " + response[0].ApellidoMaterno;
            localStorage.Correo = response[0].Correo;
            localStorage.Rfc = response[0].Rfc;

            this.$session.start();
            this.$session.set("session", response[0].Oid);

            api
              .updateStorage()
              .then((response) => {
                myRouter.push({ name: "homeview" }).catch((error) => {
                  if (error.name != "NavigationDuplicated") {
                    throw error;
                  }
                });
              })
              .catch((error) => {
                //console.log("Error: " + error + " **UpdateStorage Servicio**");
              });
            //location.reload();
          } else {
            if (response[0].hasOwnProperty("usuario") && !response[0].usuario) {
              this.$swal("Nombre de usuario es incorrecto.", "", "error");
            } else if (
              response[0].hasOwnProperty("password") &&
              !response[0].password
            ) {
              this.$swal("Contraseña incorrecta.", "", "error");
            } else if (
              response[0].hasOwnProperty("EmailConfirm") &&
              !response[0].EmailConfirm
            ) {
              this.$swal(
                "¡Confirma tu cuenta!" +
                  "Ingresa a tu correo electrónico y activa tu cuenta.",
                "",
                "warning"
              );
            }
          }
        })
        .catch((error) => {
          //console.log(error);
          this.$swal("Usuario y/o contraseña invalida!", "", "error");
        });
    },
    calcularMontos() {
      var total = 0;
      var checks = Array.from(
        document.querySelectorAll('input.pagos[type="checkbox"]:checked')
      );
      checks.forEach((element) => {
        total = total + parseFloat($(element).attr("data"));
      });
      const aMoneda = (numero) => {
        const opciones = {
          numeroDeDecimales: 2,
          separadorDecimal: ".",
          separadorMiles: ",",
          simbolo: "$ ", // Con un espacio, ya que la función no agrega espacios
          posicionSimbolo: "i", // i = izquierda, d = derecha
        };
        // Valores por defecto
        opciones.simbolo = opciones.simbolo || "$";
        opciones.separadorDecimal = opciones.separadorDecimal || ".";
        opciones.separadorMiles = opciones.separadorMiles || ",";
        opciones.numeroDeDecimales =
          opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
        opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
        const CIFRAS_MILES = 3;

        // Redondear y convertir a cadena
        let numeroComoCadena = numero.toFixed(opciones.numeroDeDecimales);

        // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
        let posicionDelSeparador = numeroComoCadena.indexOf(
          opciones.separadorDecimal
        );
        if (posicionDelSeparador === -1)
          posicionDelSeparador = numeroComoCadena.length;
        let formateadoSinDecimales = "",
          indice = posicionDelSeparador;
        // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
        while (indice >= 0) {
          let limiteInferior = indice - CIFRAS_MILES;
          // Agregar separador si cortamos más de 3
          formateadoSinDecimales =
            (limiteInferior > 0 ? opciones.separadorMiles : "") +
            numeroComoCadena.substring(limiteInferior, indice) +
            formateadoSinDecimales;
          indice -= CIFRAS_MILES;
        }
        let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(
          posicionDelSeparador,
          opciones.numeroDeDecimales + 1
        )}`;
        return opciones.posicionSimbolo === "i"
          ? opciones.simbolo + formateadoSinSimbolo
          : formateadoSinSimbolo + opciones.simbolo;
      };
      $(".montoTotal").html(aMoneda(total));
    },
    init() {
      $(
        function () {
          $("#allchecked").change(
            function (e) {
              $("input:checkbox").prop("checked", $(e.target).prop("checked"));
              $('input.pagos[type="checkbox"]').prop(
                "disabled",
                $(e.target).prop("checked")
              );
              this.calcularMontos();
            }.bind(this)
          );
          $("#allchecked").change(
            function (e) {
              $("input:checkbox").prop("checked", $(e.target).prop("checked"));
              $('input.pagos[type="checkbox"]').prop(
                "disabled",
                $(e.target).prop("checked")
              );
              this.calcularMontos();
            }.bind(this)
          );
          $('input.pagos[type="checkbox"]').on(
            "click",
            function (e) {
              var checks = Array.from(
                document.querySelectorAll('input.pagos[type="checkbox"]')
              );
              var valid = true;
              var cont = 0;
              checks.forEach((element) => {
                if (valid != false) {
                  if (element.id != e.target.id) {
                    if (element.checked == true) {
                      valid = true;
                    } else {
                      alert("Tu selección debe ser el primer pago pendinte");
                      valid = false;
                      e.target.checked = false;
                    }
                  } else {
                    valid = false;
                    $(e.target).prop("disabled", true);
                  }
                }
              });

              this.calcularMontos();
            }.bind(this)
          );
        }.bind(this)
      );
    },
  },
  mounted() {
    //console.log("Notifications component ready.");
    if (!this.$session.exists()) {
      myRouter.push({ name: "InConsultar" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    } else {
      myRouter.push({ name: "homeview" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  data() {
    return {
      Nombre: null,
      Apellidos: null,
      Correo: null,
      Rfc: null,
    };
  },
};
</script>
<style scoped>
@keyframes latidos {
  from {
    transform: none;
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: none;
  }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
  display: inline-block;
  animation: latidos 3s infinite;
  width: 50%;
}
.btn-dark {
  color: #fff;
  background-color: #df6618;
  border-color: #343a40;
}

.margin {
  margin-top: 5%;
}

@media screen and (max-width: 1980px) and (min-width: 800px) {
  .bodyInicio {
    margin-left: 30%;
    margin-right: 0%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 20px;
    background: #313a55;
  }
  .bodyIniciob {
    margin-left: 30%;
    margin-right: 30%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 20px;
    background: #313a55;
  }
}

@media screen and (max-width: 800px) {
  .bodyInicio {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 20px;
    background: #313a55;
  }
  .bodyIniciob {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 20px;
    background: #313a55;
  }
}

.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;
}

.inner-block {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.vertical-center .form-control:focus {
  border-color: #2554ff;
  box-shadow: none;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label {
  font-weight: 500;
  color: #7a7a7a;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7a7a7a;
  margin: 0;
}

.forgot-password a {
  color: #2554ff;
}

.social-icons {
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.5em;
  color: #222222;
}

.social-icons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-icons ul li {
  display: inline-block;
  zoom: 1;
  width: 65px;
  vertical-align: middle;
  border: 1px solid #e3e8f9;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  margin-right: 5px;
  background: #f4f6ff;
}

.social-icons ul li a {
  display: block;
  font-size: 1.4em;
  margin: 0 5px;
  text-decoration: none;
}
.social-icons ul li a i {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.social-icons ul li a:focus i,
.social-icons ul li a:active i {
  transition: none;
  color: #222222;
}
</style>
