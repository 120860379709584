import axios from 'axios'
var servidorPagoob = 'https://pagoob.azurewebsites.net/';
//var servidorPagoob = 'http://localhost:3000/';


function callApi (api, parametros) {
  var servidor = servidorPagoob + api

  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }
  return new Promise((resolve, reject) => {
    axios.post(servidor, parametros, axiosConfig).then(
      response => {
        resolve(response.data)
      })
      .catch(error => {
        resolve(error)
      })
  })
}

function callApiPago (api, body, header) {
  return new Promise((resolve, reject) => {
    axios.post(servidorPagoob + api, body, header).then(
      response => {
        resolve(response.data)
      })
      .catch(error => {
        resolve(error)
      })
  })
}

export async function getAllData_Remote(){

  var claves = await getRegistros_Remote()

  var parametros;
  var registros= [];
  for (const element of claves) {
    parametros = {
      'id': '303002',
      'claveCatastral': element.NumeroDeServicio,
      'catastral_Options': 1,
      'method': 'GET'
    }
    const datos = await callApi('datos', parametros)
    registros.push(datos[0])
  };

  return registros;
}


export function updateStorage () {
  var parametros = {
    'Name': 'infoGlobalAPP',
    'Oid': localStorage.getItem('Oid')
  }
  return callApi('executeSP', parametros).then(
    response => {
      for (const i in response) {
        if (JSON.parse(response[i].DATA).hasOwnProperty('Usuario')) {
          localStorage.setItem('Usuario', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).Usuario) : null))
        } else if (JSON.parse(response[i].DATA).hasOwnProperty('PagoVencido')) {
          localStorage.setItem('PagoVencido', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).PagoVencido) : null))
        } else if (JSON.parse(response[i].DATA).hasOwnProperty('PagoVigente')) {
          localStorage.setItem('PagoVigente', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).PagoVigente) : null))
        } else if (JSON.parse(response[i].DATA).hasOwnProperty('Historial')) {
          localStorage.setItem('Historial', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).Historial) : null))
        } else if (JSON.parse(response[i].DATA).hasOwnProperty('Servicios')) {
          localStorage.setItem('Servicios', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).Servicios) : null))
        }
      }
      //console.log('Actualizo storage')
      return true
    })
    .catch(error => {
      //console.log(error)
      return false
    })
}

export function updateStorage_Remote () {
var parametros = {
  'Name': 'infoGlobalAPP',
  'Oid': localStorage.getItem('Oid')
}
return callApi('executeSP', parametros).then(
  response => {
    for (const i in response) {
      if (JSON.parse(response[i].DATA).hasOwnProperty('Usuario')) {
        localStorage.setItem('Usuario', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).Usuario) : null))
      } else if (JSON.parse(response[i].DATA).hasOwnProperty('PagoVencido')) {
        localStorage.setItem('PagoVencido', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).PagoVencido) : null))
      } else if (JSON.parse(response[i].DATA).hasOwnProperty('PagoVigente')) {
        localStorage.setItem('PagoVigente', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).PagoVigente) : null))
      } else if (JSON.parse(response[i].DATA).hasOwnProperty('Historial')) {
        localStorage.setItem('Historial', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).Historial) : null))
      } else if (JSON.parse(response[i].DATA).hasOwnProperty('Servicios')) {
        localStorage.setItem('Servicios', ((response[i].DATA != null) ? JSON.stringify((JSON.parse(response[i].DATA)).Servicios) : null))
      }
    }
    //console.log('Actualizo storage')
    return true
  })
  .catch(error => {
    //console.log(error)
    return false
  })
}

export function deleteService (NumeroDeContrato) {
  var parametros = {
    'Name': 'deleteServiceAPP',
    'Oid': localStorage.getItem('Oid'),
    'contrato': NumeroDeContrato
  }

  return callApi('executeSP', parametros)
}

// ________________________________________________________
export function validateService (a) {
  var parametros = {
    'Name': 'ServicePay',
    'Service': a
  }
  return callApi('executeSP', parametros)
}
// ________________________________________________________

export function deleteRegistros_Remote(NumeroDeContrato){
  var parametros = {
    'Name': 'deleteRegistros_Remote',
    'NumeroDeServicio': NumeroDeContrato,
    'ProyectoID' : '17',
    'UserID' : localStorage.getItem('Oid')
  }

  return callApi('executeSP', parametros)
}

export function makeAlertPay (data) {
  let headers = {
    'Content-Type': 'application/json'
  }

  return callApi('makeAlertPay', data, headers)
}

export function getDataPuente(claveCatastral) {
  var parametros = {
    'id': '303002',
    'claveCatastral': claveCatastral,
    'catastral_Options': 1,
    'method': 'GET'
  }

  return callApi('datos', parametros)
}

export function getInfoFiscal(claveCatastral) {
  var parametros = {
    'id': '303002',
    'claveCatastral': claveCatastral,
    'catastral_Options': 3,
    'method': 'GET'
  }

  return callApi('datos', parametros)
}

export function getCatalogoFISCAL() {
  var parametros = {
    'id': '303002',
    'claveCatastral': '0',
    'catastral_Options': 4,
    'method': 'GET'
  }

  return callApi('datos', parametros)
}

export function getCatalogoCFDI() {
  var parametros = {
    'id': '303002',
    'claveCatastral': '0',
    'catastral_Options': 5,
    'method': 'GET'
  }

  return callApi('datos', parametros)
}

export function getMontoAPagarPuente (data) {
  let headers = {
    'Content-Type': 'application/json'
  }

  return callApi('getMontoAPagarPuente', data, headers)
}

export function getDataPuenteMontos(claveCatastral) {
  var parametros = {
    'id': '303002',
    'claveCatastral': claveCatastral,
    'catastral_Options': 2,
    "method":'GET'
  }

  return callApi('datos', parametros)
}

export function getComisionesPago(monto) {
  var parametros = {
    'monto': monto
  }

  return callApi('comision', parametros)
}


export function altaContrato (NumContrato) {
  var parametros = {
    'Name': 'addServiceAPP',
    'Oid': localStorage.getItem('Oid'),
    'contrato': NumContrato
  }
  return callApi('executeSP', parametros)
}
export function loginApp (userName, pass) {
  var parametros = {
    'Name': 'loginApp',
    'userName': userName,
    'password': pass
  }
  return callApi('executeSP', parametros)
}

export function pagosBimestre (numContrato) {
  var parametros = {
    'Name': 'pagosBimestre',
    'numeroDeContrato': numContrato
  }
  return callApi('executeSP', parametros)
}

export function infoUserGlobal (Oid) {
  var parametros = {
    'Name': 'infoGlobalAPP',
    'Oid': Oid
  }
  return callApi('executeSP', parametros)
}
export function toPayBimestres (numContrato,bimestre,monto,OidPagos) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'Oid' : localStorage.Oid,
    'OidPagos' : OidPagos,
    'contrato': numContrato,
    'bimestre': bimestre,
    'monto': monto
  }

  return callApi('toPayBimestres', parametros, headers)
}
export function getTablaPeriodo (OidV,numContrato,monto, datos) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'Oid' : localStorage.Oid,
    'OidPagos' : OidV,
    'contrato': numContrato,
    'monto': monto,
    'datos': datos
  }

  return callApi('getTablaPeriodo', parametros, headers)
}
export function updatePeriodo (periodo) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'proyectoID' : JSON.parse(localStorage.Usuario)[0].proyectoID,
    'periodo': periodo
  }

  return callApi('updatePeriodo', parametros, headers)
}
export function findProject () {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'proyectoID' : JSON.parse(localStorage.Usuario)[0].proyectoID,

  }

  return callApi('findProject', parametros, headers)
}
export function recibirOrden (servicio,numContrato,bimestre,monto,contribuyente,municipio) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'Oid' : localStorage.Oid,
    'servicio' : servicio,
    'contrato': numContrato,
    'periodos': bimestre,
    'monto': monto,
    'contribuyente' : contribuyente,
    'municipio' : municipio
  }

  return callApi('recibirOrden', parametros, headers)
}

export function sendEmailRecibo (email,ticket) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'email': email,
    'ticket': ticket
  }

  return callApiPago('sendEmailRecibo', parametros, headers)
}
export function getTablaHistorial (OidV,numContrato,monto) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'Oid' : localStorage.Oid,
    'OidPagos' : OidV,
    'contrato': numContrato,
    'monto': monto
  }

  return callApi('getTablaHistorial', parametros, headers)
}

export function createPDF (numeroDeContrato, municipio, barcode, servicio, transacc, idTransaccion, monto, status,periodosPagados,fechaDePago,nombreContribuyente, OidContribuyente, filename, pageSize, orientation) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var usuario = JSON.parse(localStorage.Usuario)
  var parametros = {
    'proyectoid': usuario[0].proyectoID,
    'Oid' : localStorage.Oid,
    'contrato': numeroDeContrato,
    'municipio': municipio,
    'barcode': barcode,
    'servicio': servicio,
    'transacc': transacc,
    'idTransaccion': idTransaccion,
    'monto': monto,
    'status': status,
    'periodosPagados': periodosPagados,
    'fechaDePago': fechaDePago,
    'nombreContribuyente': nombreContribuyente,
    'OidContribuyente': OidContribuyente,
    'filename': filename,
    'pageSize': pageSize,
    'orientation': orientation
  }

  return callApi('createPDF', parametros, headers)
}
export function dataComprobante (numContrato,oidComp) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var usuario = JSON.parse(localStorage.Usuario)
  var parametros = {
    'proyectoid': usuario[0].proyectoID,
    'Oid' : localStorage.Oid,
    'numContrato' : numContrato,
    'oidComp' : oidComp,
  }

  return callApi('dataComprobante', parametros, headers)
}
export function dataComprobantePagado (numContrato,OidTrans) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'numContrato' : numContrato,
    'OidTrans' : OidTrans,
  }

  return callApi('dataComprobantePagado', parametros, headers)
}

export function createReportePDF (resultado, filename, pageSize, orientation) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var usuario = JSON.parse(localStorage.Usuario)
  var parametros = {

    'proyectoid': usuario[0].proyectoID,
    'resultado': resultado,
    'filename': filename,
    'pageSize': pageSize,
    'orientation': orientation
  }

  return callApi('createReportePDF', parametros, headers)
}

export function infoContrato (numContrato) {
  var parametros = {
    'Name': 'infoContratoAPP',
    'NumContrato': numContrato
  }
  return callApi('executeSP', parametros)
}

export function infoConsultas (numContrato) {
  var parametros = {
    'Name': 'infoConsultasAPP',
    'NumContrato': numContrato
  }
  return callApi('executeSP', parametros)
}

export function infoConsultasNombresApellidos (nombreApellidos) {
  var parametros = {
    'Name': 'infoConsultasNombresApellidos',
    'nombreApellidos': nombreApellidos
  }
  return callApi('executeSP', parametros)
}

export function sendEmailRegister (Oid, email, userName) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'Oid': Oid,
    'email': email,
    'userName': userName
  }

  return callApiPago('sendEmailRegister', parametros, headers)
}


export function getKeyPublic (proyectoID) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'proyectoID': proyectoID
  }

  return callApiPago('findProject', parametros, headers)
}


export function actualizaInformacion (Oid, Nombre, Apaterno, Amaterno, Correo, Password, Telefono, Rfc, razonSocial) {
  var parametros = {
    'Name': 'updateInformacion',
    'Oid': Oid,
    'Nombre': Nombre,
    'Apaterno': Apaterno,
    'Amaterno': Amaterno,
    'Correo': Correo,
    'Password': Password,
    'Telefono': Telefono,
    'Rfc': Rfc,
    'razonSocial': razonSocial
  }
  return callApi('executeSP', parametros)
}

export function geolocal (OidD, Latitud, Longitud){
   var parametros = {
    'Name': 'geolocalizacion',
    'OidD': OidD,
    'Latitud': Latitud,
    'Longitud': Longitud
   }
   return callApi('executeSP', parametros)
}

export function registrarUsuario (parametros) {
  return callApi('executeSP', parametros)
}

/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function pago (body, header) {
  var response = await callApiPago('tarjeta', body, header)
  return response;
}
export async function pagoPuenteIxtla (body, header) {
  var response = await callApiPago('recibirOrdenPuenteIxtla', body, header)
  return response;
}

export function sendEmailTicket (Oid, nombre, email, ticket) {
  let headers = {
    'Content-Type': 'application/json'
  }
  var fecha = new Date();
  var parametros = {
    'Oid': Oid,
    'nombre': nombre,
    'email': email,
    'ticket': ticket,
    'fecha': fecha.getDate() + "/" + (fecha.getMonth() +1) + "/" + fecha.getFullYear()
  }

  return callApiPago('sendEmailTicket', parametros, headers)
}

export async function calcularMonto(OidsBimestres, numContrato, optionPay){
  let headers = {
    'Content-Type': 'application/json'
  }
  var parametros = {
    'OidsBimestres': OidsBimestres,
    'numContrato': numContrato,
    'optionPay': optionPay
  }

  return callApiPago('TotalMontoBimestres', parametros, headers)
}

export function transaccionExitosa (Oid, OidPagos, numContrato, idTransaccion, montoPagar) {
  var parametros = {
    'Name': 'pagoIdTransaccionAPP',
    'Oid': Oid,
    'contrato': numContrato,
    'idTransaccion': idTransaccion,
    'OidPagos': OidPagos,
    'montoPagar': montoPagar
  }
  return callApi('executeSP', parametros)
}


export function setContratoRemote(contratoID){
  // data.Name = 'addServicio_Remote';
  // data.proyectoID = 17; //puente de ixtla
  // data.numeroServicio = data.ClaveCatastral,
  // data.UserID = localStorage.getItem('Oid');

  var parametros = {
    'Name': 'addServicio_Remote',
    'proyectoID': '17',//puente de ixtla
    'numeroServicio': contratoID,
    'UserID': localStorage.getItem('Oid')
  }
  return callApi('executeSP', parametros)
}

export function getRegistros_Remote(){
  var parametros = {
    'Name': 'getRegistros_Remote',
    'proyectoID': '17',//puente de ixtla
    'UserID': localStorage.getItem('Oid')
  }
  return callApi('executeSP', parametros)
}
